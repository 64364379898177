import { useForm } from 'react-hook-form';
import useNameFormCallback from 'src/app/components/auth/AuthPopover/ProfilePage/useNameFormCallback';
import TextBox from 'src/app/components/form/TextBox/TextBox';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { Flex } from 'src/app/components/shared/Flex';
import { SignUpForm } from '../../types';

interface INameFormProps {
  onSuccess: () => void;
}

export function NameForm({ onSuccess }: INameFormProps) {
  const { formState, handleSubmit, register } =
    useForm<Pick<SignUpForm, 'firstName' | 'lastName'>>();
  const { errors, isSubmitting } = formState;

  const updateDisplayName = useNameFormCallback(onSuccess);

  const onSubmit = async (data: { firstName: string; lastName: string }) => {
    await updateDisplayName(data.firstName, data.lastName);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap="14px">
        <TextBox
          label="First Name"
          autoComplete="on"
          placeholder="John"
          {...register('firstName', { required: true })}
          error={errors.firstName?.message}
        />
        <TextBox
          label="Last Name"
          autoComplete="on"
          placeholder="Doe"
          {...register('lastName', { required: true })}
          error={errors.lastName?.message}
        />
        <Flex>
          <ButtonPill type="submit" intent="primary" disabled={isSubmitting}>
            Save
          </ButtonPill>
        </Flex>
      </Flex>
    </form>
  );
}
