import { SVGProps, memo } from 'react';

const SvgLogoSuperflow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.326 24.5c0 13.531-9.699 24.5-21.663 24.5S0 38.031 0 24.5 9.699 0 21.663 0s21.663 10.969 21.663 24.5Z"
      fill="url(#logo-superflow_svg__a)"
    />
    <path
      d="M18.16 10.936h11.123M14.3 15.32h10.48M9.799 19.703h10.159m-3.727 4.381h14.018m-7.263 4.387h10.48M14.3 32.855h13.697m-9.837 4.387h6.62"
      stroke="#fff"
      strokeWidth={1.9}
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="logo-superflow_svg__a"
        x1={47.344}
        y1={51.5}
        x2={32.4}
        y2={-13.099}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3451B2" />
        <stop offset={1} stopColor="#BB45FF" />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(SvgLogoSuperflow);
export default Memo;
