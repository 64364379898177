import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useFlowDefinitionId, useSessionId } from 'src/utils/resource.hooks';
import tracker from './tracker';

/**
 * Collects data required to send session-related tracking events:
 * - the flow ID,
 * - the session ID and type.
 *
 * The hook relies on resource hooks to fetch the required data.
 *
 * @returns A memoized object containing basic data required to send session-related tracking events.
 */
export function useSessionEventBaseData() {
  const flowId = useFlowDefinitionId();
  const sessionId = useSessionId();
  return useMemo(
    () => ({
      flowId,
      sessionId,
    }),
    [flowId, sessionId],
  );
}

/**
 * Fires a page-view analytics event each time the route changes.
 */
export function useTrackPageTransitionsEffect() {
  const location = useLocation();
  useEffect(() => {
    tracker.page();
  }, [location]);
}
