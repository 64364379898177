import { Leaves } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import { WorkspaceLogo } from 'src/app/components/workspace/WorkspaceLogo';
import { vars } from 'src/styles/vars.css';
import {
  workspaceText14,
  workspaceText16,
} from '../WorkspaceSettingsModal.css';

interface IIntroProps {
  logoUrl?: string;
  workspaceName: string;
  workspaceSlug: string;
}

/**
 * Statically renders the current workspace data.
 */
export function Intro({ logoUrl, workspaceName, workspaceSlug }: IIntroProps) {
  return (
    <Flex direction="column" justify="center" align="center" gap="34px">
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <Flex
          justify="center"
          align="center"
          style={{
            padding: '16px',
            borderRadius: '14px',
            border: `1.42px solid ${vars.color.light99}`,
          }}
        >
          {logoUrl ? (
            <Flex>
              <WorkspaceLogo
                name={workspaceName}
                imageUrl={logoUrl}
                size="large"
              />
            </Flex>
          ) : (
            <Leaves style={{ width: '74px', height: '72px' }} />
          )}
        </Flex>
      </div>
      <Flex direction="column" justify="center" align="center" gap="6px">
        <p className={workspaceText16}>{workspaceName}</p>
        <p className={workspaceText14}>
          https://superflow.team/{workspaceSlug}
        </p>
      </Flex>
      <hr
        style={{
          borderTop: `1px solid ${vars.color.light99}`,
          width: '470px',
        }}
      />
    </Flex>
  );
}
