import { useEffect, useMemo, useRef } from 'react';
import { UUID } from 'src/@types/common';
import { useEnrollmentCol } from 'src/services/database/Enrollments';
import { EnrollmentStatus } from 'src/services/database/Enrollments/utils';
import {
  useFlowDefinitionCol,
  useFlowDefinitionDoc,
} from 'src/services/database/FlowDefinitions/getters';
import { FLOW_DEFINITION_GLOBAL_WORKSPACE_ID } from 'src/services/database/FlowDefinitions/constants';
import { FlowDefinitionDto } from 'src/services/database/FlowDefinitions/dto/flowDefinition.dto';
import { FlowInstanceStatus } from 'src/services/database/FlowInstances/flowInstanceStatus';
import { useFlowInstanceDoc } from 'src/services/database/FlowInstances/getters';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import { CollectionQueryArgs } from 'src/services/database/types';
import { buildSimpleQuery } from 'src/services/database/utils';
import { useResetConfettiState } from 'src/state/flow/completion';
import { useLocalized } from 'src/utils/i18n';
import {
  useFlowDefinitionId,
  useFlowInstanceId,
  useWorkspaceId,
} from 'src/utils/resource.hooks';
import { useUserId } from 'src/utils/userContent/hooks';
import { useIsUserSystemAdmin } from 'src/utils/auth/hooks';

interface IUserFlowDefinitionListOptions {
  doesAllowGlobal?: boolean;
  doesAllowWorkspace?: boolean;
  tags?: FlowDefinitionTag[];
}

/**
 * Retrieves a list of flow definitions, based on the current workspace, and its
 * tags. If no workspace is available, it will return the global flow definitions.
 * If the `allowGlobal` flag is set and no workspace is available, it will set
 * the workspace ID to empty, therefore not returning any flow definitions.
 * If the `tags` parameter is set, it will filter the flow definitions based on
 * these tags, matching them to what are present in the workspace.
 * If no `tags` are passed, it will return all flow definitions matching the
 * workspace tags.
 *
 * @param options - The options for the flow definition list.
 * @param options.doesAllowGlobal - If true, it will return global flow
 * definitions, otherwise it will only return workspace flow definitions.
 * @param options.tags - The tags to filter the flow definitions by. If no tags are
 * passed, it will return all flow definitions, otherwise it will only return workspace flow definitions.
 */
export function useFlowDefinitionList(
  options: IUserFlowDefinitionListOptions = {},
) {
  const workspace = useWorkspace();
  const {
    doesAllowGlobal = true,
    tags = [],
    doesAllowWorkspace = true,
  } = options;

  if (!doesAllowWorkspace && !doesAllowGlobal) {
    throw new Error('At least one of the options must be true');
  }

  const query = useMemo<CollectionQueryArgs<Partial<FlowDefinitionDto>>>(() => {
    if (!workspace) {
      return {
        filterBy: [
          {
            key: 'workspaceId',
            op: '==',
            value: doesAllowGlobal ? FLOW_DEFINITION_GLOBAL_WORKSPACE_ID : '',
          },
        ],
      };
    }

    const compatibleTags =
      tags?.length === 0
        ? workspace.tags
        : tags.filter((tag) => workspace?.tags.includes(tag));

    const filterBy: CollectionQueryArgs<
      Partial<FlowDefinitionDto>
    >['filterBy'] = [];

    if (doesAllowWorkspace) {
      filterBy.push({
        key: 'workspaceId',
        op: doesAllowGlobal ? 'in' : '==',
        value: doesAllowGlobal
          ? [workspace.id, FLOW_DEFINITION_GLOBAL_WORKSPACE_ID]
          : workspace.id,
      });
    } else if (doesAllowGlobal) {
      filterBy.push({
        key: 'workspaceId',
        op: '==',
        value: FLOW_DEFINITION_GLOBAL_WORKSPACE_ID,
      });
    }

    filterBy.push({
      key: 'tags',
      op: 'array-contains-any',
      value: compatibleTags.length === 0 ? ['_'] : compatibleTags,
    });

    return { filterBy };
  }, [doesAllowGlobal, doesAllowWorkspace, tags, workspace]);

  return useFlowDefinitionCol(query);
}

/**
 * Tries retrieving the definition for the current flow.
 *
 * At least one of the following must be present:
 * - the flow definition ID as a URL path param
 * - the flow instance ID as a URL path param,
 * because the flow instance also contains a definition reference.
 *
 * **If** the workspace ID is available in the URL's path param,
 * the hook also checks the workspace ID and tags against the flow definition
 * as a security measure.
 * @param customFlowDefinitionId - If set, this flow definition will be fetched instead of the active one.
 */
export function useFlowDefinition(customFlowDefinitionId?: UUID) {
  const currentId = useFlowDefinitionId();
  const resource = useFlowDefinitionDoc(customFlowDefinitionId ?? currentId);
  const workspace = useWorkspace();

  return useMemo(() => {
    const data = resource.result?.data();

    if (workspace && data) {
      const hasMatchingTags = workspace?.tags.some((tag) =>
        data.tags.includes(tag),
      );

      // TODO [security] replace with Firestore rules
      // If the flow definition is not in the current workspace, and it's not a global flow,
      // we don't want to show it.
      if (
        !hasMatchingTags ||
        (data.workspaceId !== workspace?.id &&
          data.workspaceId !== FLOW_DEFINITION_GLOBAL_WORKSPACE_ID)
      ) {
        return;
      }
    }

    return data;
  }, [resource.result, workspace]);
}

/**
 * Checks if the user can edit a flow definition. Either
 * - the user must be a system admin, or
 * - the flow definition must be scoped to the active workspace.
 * @param customFlowDefinitionId - If set, this flow definition will be fetched instead of the active one.
 * @returns `true` if the user can edit the targeted flow definition
 */
export function useCanEditOrDuplicateFlowDefinition(
  customFlowDefinitionId?: UUID,
) {
  const currentId = useFlowDefinitionId();
  const flowDefinition = useFlowDefinition(customFlowDefinitionId ?? currentId);
  const workspaceId = useWorkspaceId();
  const isSystemAdmin = useIsUserSystemAdmin();
  return flowDefinition?.workspaceId === workspaceId || isSystemAdmin;
}

//#region  =========== Flow instances ===========
/**
 * Retrieves the flow instance for the current flow.
 */
export function useFlowInstance() {
  const flowInstanceId = useFlowInstanceId();
  return useFlowInstanceDoc(flowInstanceId)?.result?.data();
}

export function useCanFlowInstanceBeMarkedAsCompleted() {
  const flowInstance = useFlowInstance();
  const creatorId = useUserId();
  const flowInstanceId = useFlowInstanceId();
  const query = useMemo(
    () =>
      buildSimpleQuery({
        creatorId,
        flowInstanceId,
      }),
    [creatorId, flowInstanceId],
  );

  const enrollmentsFound = useEnrollmentCol(query);

  if (flowInstance?.status === FlowInstanceStatus.Completed) {
    return false;
  }

  return enrollmentsFound.result?.docs?.some(
    (x) => x.data().status === EnrollmentStatus.Completed,
  );
}

//#endregion  ======== Flow instances ===========

/**
 * Retrieves the title of a flow definition.
 * @param customFlowDefinitionId - If set, this flow definition will be fetched instead of the active one.
 */
export function useFlowDefinitionTitle(customFlowDefinitionId?: UUID) {
  const currentId = useFlowDefinitionId();
  const flow = useFlowDefinition(customFlowDefinitionId ?? currentId);
  return useLocalized(flow?.title, '');
}

/**
 * Retrieves the title of a flow definition.
 * @param customFlowDefinitionId - If set, this flow definition will be fetched instead of the active one.
 */
export function useFlowDescription(customFlowDefinitionId?: UUID) {
  const currentId = useFlowDefinitionId();
  const flow = useFlowDefinition(customFlowDefinitionId ?? currentId);
  return useLocalized(flow?.description, '');
}

/**
 * Performs any necessary cleanup tasks when the user is
 * switching between flows, while staying in the flow view.
 */
export function useHandleFlowSwitch() {
  const flowDefinitionId = useFlowDefinitionId();
  const previousFlowDefinitionId = useRef<UUID>();
  const resetConfettiState = useResetConfettiState();
  useEffect(
    function handleFlowSwitch() {
      if (
        flowDefinitionId &&
        flowDefinitionId !== previousFlowDefinitionId.current
      ) {
        resetConfettiState();
        previousFlowDefinitionId.current = flowDefinitionId;
      }
    },
    [resetConfettiState, flowDefinitionId],
  );
}

export enum FlowDefinitionLandingPageType {
  Regular = 'regular',
  Video = 'video',
  Image = 'image',
}

const flowDefinitionLandingPageVideoExtensions = new Set(['mp4', 'webm']);
const flowDefinitionLandingPageImageExtensions = new Set([
  'jpg',
  'jpeg',
  'png',
  'gif',
  'svg',
]);
const flowDefinitionLandingPageTypeTest = /(?:\.([^.]+))?$/;

export function useFlowDefinitionLandingPageType(
  argFlowDefinition?: FlowDefinitionDto,
): FlowDefinitionLandingPageType | undefined {
  const retrievedFlowDefinition = useFlowDefinition();
  const flow = argFlowDefinition ?? retrievedFlowDefinition;

  return useMemo(() => {
    const url = flow?.landingPageUrl;
    if (!url) {
      return;
    }

    const fileType = url
      ? flowDefinitionLandingPageTypeTest.exec(url)?.[1]
      : undefined;
    if (fileType) {
      if (flowDefinitionLandingPageVideoExtensions.has(fileType)) {
        return FlowDefinitionLandingPageType.Video;
      } else if (flowDefinitionLandingPageImageExtensions.has(fileType)) {
        return FlowDefinitionLandingPageType.Image;
      }
    }

    return FlowDefinitionLandingPageType.Regular;
  }, [flow?.landingPageUrl]);
}
