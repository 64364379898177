import { useCallback } from 'react';
import { updateUserData } from 'src/services/auth';
import { useSetUser, useUser } from 'src/utils/userContent/hooks';

export default function useNameFormCallback(onSuccess: () => void) {
  const user = useUser();
  const setUser = useSetUser();

  return useCallback(
    async (firstName: string, lastName: string) => {
      const displayName = `${firstName} ${lastName}`;

      await updateUserData({ displayName });
      setUser({
        ...user,
        displayName,
      });

      onSuccess();
    },
    [onSuccess, setUser, user],
  );
}
