import { LegalLinks } from '../LegalLinks';
import { Buttons } from './Buttons';
import { DefaultLayout } from './DefaultLayout';
import { Errors } from './Errors';
import { InputFields } from './InputFields';
import { Root } from './Root';

/**
 * Provides the necessary components for implementing an email signup form
 * using the compound component pattern.
 * 
 * @example
 * ```tsx
 * <EmailSignUpForm.Root onSignUp={()=> alert('Hooray!')}>
    <EmailSignUpForm.DefaultLayout>
      <EmailSignUpForm.InputFields autoFocusField="email" />
      <EmailSignUpForm.Buttons
        onVendorAuth={() => setPageId('success')}
      />
      <EmailSignUpForm.Errors />
      <EmailSignUpForm.LegalLinks />
    </EmailSignUpForm.DefaultLayout>
  </EmailSignUpForm.Root>
 * ```
 */
export const EmailSignUpForm = {
  /**
   * Renders the form submit button and the Google auth button under each other.
   */
  Buttons,
  /**
   * Renders a flex column layout with a maximum width which is applied in most app locations.
   * Adding or omitting this component doesn't impact the form behavior.
   */
  DefaultLayout,
  /**
   * Renders the validation error messages.
   */
  Errors,
  /**
   * Renders all the form input fields without any grouping layout.
   */
  InputFields,
  /** Renders the ToC and Privacy Policy URLs. */
  LegalLinks,
  /**
   * Renders the parent `<form>`.
   * It instantiates a React Hook Form instance to manage the signup form.
   * The form instance is propagated to all child components,
   * so they can access it through the `useSignUpFormInstance()` hook.
   */
  Root,
} as const;
