import {
  autoUpdate,
  offset,
  OffsetOptions,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingReturn,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { ComponentProps, useMemo } from 'react';
import { AuthPopover } from './AuthPopover';

export interface IPopOverHookArgs {
  /** Controls whether the popover is rendered. */
  isOpen?: boolean;
  /**
   * Passed to Floating UI so the popover is closed when the dialog loses focus,
   * or when the overlay is clicked.
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * Controls where the popover should float relative to the anchor button.
   * @see https://floating-ui.com/docs/useFloating#placement
   */
  placement: Placement;
  /**
   * Optional offset values, in case some custom distance must be added
   * to the calculated popover position.
   * @see https://floating-ui.com/docs/offset
   */
  offset?: OffsetOptions;
}

type AnchorRefSetter = UseFloatingReturn['refs']['setReference'];
type FloatingRefSetter = UseFloatingReturn['refs']['setFloating'];
interface IPopOverHookReturn {
  /**
   * @returns Generated props that should be spread over the popover's anchor element.
   */
  getAnchorProps: () => { ref: AnchorRefSetter } & Record<string, unknown>;
  /**
   * @returns Generated props that should be spread over the popover element.
   */
  getFloatingProps: () => { ref: FloatingRefSetter } & ComponentProps<
    typeof AuthPopover
  >;
}

/**
 * Configures Floating UI to manage the {@link AuthPopover} dialog.
 * @returns Props generated by Floating UI to manage the dialog.
 * These props should be spread over both the anchor and the popover element.
 * @see https://floating-ui.com/
 */
export default function useAuthPopover({
  isOpen,
  setIsOpen,
  placement,
  offset: offsetConfig,
}: IPopOverHookArgs): IPopOverHookReturn {
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(offsetConfig), shift()],
    placement,
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return useMemo(
    () => ({
      getAnchorProps: () => ({
        ref: refs.setReference,
        ...getReferenceProps(),
      }),
      getFloatingProps: () => ({
        ref: refs.setFloating,
        floatingContext: context,
        floatingProps: getFloatingProps(),
        onClose: () => setIsOpen(false),
        style: floatingStyles,
      }),
    }),
    [
      context,
      floatingStyles,
      getFloatingProps,
      getReferenceProps,
      refs.setFloating,
      refs.setReference,
      setIsOpen,
    ],
  );
}
