import { isError } from '@sentry/utils';
import { useCallback, useState } from 'react';
import { LogoGoogle } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import { loginWithGoogle } from 'src/services/auth/service';
import { isIgnoredAuthError } from 'src/services/auth/utils';
import { useSetUser } from 'src/utils/userContent/hooks';
import { InputError } from '../../form/InputError/InputError';
import { ButtonPill } from '../../shared/ButtonPill';

interface IGoogleAuthProps {
  /**
   * Called when vendor authentication is resolved.
   */
  onSuccess?: () => void;
}

/**
 * Renders a "Log in with Google" button, and performs authentication
 * along with error handling.
 *
 * Log in with Google can be a signup or a sign-in function as well.
 * To determine which one is it, the private workspace is fetched for the user. If there is none, we create a new one.
 *
 * If there is a redirectTo path in the url, after authentication that workspace is fetched. If none, the user is
 * redirected to the homepage (and their personal workspace).
 */
export function GoogleAuth({ onSuccess }: IGoogleAuthProps) {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState('');
  const setUser = useSetUser();

  const authenticate = useCallback(async () => {
    setIsPending(true);
    try {
      const userCredential = await loginWithGoogle();
      if (userCredential) {
        const user = userCredential.user;
        const payload = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          isAnonymous: false,
        };

        setUser(payload);
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const message = isError(error) ? error.message : String(error);
      if (!isIgnoredAuthError(message)) {
        setError(message);
      }
    }
    setIsPending(false);
  }, [onSuccess, setUser]);

  return (
    <>
      <ButtonPill
        intent="secondary"
        onClick={authenticate}
        disabled={isPending}
      >
        <Flex gap="5px">
          <LogoGoogle width="24px" height="22px" />
          Log in with Google
        </Flex>
      </ButtonPill>
      {error && <InputError severity="blocking">{error}</InputError>}
    </>
  );
}
