import { IsBoolean } from 'class-validator';
import { FacilitationVideoSource } from 'src/@types/video';

export class NudgeVideoDto {
  // TODO add validation
  readonly videoFile: FacilitationVideoSource;

  /**
   * This property is the implementation of `LocaleMap<T>`
   * to resolve some type issues between composed TS types and DTOs.
   */
  @IsBoolean()
  readonly isPublished: boolean;

  constructor(dto: NudgeVideoDto) {
    this.videoFile = dto.videoFile;
    this.isPublished = dto.isPublished;
  }
}
