import { SVGProps, memo } from 'react';

const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#users_svg__a)"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7.875 15a4.875 4.875 0 1 0 0-9.75 4.875 4.875 0 0 0 0 9.75Z" />
      <path d="M.96 18.75a8.25 8.25 0 0 1 13.833 0M16.125 15a8.244 8.244 0 0 1 6.916 3.75" />
      <path d="M14.313 5.597A4.875 4.875 0 1 1 16.122 15" />
    </g>
    <defs>
      <clipPath id="users_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgUsers);
export default Memo;
