import { fillParent } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { Tooltip } from 'src/app/components/shared/Tooltip';
import { ChatCircleDots } from '../../icons';
import { Flex } from '../../shared/Flex';
import { sidebarButton } from './Sidebar.css';

export function SendFeedbackButton() {
  return (
    <Tooltip.Root placement="right">
      <Tooltip.Anchor>
        <Flex
          as="a"
          href="https://superflow.canny.io/feature-requests"
          target="_blank"
          rel="noreferrer"
          className={sidebarButton}
        >
          <ChatCircleDots
            className={fillParent}
            style={{
              width: '24px',
              height: '24px',
              color: vars.color.brandGray80,
            }}
          />
        </Flex>
      </Tooltip.Anchor>
      <Tooltip.Content>Send feedback</Tooltip.Content>
    </Tooltip.Root>
  );
}
