import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { Flex } from 'src/app/components/shared/Flex';
import { WorkspaceSidebar } from 'src/app/components/workspace/WorkspaceSidebar';
import { defaultWorkspaceVars } from 'src/app/components/workspace/workspace.css';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import {
  screenEdgePaddingBottom,
  screenEdgePaddingLeft,
  screenEdgePaddingRight,
  screenEdgePaddingTop,
} from 'src/styles/touch.css';
import { useWorkspaceId } from 'src/utils/resource.hooks';
import { layoutRightSideCss } from './WorkspaceLayout.css';

interface IWorkspaceLayoutProps {
  title: string;
}

export function WorkspaceLayout({
  title,
  children,
}: PropsWithChildren<IWorkspaceLayoutProps>) {
  const workspaceId = useWorkspaceId();
  const workspace = useWorkspace();
  const canRender = !workspaceId || !!workspace;

  return canRender ? (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex
        justify="flex-start"
        align="stretch"
        wrap="nowrap"
        className={clsx(
          screenEdgePaddingBottom,
          screenEdgePaddingLeft,
          screenEdgePaddingRight,
          screenEdgePaddingTop,
          defaultWorkspaceVars,
        )}
        style={{
          // Must be set to make sure large screens are filled.
          // 100vh would cause empty space at the screen's bottom on small screens with scrollbars.
          height: '100%',
          minHeight: '100%',
          width: '100vw',
          maxWidth: '100vw',
        }}
      >
        <WorkspaceSidebar
          style={{
            position: 'relative',
            zIndex: 2,
            flex: '0 0 auto',
          }}
        />
        <div className={layoutRightSideCss}>{children}</div>
      </Flex>
    </>
  ) : null;
}
