type Callback<T> = (arg?: T) => void;
type Unsubscribe = () => void;

/**
 * A simple subscription class that allows you to subscribe to a value and get
 * notified when it changes. It's a simple implementation of the observer
 * pattern and can be used to create reactive components.
 */
export class Subscription<T> {
  private readonly subscriptions: Array<Callback<T>> = [];

  constructor(private value?: T) {}

  on(callback: Callback<T>): Unsubscribe {
    this.subscriptions.push(callback);
    callback(this.value);

    return () => {
      const index = this.subscriptions.indexOf(callback);
      if (index !== -1) {
        this.subscriptions.splice(index, 1);
      }
    };
  }

  emit(arg: T): void {
    this.subscriptions.forEach((callback) => {
      callback(arg);
    });
  }
}
