import { SVGProps, memo } from 'react';

const SvgVolumeGeneric = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 59 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m27.175 13.059-12.111 9.688H5.375v14.534h9.689l12.111 9.688V13.06Zm19.548-.171a24.222 24.222 0 0 1 0 34.25m-8.55-25.7a12.111 12.111 0 0 1 0 17.125"
      stroke="currentColor"
      strokeWidth={4.941}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgVolumeGeneric);
export default Memo;
