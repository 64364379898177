import { SVGProps, memo } from 'react';

const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.558 5.818c1.28 0 2.317-1.054 2.317-2.356 0-1.301-1.037-2.356-2.317-2.356-1.28 0-2.316 1.055-2.316 2.356 0 1.302 1.037 2.356 2.316 2.356ZM3.817 10.522c1.28 0 2.316-1.055 2.316-2.356S5.096 5.81 3.817 5.81C2.537 5.81 1.5 6.865 1.5 8.166s1.037 2.356 2.317 2.356ZM13.558 15.235c1.28 0 2.317-1.055 2.317-2.356 0-1.302-1.037-2.357-2.317-2.357-1.28 0-2.316 1.055-2.316 2.357 0 1.3 1.037 2.356 2.316 2.356ZM11.5 4.539l-5.625 2.56M11.5 11.802 5.942 9.285"
      stroke="#8A7A95"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgShare);
export default Memo;
