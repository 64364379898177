import { SVGProps, memo } from 'react';

const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.35}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m22.3.9-10.8 9.9L.7.9" />
      <path d="M.7.9h21.6v15.3a.9.9 0 0 1-.9.9H1.6a.9.9 0 0 1-.9-.9V.9ZM9.536 9.002l-8.56 7.846M22.02 16.848l-8.56-7.846" />
    </g>
  </svg>
);

const Memo = memo(SvgEmail);
export default Memo;
