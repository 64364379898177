import { useFormContext } from 'react-hook-form';
import { ButtonPill } from '../../shared/ButtonPill';
import { Flex } from '../../shared/Flex';
import { GoogleAuth } from '../GoogleAuth';

interface IButtonsProps {
  /**
   * Called when vendor authentication is resolved.
   */
  onVendorAuthSuccess?: () => void;
}

export function Buttons({ onVendorAuthSuccess }: IButtonsProps) {
  const { formState } = useFormContext();
  return (
    <Flex direction="column" gap="24px">
      <Flex direction="column" align="stretch" alignSelf="stretch">
        <ButtonPill
          intent="primary"
          type="submit"
          disabled={formState.isSubmitting}
        >
          Sign Up
        </ButtonPill>
      </Flex>

      <GoogleAuth onSuccess={onVendorAuthSuccess} />
    </Flex>
  );
}
