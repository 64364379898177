import { SVGProps, memo } from 'react';

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeOpacity={0.88}
      strokeWidth={2}
      strokeLinecap="round"
      clipPath="url(#close_svg__a)"
    >
      <path d="m16.086 16.08-10-10M6.086 16.08l10-10" />
    </g>
    <defs>
      <clipPath id="close_svg__a">
        <path
          fill="#fff"
          transform="translate(.086 .58)"
          d="M0 0h20.998v20.998H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgClose);
export default Memo;
