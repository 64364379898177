import { SVGProps, memo } from 'react';

const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7 5.172 4.95-4.95 1.414 1.414L7 8 .636 1.636 2.05.222 7 5.172Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgCaretDown);
export default Memo;
