import { motion } from 'framer-motion';
import { ComponentProps, PropsWithChildren } from 'react';
import { unstyledButton } from 'src/styles/utils.css';

export function ButtonWithIcon({
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof motion.button>>) {
  return (
    <motion.button
      type="button"
      {...props}
      className={unstyledButton}
      style={{
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '5px',
        color: 'hsla(210, 1%, 35%, 1)',
      }}
      whileHover={{ scale: 1.1 }}
      whileFocus={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {children}
    </motion.button>
  );
}
