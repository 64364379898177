import { SVGProps, memo } from 'react';

const SvgGoal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.315 4.965c-.174-.369-.435-.553-.783-.553h-2.61V1.644c0-.369-.174-.738-.522-.83-.348-.184-.696-.092-.957.185l-2.088 2.214c-1.74-1.015-3.828-1.569-5.916-1.569C5.74 1.644.26 7.456.26 14.56c0 7.104 5.481 12.915 12.18 12.915 6.7 0 12.18-5.811 12.18-12.915 0-2.306-.522-4.428-1.479-6.273l2.088-2.214c.174-.369.261-.738.087-1.107Zm-4.263 1.292h1.392l-1.74 1.845h-2.262V5.703l1.74-1.845v1.476c0 .554.348.923.87.923Zm-4.35 8.302c0 2.583-1.914 4.613-4.35 4.613s-4.35-2.03-4.35-4.613c0-2.583 1.914-4.612 4.35-4.612.87 0 1.74.277 2.436.738l-3.045 3.229a.93.93 0 0 0 0 1.291.913.913 0 0 0 .61.277.913.913 0 0 0 .608-.277l3.045-3.229c.435.738.696 1.66.696 2.583Zm6.09 0c0 6.089-4.698 11.07-10.44 11.07-5.742 0-10.44-4.981-10.44-11.07 0-6.088 4.698-11.07 10.44-11.07 1.653 0 3.306.37 4.698 1.107l-.087.093a.862.862 0 0 0-.26.645v3.321l-.697.738c-1.044-.83-2.262-1.291-3.654-1.291-3.393 0-6.09 2.86-6.09 6.457 0 3.598 2.697 6.458 6.09 6.458s6.09-2.86 6.09-6.458c0-1.476-.435-2.767-1.218-3.874l.696-.738h3.132c.261 0 .435-.092.61-.277l.086-.092c.696 1.476 1.044 3.229 1.044 4.981Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgGoal);
export default Memo;
