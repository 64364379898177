import { useCallback, useEffect, useState } from 'react';
import { AsyncStatus } from './useAsync';

/**
 * Handles the loading state of an image.
 * This helps to determine whether the image itself, or a fallback should be rendered.
 * @param imageUrl - Valid image URL
 * @returns The image loading state
 */
export function useLoadImage(
  imageUrl?: string,
  options?: {
    /**
     * If `true`, the image is considered to be displayed right away.
     * This prevents unnecessary flashes when the image is cached already.
     *
     * But if the image is slow, the pending status will activate after
     * `slowTriggerMs` wait time.
     * @default false
     */
    isVisibleOnMount?: boolean;
    /**
     * If `isVisibleOnMount` is `true`, this is the wait time
     * before the slowly loading image should fall back to its placeholder.
     * @default 300
     */
    slowTriggerMs?: number;
    /**
     * If `true`, the load state is reset to "idle" when `imageUrl` changes.
     * Turn this off in cases where the user is typing in a URL to avoid flickers.
     * @default true
     */
    shouldResetOnUrlChange?: boolean;
  },
) {
  const {
    isVisibleOnMount = true,
    shouldResetOnUrlChange = true,
    slowTriggerMs = 300,
  } = options ?? {};

  const [state, setState] = useState<AsyncStatus>(AsyncStatus.Idle);

  const handleLoad = useCallback(() => {
    setState(AsyncStatus.Success);
  }, []);
  const handleImageError = useCallback(() => {
    setState(AsyncStatus.Error);
  }, []);

  useEffect(
    function loadImageSource() {
      if (imageUrl && imageUrl.length > 0) {
        setState(AsyncStatus.Pending);

        const image = new Image();
        image.addEventListener('load', handleLoad);
        image.addEventListener('error', handleImageError);
        image.src = imageUrl;

        return () => {
          image.removeEventListener('load', handleLoad);
          image.removeEventListener('error', handleImageError);
        };
      }
    },
    [handleImageError, handleLoad, imageUrl],
  );

  useEffect(
    function allowFallbackWhenImageIsSlow() {
      let timer: ReturnType<typeof setTimeout>;
      if (isVisibleOnMount) {
        timer = setTimeout(() => {
          setState(AsyncStatus.Pending);
        }, slowTriggerMs);
      }
      return () => {
        clearTimeout(timer);
      };
    },
    [isVisibleOnMount, slowTriggerMs],
  );

  useEffect(
    function reset() {
      if (shouldResetOnUrlChange && state !== AsyncStatus.Idle) {
        setState(AsyncStatus.Idle);
      }
    },
    [imageUrl, shouldResetOnUrlChange, state],
  );

  return state;
}
