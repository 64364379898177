import { SVGProps, memo } from 'react';

const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#user_svg__a)" stroke="currentColor" strokeWidth={1.2}>
      <path
        d="M11.96 15.5a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25Z"
        strokeMiterlimit={10}
      />
      <path
        d="M4.086 19.25c1.926-2.292 4.646-3.75 7.875-3.75s5.948 1.458 7.875 3.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="user_svg__a">
        <path fill="#fff" transform="translate(.086 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgUser);
export default Memo;
