import { SVGProps, memo } from 'react';

const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.59 9.15a6.862 6.862 0 0 0-13.727 0c0 8.006-3.431 10.294-3.431 10.294H24.02s-3.432-2.288-3.432-10.294ZM15.705 24.019a2.287 2.287 0 0 1-3.958 0"
      stroke="#8D91A0"
      strokeWidth={2.287}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgBell);
export default Memo;
