import { ComponentProps, ReactElement } from 'react';
import {
  useSidebarContentLockState,
  useSidebarMenuContentState,
  useSidebarMenuState,
} from 'src/state/sidebar/sidebar';
import {
  FullPageContainer,
  useFullPageContainer,
} from '../../FullPageContainer';
import { ISidebarMenuContentProps } from '../../types';
import { MenuItem } from './MenuItem';

type SidebarMenuContentRenderer = (
  args: ISidebarMenuContentProps,
) => ReactElement;

interface IMenuItemWithModalProps extends ComponentProps<typeof MenuItem> {
  /**
   * Based on the current behavior, when closing a modal, it should also close the parent menu.
   * To do that, the menu's ID is also required.
   */
  menuId: string;
  /**
   * Unique ID for the rendered sidebar modal.
   * This is required for implementing the global focus logic of sidebar modals.
   */
  modalId: string;
  /**
   * Renderer function that receives state variables and callbacks
   * regarding the sidebar's focus state.
   *
   * This inversion of control allows
   * - keeping the unique modal render logic out of this component, and
   * - keeping focus state management logic inside this component.
   */
  modal: SidebarMenuContentRenderer;
}

export function MenuItemWithModal({
  menuId,
  modal,
  modalId,
  ...props
}: IMenuItemWithModalProps) {
  const [isOpen, setIsModalOpen] = useSidebarMenuContentState(modalId);
  const [, setIsMenuOpen] = useSidebarMenuState(menuId);
  const { getAnchorProps, getFloatingProps } = useFullPageContainer({
    isOpen,
    setIsOpen: (isOpen) => {
      setIsModalOpen(isOpen);
      setIsMenuOpen(isOpen);
    },
  });
  const [isLocked, onLockSidebar] = useSidebarContentLockState();

  return (
    <>
      <MenuItem
        {...props}
        {...getAnchorProps()}
        hasModal
        isActive={isOpen}
        onClick={() => setIsModalOpen(!isOpen)}
      />
      {isOpen && (
        <FullPageContainer {...getFloatingProps()}>
          {({ onClose }) =>
            modal({
              onClose,
              isLocked,
              onLockSidebar,
            })
          }
        </FullPageContainer>
      )}
    </>
  );
}
