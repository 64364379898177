import { SVGProps, memo } from 'react';

const SvgUserPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#userPlus_svg__a)" stroke="currentColor" strokeWidth={1.2}>
      <path
        d="M18.75 12.75h4.5M21 10.5V15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.125 15a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25Z"
        strokeMiterlimit={10}
      />
      <path
        d="M2.25 18.75C4.177 16.458 6.896 15 10.125 15s5.948 1.458 7.875 3.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="userPlus_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgUserPlus);
export default Memo;
