import { SVGProps, memo } from 'react';

const SvgEditPencilSimpleLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#editPencilSimpleLine_svg__a)"
      stroke="currentColor"
      strokeWidth={0.487}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.14 6.767H1.664a.246.246 0 0 1-.246-.246V5.146c0-.065.026-.127.072-.173l3.793-3.794a.246.246 0 0 1 .348 0l1.375 1.374a.246.246 0 0 1 0 .348L3.14 6.767ZM6.832 6.771H3.14M4.367 2.096 6.09 3.818" />
    </g>
    <defs>
      <clipPath id="editPencilSimpleLine_svg__a">
        <path
          fill="#fff"
          transform="translate(.188 .125)"
          d="M0 0h7.874v7.874H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgEditPencilSimpleLine);
export default Memo;
