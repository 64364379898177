import { SVGProps, memo } from 'react';

const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#refresh_svg__a)"
      stroke="currentcolor"
      strokeWidth={2.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M2.25 5.25v4.5m0 0h4.5m-4.5 0 3.916-3.584A8.25 8.25 0 1 1 6.336 18"
        strokeOpacity={0.8}
      />
      <path
        d="M2.25 5.25v4.5m0 0h4.5m-4.5 0 3.916-3.584A8.25 8.25 0 1 1 6.336 18"
        strokeOpacity={0.45}
      />
    </g>
    <defs>
      <clipPath id="refresh_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgRefresh);
export default Memo;
