import { IsBoolean, IsEnum, IsOptional, IsString } from 'class-validator';
import { CanvasWidgetType } from '@features/canvas/components/canvasComponent.types';
import { UnknownPartial } from 'src/services/database/types';
import { _CanvasItemConfigBaseDto } from './_canvasItemConfigBase.dto';

/**
 * Describes a canvas widget configuration that is used to instantiate a widget document in the database.
 * The widget element can be simple or complex, ranging from static text blocks to interactive drop zones.
 *
 * TODO replace flow editor's PredefinedCanvasContent with this
 */
export class CanvasWidgetConfigDto<
  TContent = UnknownPartial,
> extends _CanvasItemConfigBaseDto<TContent> {
  /**
   * AI evaluation will skip this node, and all of its content (including child nodes).
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsIgnoredOnEvaluate?: boolean;

  /**
   * Allows AI evaluation to use a custom prompt (instead of the prompt inherited from parent nodes)
   * for this node from the `aiCustomPrompt` field.
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsCustomPromptEnabled?: boolean;

  /**
   * Optional custom prompt for AI evaluation (instead of the prompt inherited from parent nodes).
   * This prompt is only considered if `aiCustomPromptEnabled` is `true`.
   * @default false
   */
  @IsOptional()
  @IsString()
  readonly aiCustomPrompt?: string;

  /**
   * Describes the content type that informs how to validate the unknown `content` property.
   */
  // TODO introduce DTOs for each widget type if feasible
  @IsEnum(CanvasWidgetType)
  readonly contentType: CanvasWidgetType;

  /**
   * TODO Not implemented yet.
   * Whether the user can update the item's _settings_ in the database.
   * Examples: rewriting the labels of a scoring grid.
   * @default false
   */
  @IsBoolean()
  readonly isConfigEditable: boolean;

  /**
   * TODO Not implemented yet.
   * Whether the user can update the item's _content_ (e.g. the cell of a table) in the database.
   * Examples: the content of a table cell; the assigned scores saved in a scoring grid.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  readonly isContentEditable?: boolean;

  /**
   * Whether the user can update the item's coordinates or parent nodes in the database.
   * @default false
   */
  @IsBoolean()
  readonly isMovable: boolean;

  constructor(dto: CanvasWidgetConfigDto<TContent>) {
    super(dto);
    this.aiCustomPrompt = dto.aiCustomPrompt;
    this.aiIsCustomPromptEnabled = dto.aiIsCustomPromptEnabled ?? false;
    this.aiIsIgnoredOnEvaluate = dto.aiIsIgnoredOnEvaluate ?? false;
    this.contentType = dto.contentType;
    this.isConfigEditable = dto.isConfigEditable ?? false;
    this.isContentEditable = dto.isContentEditable ?? true;
    this.isMovable = dto.isMovable ?? false;
  }
}
