import { SVGProps, memo } from 'react';

const SvgPrev = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47 10.047 17.97.27C18.787-.37 20 .2 20 1.224V20.78c0 1.025-1.212 1.595-2.03.955l-12.5-9.778a1.205 1.205 0 0 1 0-1.909ZM2.491 2.304a1.24 1.24 0 0 0-1.242-1.08C.56 1.224 0 1.772 0 2.447v17.11l.008.143c.073.608.601 1.08 1.242 1.08.69 0 1.25-.548 1.25-1.223V2.447l-.008-.143Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgPrev);
export default Memo;
