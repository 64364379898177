import {
  collection,
  doc,
  Firestore,
  getDoc,
  getFirestore,
  setDoc,
} from '@firebase/firestore';
import { validateSync } from 'class-validator';
import { User as FirebaseUser } from 'firebase/auth';
import { useCallback } from 'react';
import { generateGuestUserDisplayName } from 'src/services/auth/utils';
import { getPathForWorkspaces } from 'src/services/database/paths';
import { gatherValidationErrorConstraints } from 'src/services/database/validators/utils';
import { WorkspaceConverter } from 'src/services/database/Workspaces/converter';
import { WorkspaceDto } from 'src/services/database/Workspaces/dto/workspace.dto';
import { WorkspaceCreateDto } from 'src/services/database/Workspaces/dto/workspaceCreate.dto';
import {
  WorkspaceMemberDto,
  WorkspaceMemberRole,
} from 'src/services/database/Workspaces/dto/workspaceMember.dto';
import { WorkspaceUpdateDto } from 'src/services/database/Workspaces/dto/workspaceUpdate.dto';
import { trace } from 'src/services/telemetry/trace';
import { User } from 'src/state/user/types';
import { cloneObject } from '@shared/utils/object';

export function userToWorkspaceMember(
  user: User | FirebaseUser,
  role: WorkspaceMemberRole,
) {
  return new WorkspaceMemberDto({
    role,
    id: user.uid,
    displayName: user.displayName ?? generateGuestUserDisplayName(user.uid),
  });
}

/**
 * Creates a new workspace in Firestore.
 * Internal use only, use `useCreateWorkspace` instead.
 * @param dto - Workspace creation data
 * @param firestore - The Firestore instance. Defaults to the global Firestore instance.
 * @returns a promise that resolves with the newly created workspace
 * @throws `Error` if workspace validation fails or Firestore returns with an error
 * @internal
 * @see useCreateWorkspace
 */
export async function createWorkspace(
  dto: WorkspaceCreateDto,
  firestore: Firestore = getFirestore(),
): Promise<WorkspaceDto> {
  trace({
    message: 'Attempting to create a new workspace in Firestore.',
    level: 'info',
  });

  try {
    const errors = validateSync(new WorkspaceCreateDto(dto));
    if (errors.length > 0) {
      throw gatherValidationErrorConstraints(errors);
    }

    const workspace: Omit<WorkspaceDto, 'id'> = {
      ...dto,
      createdAt: Date.now(),
      logo: dto.logo ?? '',
    };

    const document = doc(
      collection(firestore, getPathForWorkspaces()),
      workspace.slug,
    ).withConverter(WorkspaceConverter);

    await setDoc(document, workspace);

    return new WorkspaceDto({
      ...workspace,
      id: workspace.slug,
    });
  } catch (error) {
    trace(error as Error);
    throw error;
  }
}

/**
 * Creates a callback that creates a new workspace in Firestore.
 * @returns a callback with a promise that resolves with the newly created workspace or throws an error
 */
export function useCreateWorkspace() {
  return useCallback((dto: WorkspaceCreateDto) => {
    return createWorkspace(dto);
  }, []);
}

/**
 * Update workspace in Firestore.
 * @param workspaceId - The workspace ID.
 * @param dto - The payload to update the workspace with, can be a partial workspace object.
 * @param partial - Whether to perform a partial update.
 * @returns A promise that resolves with void.
 */
async function updateWorkspace(
  workspaceId: string,
  dto: WorkspaceUpdateDto,
  partial: boolean = true,
): Promise<void> {
  trace({
    message: `Attempting to update workspace: ${workspaceId}`,
    data: dto,
    level: 'info',
  });

  try {
    const documentRef = doc(
      getFirestore(),
      getPathForWorkspaces(workspaceId),
    ).withConverter(WorkspaceConverter);
    const documentSnapshot = await getDoc(documentRef);
    if (!documentSnapshot.exists()) {
      throw new Error(`Workspace with slug ${workspaceId} was not found`);
    }

    await setDoc(documentRef, cloneObject(dto), { merge: partial });
  } catch (error) {
    trace(error as Error);
    throw error;
  }
}

``;

/**
 * Creates a callback that updates a workspace in Firestore.
 * @param workspaceId - The workspace ID.
 * @returns A callback with a promise that resolves with void or throws an error.
 */
export function usePartialUpdateWorkspace(workspaceId: string) {
  return useCallback(
    async (dto: WorkspaceUpdateDto) => {
      return updateWorkspace(workspaceId, dto, true);
    },
    [workspaceId],
  );
}

/**
 * Add member to the workspace.
 * Internal use only, use `useAddMemberToWorkspace` instead.
 * @param workspaceId - The workspace ID.
 * @param member - The new member object.
 * @param firestore - The Firestore instance. Defaults to the global Firestore instance.
 * @returns A callback with a promise that resolves with void or throws an error.
 * @throws `Error` if Firestore returns with an error.
 * @internal
 * @see useAddMemberToWorkspace
 */
export async function addMemberToWorkspace(
  workspaceId: string,
  member: WorkspaceMemberDto,
  firestore?: Firestore,
): Promise<void> {
  trace({
    message: `Adding member to workspace: ${workspaceId}`,
    data: member,
    level: 'info',
  });

  try {
    const documentRef = doc(
      firestore ?? getFirestore(),
      getPathForWorkspaces(workspaceId),
    ).withConverter(WorkspaceConverter);

    const documentSnapshot = await getDoc(documentRef);
    if (!documentSnapshot.exists()) {
      throw new Error(`Workspace with slug ${workspaceId} was not found`);
    }

    const members = documentSnapshot.data().members;
    if (member.id in members) {
      throw new Error(
        `Member with ID ${member.id} already exists in the workspace. Role escalation or demotion is not supported via this method.`,
      );
    }

    members[member.id] = { ...member };

    await setDoc(documentRef, { members }, { merge: true });
  } catch (error) {
    trace(error as Error);
    throw error;
  }
}

/**
 * Creates a callback that adds a member to the workspace.
 * @param workspaceId - The workspace ID.
 * @returns A callback with a promise that resolves with void or throws an error.
 */
export function useAddMemberToWorkspace(workspaceId: string) {
  return useCallback(
    async (member: WorkspaceMemberDto) => {
      return addMemberToWorkspace(workspaceId, member);
    },
    [workspaceId],
  );
}
