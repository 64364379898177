import { useCallback } from 'react';
import { PasswordReminderForm } from 'src/app/components/auth/types';
import { sendUserPasswordResetEmail } from 'src/services/auth';

/**
 * @returns Memoized callback that resets user password
 */
export default function usePasswordReminderCallback(
  args: { onSuccess?: () => void } = {},
) {
  const { onSuccess } = args;
  return useCallback(
    async (email: PasswordReminderForm) => {
      await sendUserPasswordResetEmail(email);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    },
    [onSuccess],
  );
}
