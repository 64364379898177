import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useClientPoint,
  useDelayGroupContext,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import {
  createContext,
  useContext,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface TooltipOptions {
  /**
   * Controls whether to show the tooltip on first render.
   * @default false
   */
  initialOpen?: boolean;
  /**
   * If `true`, the tooltip will follow the pointer's coordinate, while respecting its `placement`.
   *
   * In this case, the pointer tooltip **will become non-interactive**.
   * Otherwise it would be possible to "catch" the tooltip with the pointer,
   * which would suspend the tooltip's coordinate calculation.
   *
   * For now, `rootBoundary` is not implemented, so the tooltip won't be contained within any container,
   * only by the viewport.
   */
  isPointerTooltip?: boolean;
  /**
   * Controls the tooltip's preferred position relative to its anchor element.
   * @default "top"
   */
  placement?: Placement;
  /**
   * Controls the tooltip's distance from the anchor element in `px`.
   * @default 5
   */
  offsetPx?: number;
  /**
   * Allows controlling the tooltip's state from the outside.
   */
  isOpen?: boolean;
  /**
   * Allows controlling the tooltip's state from the outside.
   */
  setIsOpen?: (open: boolean) => void;
}

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  offsetPx = 5,
  isPointerTooltip,
  isOpen: controlledOpen,
  setIsOpen: setControlledOpen,
}: TooltipOptions = {}) {
  //#region  =========== Open/closed state ===========
  const [isUncontrolledOpen, setIsUncontrolledOpen] = useState(initialOpen);
  const isOpen = controlledOpen ?? isUncontrolledOpen;
  const setOpen = setControlledOpen ?? setIsUncontrolledOpen;
  //#endregion  ======== Open/closed state ===========

  //#region  =========== Tooltip config ===========
  const arrowRef = useRef(null);
  const data = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetPx),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });
  //#endregion  ======== Tooltip config ===========

  //#region  =========== Interaction utils ===========
  const { delay } = useDelayGroupContext();
  const context = data.context;
  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
    delay,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const clientPoint = useClientPoint(context, {
    enabled: Boolean(isPointerTooltip),
  });
  const interactions = useInteractions([
    hover,
    focus,
    dismiss,
    role,
    clientPoint,
  ]);
  //#endregion  ======== Interaction utils ===========

  const id = useId();
  return useMemo(
    () => ({
      arrowRef,
      id,
      open: isOpen,
      setOpen,
      ...interactions,
      ...data,
      isPointerTooltip,
    }),
    [id, isOpen, setOpen, interactions, data, isPointerTooltip],
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

export const TooltipContext = createContext<ContextType>(null);

export const useTooltipState = () => {
  const ctx = useContext(TooltipContext);
  if (ctx == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }
  return ctx;
};
