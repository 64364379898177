import { Navigate, NavigateProps } from 'react-router-dom';
import { useTypedAutofillParams } from 'src/app/router/hooks';
import { PathParams } from 'src/app/router/routeValidation';
import { Path, buildPath } from 'src/app/router/routes';

type TypedNavigateProps<P extends Path> = Pick<
  NavigateProps,
  'replace' | 'state'
> & {
  /** Valid path within the app. See `src/app/routes.ts` for valid paths. */
  to: P;
  /** A map of path param values. Allowed only if the path in the `to` prop includes params. */
  params?: PathParams;
  /**
   * Whether to automatically fill in workspace and flow slugs if they are not provided.
   * @default true
   */
  shouldAutoFill?: boolean;
  /** Query params to be added to the URL. */
  query?: Record<string, string>;
};

/**
 * Type-safe implementation of React Router's `<Link/>`,
 * which makes entering incorrect path params impossible.
 *
 * All props of the original `<Link/>` component are allowed.
 * @see `src/app/routes.ts` for valid paths.
 */
export function TypedNavigate<P extends Path>({
  to,
  params,
  shouldAutoFill = true,
  query = {},
  ...allProps
}: TypedNavigateProps<P>) {
  const autofilledParams = useTypedAutofillParams(params, shouldAutoFill);
  let url = buildPath(to, autofilledParams);
  if (Object.keys(query).length > 0) {
    const separator = url.includes('?') ? '&' : '?';
    url += separator + new URLSearchParams(query).toString();
  }

  return <Navigate to={url} {...allProps} />;
}
