import clsx from 'clsx';
import { Flex } from 'src/app/components/shared/Flex';
import { EmailSignUpForm } from '../../EmailSignUpForm';
import { brandPrimaryText, centerText, h4Text } from '../../auth.css';
import { SignUpForm } from '../../types';

interface IEmailSignUpPageProps {
  headingId: string;
  onEmailSignUp: (values: SignUpForm) => Promise<void>;
  onVendorAuth: () => void | Promise<void>;
}

export function EmailSignUpPage({
  headingId,
  onEmailSignUp,
  onVendorAuth,
}: IEmailSignUpPageProps) {
  return (
    <>
      <Flex as="header" direction="column" style={{ marginBottom: '24px' }}>
        <h1
          id={headingId}
          className={clsx(h4Text, brandPrimaryText, centerText)}
          style={{ margin: 0 }}
        >
          Sign up to Superflow
        </h1>
      </Flex>
      <EmailSignUpForm.Root onSignUp={onEmailSignUp}>
        <EmailSignUpForm.DefaultLayout>
          <EmailSignUpForm.InputFields />
          <EmailSignUpForm.Buttons onVendorAuthSuccess={onVendorAuth} />
          <EmailSignUpForm.Errors />
          <EmailSignUpForm.LegalLinks />
        </EmailSignUpForm.DefaultLayout>
      </EmailSignUpForm.Root>
    </>
  );
}
