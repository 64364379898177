import { motion, useAnimation } from 'framer-motion';
import { CSSProperties, PropsWithChildren, useEffect } from 'react';
import { Flex } from '../../../../shared/Flex';

interface ICogSpinAnimationProps {
  className?: string;
  isFocused?: boolean;
  style?: CSSProperties;
}

/**
 * Implements a "ticking" animation for the cog icon as long as it's focused.
 */
export const CogSpinAnimation = ({
  children,
  className,
  isFocused,
  style,
}: PropsWithChildren<ICogSpinAnimationProps>) => {
  const controls = useAnimation();

  useEffect(
    function animateOnFocus() {
      if (isFocused) {
        const COG_TEETH_COUNT = 8;
        controls.start({
          rotate: 360 / COG_TEETH_COUNT,
          transition: {
            type: 'keyframes',
            duration: 1,
            repeat: Number.POSITIVE_INFINITY,
            ease: [0.25, 1, 0.5, 1.25],
          },
        });
      } else {
        controls.start({ rotate: 0 });
      }
    },
    [controls, isFocused],
  );

  return (
    <Flex className={className} style={{ display: 'inline-block', ...style }}>
      <motion.div animate={controls} initial={{ rotate: 0 }}>
        {children}
      </motion.div>
    </Flex>
  );
};
