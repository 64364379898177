import { CSSProperties } from 'react';

/**
 * Collection of valid workspace logo background colors.
 * @see https://www.figma.com/file/jzl8S1CXNtnCDdcPR7VLpY/%F0%9F%9A%80-Design-System-%5BSuperflow%5D?type=design&node-id=5762-217753&mode=dev
 */
export const workspaceLogoColors: CSSProperties['backgroundColor'][] = [
  'hsla(14, 63%, 53%, 1)',
  'hsla(36, 74%, 53%, 1)',
  'hsla(58, 63%, 53%, 1)',
  'hsla(68, 63%, 53%, 1)',
  'hsla(130, 63%, 53%, 1)',
  'hsla(169, 63%, 53%, 1)',
  'hsla(191, 63%, 53%, 1)',
  'hsla(219, 63%, 53%, 1)',
  'hsla(263, 63%, 53%, 1)',
  'hsla(292, 66%, 55%, 1)',
  'hsla(328, 63%, 53%, 1)',
  'hsla(346, 63%, 53%, 1)',
];

/**
 * Hashes the workspace name, so a random but deterministic background color can be used.
 * @param name - Workspace name used for hashing
 * @returns A deterministic workspace background color value
 */
export function getWorkspaceColorFromName(
  name: string,
): CSSProperties['backgroundColor'] {
  if (!name) {
    return workspaceLogoColors[0] ?? 'transparent';
  }

  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    const charCode = name.codePointAt(i) ?? 0;
    hash = (hash * 33) ^ charCode;
  }
  const colorIx = Math.abs(hash) % workspaceLogoColors.length;
  return workspaceLogoColors[colorIx] ?? 'transparent';
}
