import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';
import { fillParent, stackedLayer } from 'src/styles/utils.css';
import { Flex } from '../Flex';
import { Spinner } from '../Spinner';
import { buttonStyles, labelStyles, labelWrapper } from './styles/index.css';
import { IButtonPillProps } from './types';

/**
 * Renders a pill-shaped button whose color and size can be configured.
 *
 * When using a secondary button,
 * **make sure to match the container's background color**
 * by setting the `secondaryBgColor` prop!
 */
export function ButtonPill({
  ariaTrigger,
  children,
  className,
  disabled = false,
  intent = 'primary',
  loading = false,
  secondaryBgColor = 'white',
  size = 'regular',
  slotLeft,
  slotRight,
  theme = 'light',
  type,
  ...props
}: PropsWithChildren<IButtonPillProps>) {
  const isLocked = disabled || loading;
  return (
    <motion.button
      // eslint-disable-next-line react/button-has-type
      type={type ?? 'button'}
      aria-busy={loading}
      aria-haspopup={ariaTrigger?.type}
      aria-controls={ariaTrigger?.controlledId}
      disabled={isLocked}
      {...props}
      className={clsx(
        buttonStyles({
          disabled,
          intent,
          loading,
          size,
          theme,
        }),
        className,
      )}
      transition={{ type: 'tween', ease: 'easeOut', duration: 0.02 }}
      whileHover={
        isLocked
          ? undefined
          : {
              scale: 1.06,
            }
      }
      whileFocus={isLocked ? undefined : { scale: 1.06 }}
      whileTap={isLocked ? undefined : { scale: 0.94 }}
    >
      <div
        className={labelWrapper}
        style={{
          backgroundColor:
            theme === 'light' && intent === 'secondary'
              ? secondaryBgColor
              : undefined,
        }}
      >
        {loading && (
          <Flex
            className={clsx(stackedLayer, fillParent)}
            style={{ zIndex: 2 }}
          >
            <Spinner
              type="circleBall"
              size="1.1em"
              color={'inherit'}
              className={labelStyles({ intent, disabled, loading, theme })}
            />
          </Flex>
        )}
        <Flex
          justify={slotLeft || slotRight ? 'space-between' : 'space-around'}
          gap="10px"
          className={clsx(
            fillParent,
            labelStyles({ intent, disabled, loading, theme }),
          )}
          style={{
            visibility: loading ? 'hidden' : 'visible',
          }}
        >
          {slotLeft && <div>{slotLeft}</div>}
          <div>{children}</div>
          {slotRight && <div>{slotRight}</div>}
        </Flex>
      </div>
    </motion.button>
  );
}
