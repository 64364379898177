import {
  IsArray,
  IsNumber,
  IsOptional,
  IsString,
  MinLength,
  ValidateNested,
} from 'class-validator';
import { LocaleMap, TextObject, UUID } from 'src/@types/common';
import { OpenAiResponseSchema } from 'src/services/ai/openAiResponseSchema.type';
import { IsOpenAiResponseSchema } from 'src/services/ai/openAiResponseSchema.validators';
import { FlowDefinitionAuthorDto } from 'src/services/database/FlowDefinitions/dto/flowDefinitionAuthor.dto';
import { FlowDefinitionModuleDto } from 'src/services/database/FlowDefinitions/dto/flowDefinitionModule.dto';
import type { FlowDefinitionWorkspaceId } from 'src/services/database/FlowDefinitions/validators';
import { IsFlowDefinitionWorkspaceId } from 'src/services/database/FlowDefinitions/validators';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import {
  IsFirestoreTimestamp,
  IsFirestoreUUID,
  IsLocaleText,
} from 'src/services/database/validators/validators';
import { FLOW_DEFINITION_GLOBAL_WORKSPACE_ID } from '../constants';
import { CanvasSessionDto } from './CanvasSessions/canvasSession.dto';

/**
 * Defines metadata of a flow definition document.
 */
export class FlowDefinitionDto {
  /** Unique identifier of the flow definition */
  @IsFirestoreUUID()
  readonly id: UUID;

  @IsFirestoreTimestamp({ allowNumber: true })
  readonly createdAt: number;

  /** Unique identifier of the parent flow definition, if any */
  @IsOptional()
  @IsFirestoreUUID()
  readonly parentId?: UUID;

  /** Unique identifier of the ancestor of this and all parent flow definitions, if any */
  @IsOptional()
  @IsFirestoreUUID()
  readonly ancestorId?: UUID;

  /** Slug used in URLs to identify the flow definition */
  @MinLength(1)
  readonly slug: string;

  /** Title rendered. */
  @IsLocaleText()
  readonly title: LocaleMap<TextObject>;

  /** Description shown on the flow landing page. */
  @IsLocaleText()
  @IsOptional()
  readonly description?: LocaleMap<TextObject>;

  /** Author metadata */
  @ValidateNested()
  readonly author: FlowDefinitionAuthorDto;

  /** Workspace ID where the flow definition was created. If "*" it is a global flow. */
  @IsFlowDefinitionWorkspaceId()
  readonly workspaceId: FlowDefinitionWorkspaceId;

  /** Duration of the flow in minutes */
  @IsNumber()
  readonly duration: number;

  /** URL of the banner image displayed on the flow landing page */
  @IsString()
  readonly bannerImageUrl: string;

  /** URL of the flow landing page */
  @IsString()
  @IsOptional()
  readonly landingPageUrl?: string;

  /** Prompt for the AI model */
  @IsString()
  @IsOptional()
  readonly aiPrompt?: string;

  /**
   * Schema for the AI response
   * @see {OpenAiResponseSchema}
   * @see https://openai.com/index/introducing-structured-outputs-in-the-api/
   */
  @IsOpenAiResponseSchema()
  @IsOptional()
  readonly aiOpenAiResponseSchema?: OpenAiResponseSchema;

  /** Flow modules that contain references to session definitions */
  @IsArray()
  @ValidateNested({ each: true })
  readonly modules: FlowDefinitionModuleDto[];

  /** Session definitions included in the flow */
  @IsArray()
  @ValidateNested({ each: true })
  readonly sessions: CanvasSessionDto[];

  /** Tags associated with the flow */
  @IsArray()
  @MinLength(1, { each: true })
  readonly tags: FlowDefinitionTag[];

  /** Skills associated with the flow */
  @IsArray()
  @IsFirestoreUUID({ each: true })
  readonly skills: UUID[];

  /** Roles associated with the flow, if the flow is for HR */
  @IsArray()
  @MinLength(1, { each: true })
  readonly roles: string[];

  constructor(dto: FlowDefinitionDto) {
    this.id = dto.id;
    this.createdAt = dto.createdAt;
    this.parentId = dto.parentId;
    this.ancestorId = dto.ancestorId;
    this.slug = dto.slug;
    this.title = dto.title;
    this.description = dto.description;
    this.author = new FlowDefinitionAuthorDto(dto.author);
    this.workspaceId = dto.workspaceId ?? FLOW_DEFINITION_GLOBAL_WORKSPACE_ID; // TODO [KS] Remove default value
    this.duration = dto.duration;
    this.bannerImageUrl = dto.bannerImageUrl;
    this.landingPageUrl = dto.landingPageUrl;
    this.aiPrompt = dto.aiPrompt;
    this.aiOpenAiResponseSchema = dto.aiOpenAiResponseSchema;
    this.modules = dto.modules?.map(
      (module) => new FlowDefinitionModuleDto(module),
    );
    this.sessions = dto.sessions.map(
      (session) => new CanvasSessionDto(session),
    );
    this.tags = dto.tags ?? [FlowDefinitionTag.Development]; // TODO [KS] Remove default value
    this.skills = dto.skills ?? [];
    this.roles = dto.roles ?? [];
  }
}
