import { SVGProps, memo } from 'react';

const SvgSignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#signOut_svg__a)"
      stroke="currentColor"
      strokeWidth={0.487}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.387 1.355H1.664a.246.246 0 0 0-.246.247v4.921a.246.246 0 0 0 .246.247h1.723M3.387 4.063h3.445M5.602 2.832l1.23 1.23-1.23 1.231" />
    </g>
    <defs>
      <clipPath id="signOut_svg__a">
        <path
          fill="#fff"
          transform="translate(.188 .125)"
          d="M0 0h7.875v7.875H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgSignOut);
export default Memo;
