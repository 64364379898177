import { SVGProps, memo } from 'react';

const SvgUnlink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.636 3.636 5.653 5.653 2.132-2.132a1.005 1.005 0 1 1 1.422 1.422L10.71 10.71l5.654 5.654a1 1 0 0 1-1.414 1.414l-2.811-2.81-3.56 3.56a5.025 5.025 0 0 1-7.258-6.95l.15-.157 3.561-3.56-2.81-2.81a1 1 0 1 1 1.414-1.415Zm2.817 5.646-3.56 3.56a3.015 3.015 0 0 0 4.135 4.386l.13-.121 3.559-3.561-1.421-1.421-.717.718a1.005 1.005 0 0 1-1.422-1.422l.718-.717-1.422-1.422Zm12.075-7.81a5.025 5.025 0 0 1 0 7.107l-2.843 2.842A1.005 1.005 0 1 1 14.265 10l2.842-2.843a3.015 3.015 0 1 0-4.264-4.264L10 5.736a1.005 1.005 0 1 1-1.421-1.421l2.842-2.843a5.025 5.025 0 0 1 7.107 0Zm-7.82 6.407L9.292 9.293l1.414 1.414 1.414-1.414-1.414-1.414Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgUnlink);
export default Memo;
