import { registerDecorator, ValidationOptions } from 'class-validator';
import { InvalidResourceIdError } from './InvalidResourceId.error';
import {
  VALIDATOR_MATCHES_RESOURCE_ID,
  VALIDATOR_MATCHES_RESOURCE_ID_WITH_REQUIRED_ID,
} from '../validators';

/**
 * Represents a resource ID.
 * The product and collection is required, and the ID is optional.
 * This allows for the following formats:
 * - `<product>/<collection>/<id>`
 * - `<product>/<collection>`
 * - `<protocol>://<host>/<product>/<collection>/<id>`
 * - `<protocol>://<host>/<product>/<collection>`
 */
export type ResourceId = string;

/**
 * Parsed representation of a resource ID.
 */
export type ParsedResourceId = {
  /** Product name, like `superflow`. */
  readonly product: string;

  /** Collection name, like `workspaces`. */
  readonly collection: string;

  /** ID of the resource, like `e2eab57b-520a-4f82-b4db-be7446d6a96a`. Can be omitted if resource is a collection. */
  readonly id?: string;
};

/**
 * Parses a resource ID into its component parts.
 * @param resourceId - The resource ID to parse.
 * @returns An object containing the parsed parts.
 * @throws {Error} If the resource ID is invalid.
 */
export function parseResourceId(resourceId: ResourceId): ParsedResourceId {
  if (!isResourceId(resourceId)) {
    throw new InvalidResourceIdError(resourceId);
  }

  if (resourceId.includes('://')) {
    resourceId = resourceId.replace(/^.+:\/\/.+?\//, '');
  }

  const [product, collection, id] = resourceId.split('/') as [
    string,
    string,
    string | undefined,
  ];

  return { product, collection, id };
}

/**
 * Creates a resource ID from a parsed resource ID object.
 * @param parsedResourceId - The parsed resource ID object.
 * @returns The created resource ID.
 * @throws {Error} If the parsed resource ID is invalid.
 */
export function createResourceId(
  parsedResourceId: ParsedResourceId,
): ResourceId {
  const { product, collection, id } = parsedResourceId;

  if (!product) {
    throw new InvalidResourceIdError('Missing product');
  }

  if (!collection) {
    throw new InvalidResourceIdError('Missing collection');
  }

  return [product, collection, id].filter(Boolean).join('/');
}

interface IIsResourceIdOptions {
  /** Whether the resource ID must include an ID. */
  requireId?: boolean;
}

/**
 * Checks if a resource ID is valid.
 * @param resourceId - The resource ID to check.
 * @param options - Resource ID validation options.
 * @returns `true` if the resource ID is valid, `false` otherwise.
 */
export function isResourceId(
  resourceId: unknown,
  options: IIsResourceIdOptions = {},
): resourceId is ResourceId {
  return (
    typeof resourceId === 'string' &&
    (options.requireId
      ? VALIDATOR_MATCHES_RESOURCE_ID_WITH_REQUIRED_ID.test(resourceId)
      : VALIDATOR_MATCHES_RESOURCE_ID.test(resourceId))
  );
}

/**
 * Class validator decorator to check if the value is a resource ID.
 * @param options - Resource ID validation options.
 * @param validatorOptions - Validator options
 */
export function IsResourceId(
  options: IIsResourceIdOptions = {},
  validatorOptions?: ValidationOptions,
) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isResourceId',
      target: object.constructor,
      propertyName,
      options: validatorOptions,
      validator: {
        validate(value: unknown) {
          return isResourceId(value, options);
        },
      },
    });
  };
}
