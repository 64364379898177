import { HTMLAttributes, useId, useMemo, useState } from 'react';

type HTMLProps = HTMLAttributes<HTMLDivElement>;

/**
 * @returns State hook and a11y props for implementing a generic accordion behavior.
 */
export default function useAccordion(args?: { isOpenDefault?: boolean }) {
  const id = useId();
  const [isOpen, setIsOpen] = useState(args?.isOpenDefault ?? false);

  return useMemo(
    () => ({
      /** `true` if the accordion content should be rendered. */
      isOpen,
      /** Setter for toggling the accordion state. */
      setIsOpen: setIsOpen,
      /** Spread these a11y props over the accordion's wrapper element. */
      getAccordionProps: (): HTMLProps => ({
        role: 'region',
        'aria-labelledby': `${id}-header`,
        'aria-expanded': isOpen,
      }),
      /** Spread these a11y props over the toggle button that controls the accordion. */
      getHeaderProps: (): HTMLProps => ({
        id: `${id}-header`,
        onClick: () => setIsOpen(!isOpen),
        'aria-controls': `${id}-content`,
        'aria-expanded': isOpen,
      }),
      /** Spread these a11y props over the conditionally rendered accordion content. */
      getContentProps: (): HTMLProps => ({
        id: `${id}-content`,
      }),
    }),
    [id, isOpen],
  );
}
