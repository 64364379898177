import { SVGProps, memo } from 'react';

const SvgUploadCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 66 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.588 14.105a.691.691 0 0 1-.465-.86c1.271-4.272 5.23-7.41 9.942-7.41a.692.692 0 0 1 0 1.384c-3.948 0-7.492 2.64-8.617 6.42a.69.69 0 0 1-.86.466Z"
      fill="currentColor"
    />
    <path
      d="M34.588 14.105a.691.691 0 0 1-.465-.86c1.271-4.272 5.23-7.41 9.942-7.41a.692.692 0 0 1 0 1.384c-3.948 0-7.492 2.64-8.617 6.42a.69.69 0 0 1-.86.466Z"
      stroke="currentColor"
    />
    <path
      d="M53.745 40.41h-4.149a.692.692 0 0 1 0-1.384h4.149c5.719 0 10.372-4.653 10.372-10.372 0-5.72-4.653-10.372-10.372-10.372h-.1a.691.691 0 0 1-.684-.79c.061-.431.093-.864.093-1.285 0-4.956-4.033-8.99-8.99-8.99a8.916 8.916 0 0 0-5.318 1.744.692.692 0 0 1-1.022-.236C33.332.361 21.86-.762 15.911 6.514a13.1 13.1 0 0 0-2.7 10.939.692.692 0 0 1-.679.829h-.277c-5.719 0-10.372 4.653-10.372 10.372 0 5.72 4.653 10.373 10.372 10.373h4.15a.692.692 0 0 1 0 1.383h-4.15C5.774 40.41.5 35.136.5 28.654c0-6.3 4.982-11.458 11.213-11.743A14.47 14.47 0 0 1 14.84 5.64c6.368-7.79 18.572-6.918 23.73 1.77a10.283 10.283 0 0 1 5.493-1.574c6.005 0 10.763 5.111 10.347 11.083 6.173.346 11.089 5.477 11.089 11.736 0 6.482-5.274 11.755-11.755 11.755Z"
      fill="currentColor"
    />
    <path
      d="M32.905 56.614c-9.533 0-17.288-7.755-17.288-17.288 0-9.532 7.755-17.287 17.288-17.287 9.532 0 17.287 7.755 17.287 17.287 0 9.533-7.755 17.288-17.287 17.288Zm0-33.192C24.135 23.422 17 30.557 17 39.326c0 8.77 7.135 15.904 15.905 15.904 8.769 0 15.904-7.134 15.904-15.904s-7.135-15.904-15.904-15.904Z"
      fill="currentColor"
    />
    <path
      d="M32.905 56.614c-9.533 0-17.288-7.755-17.288-17.288 0-9.532 7.755-17.287 17.288-17.287 9.532 0 17.287 7.755 17.287 17.287 0 9.533-7.755 17.288-17.287 17.288Zm0-33.192C24.135 23.422 17 30.557 17 39.326c0 8.77 7.135 15.904 15.905 15.904 8.769 0 15.904-7.134 15.904-15.904s-7.135-15.904-15.904-15.904Z"
      stroke="currentColor"
    />
    <path
      d="M33.217 46.792a.506.506 0 0 1-.506-.506V33.123a.506.506 0 0 1 1.012 0v13.163c0 .28-.226.506-.506.506Z"
      fill="currentColor"
    />
    <path
      d="M33.217 46.792a.506.506 0 0 1-.506-.506V33.123a.506.506 0 0 1 1.012 0v13.163c0 .28-.226.506-.506.506Z"
      stroke="currentColor"
    />
    <path
      d="M37.27 37.68c-.13 0-.259-.05-.358-.149L33.22 33.84l-3.692 3.691a.506.506 0 1 1-.716-.715l4.05-4.05a.506.506 0 0 1 .716 0l4.05 4.05a.506.506 0 0 1-.358.864Z"
      fill="currentColor"
    />
    <path
      d="M37.27 37.68c-.13 0-.259-.05-.358-.149L33.22 33.84l-3.692 3.691a.506.506 0 1 1-.716-.715l4.05-4.05a.506.506 0 0 1 .716 0l4.05 4.05a.506.506 0 0 1-.358.864Z"
      stroke="currentColor"
    />
  </svg>
);

const Memo = memo(SvgUploadCloud);
export default Memo;
