import { SVGProps, memo } from 'react';

const SvgNext = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 20.78V1.223C0 .2 1.212-.37 2.03.27l12.5 9.777c.626.49.626 1.42 0 1.91l-12.5 9.777c-.818.64-2.03.07-2.03-.955ZM19.992 2.303a1.24 1.24 0 0 0-1.242-1.08c-.69 0-1.25.548-1.25 1.223v17.11l.008.143c.073.608.601 1.08 1.242 1.08.69 0 1.25-.548 1.25-1.223V2.447l-.008-.143Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgNext);
export default Memo;
