import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  updateProfile,
} from '@firebase/auth';
import { isError } from '@sentry/utils';
import { setTelemetryUserId } from 'src/utils/userContent';
import { trace } from '../telemetry';
import { traceError } from '../telemetry/trace';
import { isIgnoredAuthError } from './utils';

/**
 * Authenticates an anonymous user with the Firebase Auth package.
 *
 * Errors aren't thrown, only traced through telemetry.
 * @returns A promise that resolves with the workspace object.
 */
export async function signInWithAnonymousUser(knownUserId?: string) {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Anonymous auth...',
    });
    if (knownUserId) {
      trace({
        category: 'auth',
        level: 'info',
        message: `Existing anonymous user: ${knownUserId}`,
      });
    }

    const userCredential = await signInAnonymously(getAuth());
    setTelemetryUserId(userCredential.user.uid);
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Anonymous auth error',
    });
    throw error;
  }
}

function getCredentialsForEmailAuthProvider(email: string, password: string) {
  return EmailAuthProvider.credential(email, password);
}

/**
 * Converts an anonymous user to a regular user with email and password.
 * @param signUpUser - The user object containing email and password.
 */
async function convertAnonymousToUser(signUpUser: {
  email: string;
  password: string;
}) {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Transform guest user to signed up user...',
    });

    const userCredential = await linkWithCredential(
      getAuth().currentUser!,
      getCredentialsForEmailAuthProvider(signUpUser.email, signUpUser.password),
    );

    setTelemetryUserId(userCredential.user.uid);
    return userCredential;
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Transform guest user to signed up user error',
    });
    throw error;
  }
}

/**
 * Asynchronously signs up a user with an email and password using Firebase authentication.
 * If the current user is guest, it converts the anonymous account to a regular account.
 * Otherwise, it creates a new user account with the provided email and password.
 *
 * @param signUpUser - The user object containing email and password.
 * @returns A promise that resolves with the authentication result.
 * @throws Will throw an error if the authentication process fails.
 */
export async function signUpWithEmail(signUpUser: {
  email: string;
  password: string;
}) {
  const firebaseAuth = getAuth();
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: firebaseAuth.currentUser?.isAnonymous
        ? `[Firebase] Converting guest user with uid: ${firebaseAuth.currentUser?.uid} to regular user ...`
        : '[Firebase] Signing up user with email and password ...',
    });

    const result = await (firebaseAuth.currentUser &&
    firebaseAuth.currentUser.isAnonymous
      ? convertAnonymousToUser(signUpUser)
      : createUserWithEmailAndPassword(
          firebaseAuth,
          signUpUser.email,
          signUpUser.password,
        ));

    setTelemetryUserId(result?.user.uid);
    return result;
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: firebaseAuth.currentUser?.isAnonymous
        ? '[Firebase] Converting guest user to regular user error'
        : '[Firebase] Sign up user with email and password error',
    });
    throw error;
  }
}

/**
 * Update a user's profile. Only Display name and photoUrl updates are possible.
 * @param updateUser - The user object containing display name and photo url.
 */
export async function updateUserData(updateUser: {
  displayName?: string;
  photoURL?: string;
}) {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Updating user data with display name / photo url...',
    });
    const user = getAuth().currentUser!;
    //Update profile method only accepts display name and photoURL properties
    return await updateProfile(user, {
      displayName: updateUser.displayName,
      photoURL: updateUser.photoURL,
    });
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message:
        '[Firebase] Updating user data with display name / photo url caused an error',
    });
    throw error;
  }
}

export async function loginWithEmailAndPassword(loginUser: {
  email: string;
  password: string;
}) {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Login with email and password...',
    });

    const req = await signInWithEmailAndPassword(
      getAuth(),
      loginUser.email,
      loginUser.password,
    );
    setTelemetryUserId(req.user.uid);
    return req;
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Login with email and password error',
    });
    throw error;
  }
}

export async function loginWithGoogle() {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Google login...',
    });
    const req = await signInWithPopup(getAuth(), new GoogleAuthProvider());
    setTelemetryUserId(req.user.uid);
    return req;
  } catch (error) {
    if (isError(error) && isIgnoredAuthError(error.message)) {
      return;
    }
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Google login error',
    });
    throw error;
  }
}

export async function logOut() {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Logout...',
    });
    const req = await getAuth().signOut();
    setTelemetryUserId(undefined);
    return req;
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Logout error',
    });
    throw error;
  }
}

export async function updateUserPassword(
  newPassword: string,
  passwordConfirmation: string,
) {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Updating password...',
    });
    const user = getAuth().currentUser!;
    if (user && newPassword === passwordConfirmation) {
      return await updatePassword(user, newPassword);
    }
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Update password error',
    });
    throw error;
  }
}

export async function sendUserPasswordResetEmail(PasswordReminderForm: {
  email: string;
}) {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Password reset email...',
    });
    return await sendPasswordResetEmail(getAuth(), PasswordReminderForm.email);
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Password reset error',
    });
    throw error;
  }
}

export async function resetUserPassword(
  actionCode: string,
  newPassword: string,
) {
  try {
    trace({
      category: 'auth',
      level: 'info',
      message: '[Firebase] Reset password...',
    });
    return await confirmPasswordReset(getAuth(), actionCode, newPassword);
  } catch (error) {
    traceError(error, {
      category: 'auth',
      level: 'error',
      message: '[Firebase] Reset password error',
    });
    throw error;
  }
}
