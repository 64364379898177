import { useCallback, useEffect, useRef, useState } from 'react';

export enum AsyncStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export interface IAsyncResponse<T> {
  status: AsyncStatus;
  value: T | null;
  error: unknown | null;
  execute: () => Promise<void>;
  reset: () => void;
}

interface IUseAsyncOptions {
  /** If `true`, the function is called immediately as an effect. Defaults to `false`. */
  isImmediate?: boolean;
  /** If `true`, the function can be called multiple times in parallel. Defaults to `false`. */
  isParallel?: boolean;
}

/**
 * Based on https://usehooks.com/useAsync/
 * @param asyncFunction
 * @param options
 */
export default function useAsync<T>(
  asyncFunction: () => Promise<T>,
  options: IUseAsyncOptions = {},
): IAsyncResponse<T> {
  const [status, setStatus] = useState<AsyncStatus>(AsyncStatus.Idle);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<unknown | null>(null);

  const reset = useCallback(() => {
    setStatus(AsyncStatus.Idle);
    setValue(null);
    setError(null);
  }, []);

  const isRunningRef = useRef(false);

  const execute = useCallback(async () => {
    // `isParallel` can't depend on the `status` state hook because
    // React batches async calls fired within the same render cycle.
    if (!options.isParallel && isRunningRef.current === true) {
      return;
    }

    reset();
    isRunningRef.current = true;
    setStatus(AsyncStatus.Pending);

    try {
      const response = await asyncFunction();
      setValue(response);
      setStatus(AsyncStatus.Success);
    } catch (error) {
      setError(error);
      setStatus(AsyncStatus.Error);
    }
    isRunningRef.current = false;
  }, [asyncFunction, options.isParallel, reset]);

  useEffect(() => {
    const canExecute =
      options.isParallel ||
      (!options.isParallel && status === AsyncStatus.Idle);
    if (options.isImmediate && canExecute) {
      execute();
    }
  }, [execute, options.isImmediate, options.isParallel, status]);

  return {
    /** Callback for manually firing the async request */
    execute,
    /** Async status */
    status,
    /** Value of the successful request */
    value,
    /** Request error of unknown type. Use `isError()` or other type guards. */
    error,
    /** Reset the state to `idle` */
    reset,
  };
}
