import { registerDecorator, ValidationOptions } from 'class-validator';

export const VALIDATOR_MATCHES_RESOURCE_ID =
  /^([a-z]+:\/\/[^/]+\/|)[\da-z-]+\/[\da-z-]+(\/[\da-z-]+)?$/i;

export const VALIDATOR_MATCHES_RESOURCE_ID_WITH_REQUIRED_ID =
  // eslint-disable-next-line unicorn/better-regex
  /^([a-z]+:\/\/[^/]+\/|)[\da-z-]+\/[\da-z-]+\/[\da-z-]+?$/i;

export const VALIDATOR_MATCHES_CREDIT_CARD_LAST_FOUR = /^\d{4}$/;

type ValidatorFunction = (value: unknown) => boolean;

/**
 * Checks if the value is null or passes the validator function.
 * @param value - The value to check.
 * @param validatorFunction - The validator function to check the value against.
 */
export function isNullOr(
  value: unknown,
  validatorFunction: ValidatorFunction,
): boolean {
  return value === null || validatorFunction(value);
}

/**
 * Decorator for class-validator to check if the value is null or passes the
 * validator function. It's not the same as `@IsOptional` because it doesn't
 * allow undefined values.
 * @param validatorFunction - The validator function to check the value against.
 * @param validationOptions - Options for the validation.
 */
export function IsNullOr(
  validatorFunction: ValidatorFunction,
  validationOptions?: ValidationOptions,
) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isNullOr',
      target: object.constructor,
      propertyName,
      constraints: [validatorFunction],
      options: validationOptions,
      validator: {
        validate(value: unknown) {
          return isNullOr(value, validatorFunction);
        },
      },
    });
  };
}
