import { SVGProps, memo } from 'react';

const SvgRocket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.64 12.997a.505.505 0 0 0-.718 0 .515.515 0 0 0 0 .723c.198.2.52.2.718 0a.515.515 0 0 0 0-.723ZM16.516 6.484c.992-1 2.598-1 3.59 0 .993 1 .992 2.618 0 3.618-.99.998-2.6.997-3.59 0a2.576 2.576 0 0 1 0-3.618Zm.718 2.895c.594.598 1.561.598 2.155 0 .595-.6.595-1.572 0-2.171-.596-.6-1.56-.6-2.155 0a1.546 1.546 0 0 0 0 2.17Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.85.695a.504.504 0 0 0-.378-.15c-.115.004-2.849.115-6.098 1.272-2.604.927-4.774 2.253-6.451 3.943-.376.378-.742.78-1.091 1.197-1.658-.983-2.987-.675-3.826-.222-1.934 1.043-3.131 3.874-3.131 5.9a.512.512 0 0 0 .508.511.506.506 0 0 0 .359-.15c1.02-1.027 2.278-.963 2.689-.909l.177.18a18.09 18.09 0 0 0-.838 2.485c-.075.294-.05.604.061.889a4.81 4.81 0 0 0-1.371.974c-1.307 1.317-1.573 4.527-1.583 4.663a.514.514 0 0 0 .506.552l.04-.001c.135-.011 3.32-.279 4.628-1.596.41-.413.74-.889.966-1.381.299.118.606.133.883.061a17.767 17.767 0 0 0 2.466-.845l.177.18c.054.414.118 1.681-.901 2.709a.515.515 0 0 0-.11.557.508.508 0 0 0 .469.316c2.01 0 4.82-1.206 5.854-3.154.45-.847.755-2.185-.22-3.856.413-.352.812-.72 1.188-1.1 1.676-1.69 2.993-3.876 3.912-6.5 1.149-3.275 1.259-6.03 1.263-6.145a.514.514 0 0 0-.149-.38ZM6.01 11.534c.316-1.538 1.232-3.226 2.475-3.897.83-.448 1.739-.404 2.704.129a20.722 20.722 0 0 0-2.133 3.504.523.523 0 0 0-.269-.159c-.07-.016-1.413-.307-2.777.423Zm-.05 9.202c1.035-.152 2.673-.523 3.37-1.226a3.69 3.69 0 0 0 .855-1.31l-1.709-1.722a3.656 3.656 0 0 0-1.3.86c-.698.704-1.066 2.355-1.216 3.398Zm5.686-2.814a.474.474 0 0 1-.444-.145l-.994-1.002-1.312-1.322a.483.483 0 0 1-.144-.447c.162-.638.375-1.293.634-1.954l4.199 4.231c-.656.26-1.306.475-1.94.639Zm3.445 2.764c1.527-.317 3.202-1.24 3.867-2.493.444-.837.4-1.752-.128-2.725a20.513 20.513 0 0 1-3.477 2.15l.009.009c.027.027.11.108.145.249.178.712.07 1.887-.416 2.81Zm5.013-7.69c-.458.462-.95.907-1.465 1.325a19.484 19.484 0 0 1-4.048 2.528l-4.774-4.81a19.68 19.68 0 0 1 2.51-4.08c.414-.518.856-1.015 1.313-1.475 1.505-1.516 3.446-2.723 5.772-3.591l4.255 4.287c-.862 2.344-2.059 4.3-3.563 5.817Zm.378-10.472 3.55 3.577c.6-1.948.825-3.644.906-4.49-.84.082-2.522.308-4.456.913Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.205 21.68a.505.505 0 0 0-.718 0l-1.436 1.448a.514.514 0 0 0 0 .724c.198.2.52.2.718 0l1.436-1.448a.515.515 0 0 0 0-.724ZM5.026 14.444a.505.505 0 0 0-.718 0L2.871 15.89a.514.514 0 0 0 0 .723c.198.2.52.2.718 0l1.437-1.446a.515.515 0 0 0 0-.724ZM8.615 22.404a.505.505 0 0 0-.718 0l-3.44 3.466a.514.514 0 0 0 0 .724c.198.2.52.2.718 0l3.44-3.466a.515.515 0 0 0 0-.724ZM4.553 22.404a.505.505 0 0 0-.718 0L.395 25.87a.514.514 0 0 0 0 .724c.198.2.52.2.718 0l3.44-3.466a.515.515 0 0 0 0-.724ZM4.306 18.062a.505.505 0 0 0-.718 0l-3.44 3.466a.515.515 0 0 0 0 .724c.2.2.52.2.719 0l3.44-3.466a.515.515 0 0 0-.001-.724ZM15.796 10.826a.505.505 0 0 0-.718 0l-.718.723a.515.515 0 0 0 0 .724c.198.2.52.2.718 0l.718-.724a.515.515 0 0 0 0-.723Z"
      fill="#000"
    />
  </svg>
);

const Memo = memo(SvgRocket);
export default Memo;
