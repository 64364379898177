import { SVGProps, memo } from 'react';

const SvgAlignRightSimple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#alignRightSimple_svg__a)"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 5.25v13.5M17.25 8.25H3a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h14.25A.75.75 0 0 0 18 15V9a.75.75 0 0 0-.75-.75Z" />
    </g>
    <defs>
      <clipPath id="alignRightSimple_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgAlignRightSimple);
export default Memo;
