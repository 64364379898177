import { CSSProperties, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import TextBox from 'src/app/components/form/TextBox/TextBox';
import { Flex } from 'src/app/components/shared/Flex';
import { LogoForm } from 'src/app/components/workspace/WorkspaceSidebar/LogoForm';
import { WorkspaceUpdateDto } from 'src/services/database/Workspaces/dto/workspaceUpdate.dto';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import { FormAccordion } from './FormAccordion';

interface IEditWorkspaceSettingsProps {
  className?: string;
  style?: CSSProperties;
}

export function Fields({ className, style }: IEditWorkspaceSettingsProps) {
  const { register, setValue } = useFormContext<WorkspaceUpdateDto>();
  const defaultValues = useWorkspace();
  const handleChooseLogoChange = useCallback(
    (imageBase64: string) => {
      setValue('logo', imageBase64);
    },
    [setValue],
  );

  return defaultValues ? (
    <Flex
      direction="column"
      align="stretch"
      gap="20px"
      className={className}
      style={style}
    >
      <FormAccordion
        title="Edit workspace name"
        style={{ display: 'contents' }}
      >
        <TextBox
          label="Workspace name"
          isLabelRendered={false}
          // TODO duplicates workspace creation field validation
          {...register('name', {
            required: 'This field is required',
            minLength: {
              value: 2,
              message: 'Name must be at least 2 characters long',
            },
          })}
          placeholder="Example Ltd."
        />
      </FormAccordion>

      <FormAccordion
        title="Edit workspace logo"
        style={{
          display: 'contents',
        }}
      >
        <Flex direction="column">
          <LogoForm
            currentLogoUrl={defaultValues?.logo}
            onImageSelection={handleChooseLogoChange}
            workspaceName={defaultValues.name}
          />
        </Flex>
      </FormAccordion>
    </Flex>
  ) : null;
}
