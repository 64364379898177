import {
  IsArray,
  IsObject,
  IsOptional,
  IsString,
  MinLength,
  ValidateNested,
} from 'class-validator';
import { IsWorkspaceMemberRecords } from 'src/services/database/Workspaces/validators';
import { WorkspaceMemberDto } from 'src/services/database/Workspaces/dto/workspaceMember.dto';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import { WorkspaceUpdateInvitationSettingsDto } from 'src/services/database/Workspaces/dto/workspaceUpdateInvitationSettings.dto';

/**
 * Workspace Create DTO is used to create a new workspace and validate the input.
 */
export class WorkspaceCreateDto {
  @MinLength(2, {
    message: 'Name must be at least 2 characters long.',
  })
  name: string;

  @MinLength(1, {
    message: 'Slug cannot be empty or undefined.',
  })
  slug: string;

  @IsString()
  @IsOptional()
  logo?: string;

  @IsWorkspaceMemberRecords()
  members: Record<string, WorkspaceMemberDto>;

  @IsArray()
  @MinLength(1, { each: true })
  tags: FlowDefinitionTag[];

  @MinLength(1)
  createdBy: string;

  @IsObject()
  @ValidateNested()
  readonly invitationSettings: WorkspaceUpdateInvitationSettingsDto;

  constructor(dto: WorkspaceCreateDto) {
    this.name = dto.name;
    this.slug = dto.slug;
    this.logo = dto.logo;
    this.members = dto.members;
    this.tags = dto.tags;
    this.createdBy = dto.createdBy;
    this.invitationSettings = new WorkspaceUpdateInvitationSettingsDto(
      dto.invitationSettings,
    );
  }
}
