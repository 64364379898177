import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PasswordReminderForm } from '../../types';

/**
 * Implements password reminder form state handling,
 * and provides a callback that catches server-side errors.
 */
export default function usePasswordReminderForm({
  onPasswordReminder,
}: {
  onPasswordReminder: (values: PasswordReminderForm) => Promise<void>;
}) {
  const form = useForm<PasswordReminderForm>();
  const [rootErrorMessage, setRootErrorMessage] = useState('');
  const handlePasswordReminderWithError = useCallback(
    async (values: PasswordReminderForm) => {
      try {
        setRootErrorMessage('');
        await onPasswordReminder(values);
      } catch (error) {
        setRootErrorMessage('Something went wrong. Please try again.');
      }
    },
    [onPasswordReminder],
  );
  return useMemo(
    () => ({
      /**
       * React Hook Form instance
       * @see https://react-hook-form.com/
       */
      form,
      /**
       * Wrapped submit callback that catches server errors
       * and triggers a generic error message for the user.
       */
      handlePasswordReminderWithError,
      /**
       * User-facing top-level error message.
       */
      rootErrorMessage,
    }),
    [form, handlePasswordReminderWithError, rootErrorMessage],
  );
}
