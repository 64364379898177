import { useCallback, useState } from 'react';
import { ArrowFatLineRight, Upload } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import { WorkspaceLogo } from 'src/app/components/workspace/WorkspaceLogo';
import { unstyledButton } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { bodyTextNormalGray } from '../workspaceStyles.css';
import { SelectFile } from './SelectFile';

interface ILogoFormProps {
  /**
   * If available, the current workspace logo is displayed.
   */
  currentLogoUrl?: string;
  /**
   * Called when the user selects the cropped logo image.
   */
  onImageSelection?: (imageBase64: string) => void;
  /**
   * The workspace name's first letter is rendered as fallback
   * if the logo image is unavailable.
   */
  workspaceName: string;
}

export function LogoForm({
  currentLogoUrl,
  onImageSelection,
  workspaceName,
}: ILogoFormProps) {
  const [croppedImage, setCroppedImage] = useState('');

  const onImageCropped = useCallback(
    (imageData: string) => {
      setCroppedImage(imageData);
      if (typeof onImageSelection === 'function') {
        onImageSelection(imageData);
      }
    },
    [onImageSelection],
  );

  return (
    <>
      <Flex justify="center" align="center" gap="40px">
        <Flex direction="column" justify="center" align="center" gap="12px">
          <WorkspaceLogo
            imageUrl={currentLogoUrl}
            name={workspaceName}
            size="large"
          />
          <p className={bodyTextNormalGray}>Current logo</p>
        </Flex>

        <Flex
          direction="column"
          justify="center"
          align="center"
          alignSelf={'flex-start'}
          gap="12px"
          style={{
            padding: '16px',
          }}
        >
          <ArrowFatLineRight
            fill={vars.color.brandGray88}
            style={{
              width: '32px',
              height: '32px',
              strokeWidth: '2px',
              color: vars.color.brandGray88,
            }}
          />
        </Flex>

        <button type="button" className={unstyledButton}>
          <Flex direction="column" justify="center" align="center" gap="12px">
            <Flex
              justify="center"
              align="center"
              style={{
                width: 72,
                height: 72,
                borderRadius: vars.borderRadius.large,
                border: `1.6px dashed ${vars.color.brandGray88}`,
                overflow: 'hidden',
              }}
            >
              {croppedImage ? (
                <img
                  src={croppedImage}
                  alt="Cropped Preview"
                  style={{ width: '40px', height: '40px' }}
                />
              ) : (
                <Upload
                  style={{
                    width: '24px',
                    height: '24px',
                    color: vars.color.brandGray88,
                  }}
                />
              )}
            </Flex>
            <p className={bodyTextNormalGray}>Custom logo</p>
          </Flex>
        </button>
      </Flex>
      <SelectFile onImageCropped={onImageCropped} />
    </>
  );
}
