import { SVGProps, memo } from 'react';

const SvgPeak = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.043 25.89 16.932 10.2V5.644h6.12a.51.51 0 0 0 .51-.51v-4.08a.51.51 0 0 0-.51-.51h-6.63a.51.51 0 0 0-.51.51v4.528l-1.093 1.524a.51.51 0 0 0 .829.595l.264-.369v2.858l-4.029 6.452-3.412-2.63a.51.51 0 0 0-.738.125l-7.65 11.73a.51.51 0 0 0 .427.789h25.092a.51.51 0 0 0 .441-.766ZM16.932 1.566h5.61v3.06h-5.61v-3.06ZM8.286 15.158 1.452 25.637h23.264l-8.292-14.28h-.002l-.035-.002-3.947 6.32-1.806 3.165a.51.51 0 1 1-.886-.505l1.605-2.812-3.067-2.365Z"
      fill="#000"
    />
    <path
      d="M9.43 21.916a.51.51 0 1 0-.506.887.51.51 0 0 0 .506-.887ZM10.603 13.633a.51.51 0 0 0 .81-.025l.67-.933a.51.51 0 0 0-.83-.594l-.28.39-.786-.964a.51.51 0 1 0-.791.644l1.207 1.482ZM12.557 11.135a.507.507 0 0 0 .711-.118l1.189-1.657a.51.51 0 1 0-.829-.595l-1.189 1.658a.51.51 0 0 0 .118.712ZM7.063 11.509a.508.508 0 0 0 .706-.149l.45-.69a.51.51 0 0 0 .68-.745l-.343-.42a.51.51 0 0 0-.823.043l-.818 1.255a.51.51 0 0 0 .148.706ZM4.833 14.926a.508.508 0 0 0 .706-.148l1.114-1.71a.51.51 0 1 0-.854-.556L4.684 14.22a.51.51 0 0 0 .149.706ZM.231 21.984a.508.508 0 0 0 .706-.149l.703-1.077a.51.51 0 1 0-.855-.557l-.702 1.077a.51.51 0 0 0 .148.706ZM2.048 19.198a.508.508 0 0 0 .706-.149l1.114-1.708a.51.51 0 1 0-.854-.558l-1.115 1.71a.51.51 0 0 0 .149.705Z"
      fill="#000"
    />
  </svg>
);

const Memo = memo(SvgPeak);
export default Memo;
