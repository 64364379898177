import { SVGProps, memo } from 'react';

const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#home_svg__a)">
      <path
        d="M14.336 20.395v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-8.668a.75.75 0 0 1 .246-.555l7.5-7.082a.75.75 0 0 1 1.01 0l7.5 7.082a.748.748 0 0 1 .245.555v8.668a.75.75 0 0 1-.75.75h-4.501a.75.75 0 0 1-.75-.75Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="home_svg__a">
        <path fill="#fff" transform="translate(.086 .895)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgHome);
export default Memo;
