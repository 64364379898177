import { SVGProps, memo } from 'react';

const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#calendar_svg__a)" fill="currentColor">
      <path d="M23.831 2.072h-1.228V.307h-2.046v1.765H7.049V.307H5.003v1.765H3.775C2.082 2.072.705 3.26.705 4.72v15.537c0 1.46 1.377 2.648 3.07 2.648H23.83c1.693 0 3.07-1.188 3.07-2.648V4.72c0-1.46-1.377-2.648-3.07-2.648Zm1.024 18.185c0 .486-.46.882-1.024.882H3.775c-.564 0-1.023-.396-1.023-.882V8.604h22.102v11.653Zm0-13.418H2.752V4.72c0-.486.459-.882 1.023-.882h1.228v1.765h2.046V3.838h13.508v1.765h2.046V3.838h1.228c.564 0 1.024.396 1.024.882v2.12Z" />
      <path d="M6.64 10.458H4.594v1.766H6.64v-1.766Zm4.093 0H8.687v1.766h2.046v-1.766Zm4.093 0h-2.047v1.766h2.047v-1.766Zm4.094 0h-2.047v1.766h2.047v-1.766Zm4.092 0h-2.046v1.766h2.046v-1.766ZM6.64 13.99H4.594v1.765H6.64v-1.766Zm4.093 0H8.687v1.765h2.046v-1.766Zm4.093 0h-2.047v1.765h2.047v-1.766Zm4.094 0h-2.047v1.765h2.047v-1.766ZM6.64 17.52H4.594v1.766H6.64V17.52Zm4.093 0H8.687v1.766h2.046V17.52Zm4.093 0h-2.047v1.766h2.047V17.52Zm4.094 0h-2.047v1.766h2.047V17.52Zm4.092-3.53h-2.046v1.765h2.046v-1.766Z" />
    </g>
    <defs>
      <clipPath id="calendar_svg__a">
        <path fill="#fff" d="M.705.307h26.196v22.598H.705z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgCalendar);
export default Memo;
