import { SVGProps, memo } from 'react';

const SvgExternalLinkOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.829 2.347h5.985v5.986M10.84 13.321 21.816 2.347M11.342 4.01h-5.82c-.442 0-.865.221-1.177.616-.311.395-.487.931-.487 1.49V20.86c0 .56.176 1.095.487 1.49.312.395.735.617 1.176.617h11.641c.441 0 .864-.222 1.176-.617.312-.395.487-.93.487-1.49v-7.372"
      stroke="currentColor"
      strokeWidth={1.996}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgExternalLinkOut);
export default Memo;
