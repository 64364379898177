import { SVGProps, memo } from 'react';

const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.934 4.195v31.709M4.08 20.05h31.708"
      stroke="currentColor"
      strokeWidth={7.927}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgPlus);
export default Memo;
