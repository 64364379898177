import {
  autoUpdate,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { CSSProperties, useMemo, useState } from 'react';
import {
  IWorkspaceFloatingHookArgs,
  IWorkspaceFloatingHookReturn,
} from '../types';
import { IRequiredContainerProps } from './types';

/**
 * Configures Floating UI to manage an instance of `<FullPageContainer/>`.
 * @returns Props generated by Floating UI to manage the dialog.
 * These props should be spread over both the anchor and the popover element.
 * @see https://floating-ui.com/
 */
export default function useFullPageContainer({
  isOpen,
  setIsOpen,
}: IWorkspaceFloatingHookArgs): IWorkspaceFloatingHookReturn<IRequiredContainerProps> {
  const [leftEdge, setLeftEdge] = useState(0);
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      size({
        apply: ({ availableWidth, elements, x }) => {
          // Side effect storing the floating element's X coordinate.
          // This is cleaner than parsing the string of a CSS transform rule to extract the same coordinate.
          setLeftEdge(x);
          // Augments the calculated floating styles with custom styles.
          Object.assign(elements.floating.style, {
            // Contains the floating element within the viewport.
            maxWidth: `${availableWidth}px`,
            maxHeight: '100vh',
          });
        },
      }),
    ],
    placement: 'right',
    // Fixed positioning allows us to use `vh` unit for element height.
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  const transformedStyles = useMemo<CSSProperties>(
    () => ({
      ...floatingStyles,
      // The transform rule must be defined manually to avoid setting the Y translation.
      // Because the element should vertically cover the whole page,
      // non-zero Y coordinates cannot be used.
      transform: `translate(${leftEdge}px, 0px)`,
    }),
    [floatingStyles, leftEdge],
  );

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return useMemo<IWorkspaceFloatingHookReturn<IRequiredContainerProps>>(
    () => ({
      getAnchorProps: () => ({
        ref: refs.setReference,
        ...getReferenceProps(),
      }),
      getFloatingProps: () => ({
        ref: refs.setFloating,
        floatingContext: context,
        floatingProps: getFloatingProps(),
        onClose: () => setIsOpen(false),
        style: transformedStyles,
      }),
    }),
    [
      context,
      transformedStyles,
      getFloatingProps,
      getReferenceProps,
      refs.setFloating,
      refs.setReference,
      setIsOpen,
    ],
  );
}
