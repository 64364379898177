import { ReactNode } from 'react';
import { InputError } from '../../form/InputError/InputError';
import { useLoginFormInstance } from './context';

interface IErrorsProps {
  /**
   * If necessary, e.g. an empty paragraph can be used
   * to prevent layout shift when the error message appears.
   */
  emptyContent?: ReactNode | null;
}

export function Errors({ emptyContent = null }: IErrorsProps) {
  const { rootErrorMessage } = useLoginFormInstance();
  return (
    <>
      {rootErrorMessage.length > 0 ? (
        <InputError>{rootErrorMessage}</InputError>
      ) : (
        emptyContent
      )}
    </>
  );
}
