import {
  FirebaseStorage,
  getDownloadURL,
  ref,
  uploadString,
} from '@firebase/storage';
import { traceError } from '../telemetry/trace';

/**
 * Saves an image to Firebase Cloud Storage.
 *
 * @param storage - Firebase Storage instance
 * @param {string} data - Base64 encoded string of the image.
 * @param {string} path - The path in storage where the image should be saved.
 * @returns {Promise<void>} A promise that resolves once the image has been saved.
 */
export async function saveImage(
  storage: FirebaseStorage,
  data: string,
  path: string,
): Promise<string> {
  const storageRef = ref(storage, path);
  try {
    await uploadString(storageRef, data, 'data_url');
    return await getDownloadURL(storageRef);
  } catch (error) {
    traceError(error, {
      category: 'firebase',
      level: 'fatal',
      message: String(error),
    });
    throw error;
  }
}
