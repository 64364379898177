import { SVGProps, memo } from 'react';

const SvgShrinkSimple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.813 21.308H26.944V9.439M40.787 7.46 26.941 21.306m-18.185 6.32h11.868v11.868M6.781 41.472l13.847-13.846"
      stroke="currentColor"
      strokeWidth={4.028}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgShrinkSimple);
export default Memo;
