import { SVGProps, memo } from 'react';

const SvgLogoGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.76 12.29a15 15 0 0 0-.21-2.43H12.23v4.627h6.484a5.455 5.455 0 0 1-2.405 3.634l3.87 3.006c2.274-2.117 3.58-5.202 3.58-8.836Z"
      fill="#4280EF"
    />
    <path
      d="M12.23 24.001c3.242 0 5.961-1.072 7.948-2.902l-3.869-2.98c-1.072.732-2.458 1.15-4.078 1.15-3.138 0-5.778-2.117-6.745-4.94l-3.974 3.058A11.988 11.988 0 0 0 12.23 24Z"
      fill="#34A353"
    />
    <path
      d="M5.487 14.301a7.29 7.29 0 0 1 0-4.6L1.513 6.614a12.016 12.016 0 0 0 0 10.771l3.974-3.085Z"
      fill="#F6B704"
    />
    <path
      d="M12.23 4.759a6.547 6.547 0 0 1 4.602 1.803l3.425-3.45C18.087 1.072 15.21-.026 12.23 0 7.68 0 3.55 2.563 1.51 6.615L5.487 9.7c.967-2.85 3.607-4.941 6.745-4.941Z"
      fill="#E54335"
    />
  </svg>
);

const Memo = memo(SvgLogoGoogle);
export default Memo;
