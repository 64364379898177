import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { PathParams } from 'src/app/router/routeValidation';
import { buildPath, Path } from 'src/app/router/routes';
import { useTypedAutofillParams } from 'src/app/router/hooks';

type TypedLinkProps<P extends Path> = LinkProps & {
  /** Valid path within the app. See `src/app/routes.ts` for valid paths. */
  to: P;
  /** A map of path param values. Allowed only if the path in the `to` prop includes params. */
  params?: PathParams;
  /** Whether to automatically fill in workspace and flow slugs if they are not provided. */
  autoFill?: boolean;
  /** A URLSearchParams object to append to the URL. */
  search?: URLSearchParams;
};

/**
 * Type-safe implementation of React Router's `<Link/>`,
 * which makes entering incorrect path params impossible.
 *
 * All props of the original `<Link/>` component are allowed.
 * @see `src/app/routes.ts` for valid paths.
 */
export function TypedLink<P extends Path>({
  to,
  children,
  params,
  title,
  autoFill = true,
  search,
  ...allProps
}: PropsWithChildren<TypedLinkProps<P>>) {
  const autofilledParams = useTypedAutofillParams(params, autoFill);
  const url = buildPath(to, autofilledParams, search);
  return (
    <Link {...allProps} to={url} title={title}>
      {children}
    </Link>
  );
}
