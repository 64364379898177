import { IsBoolean, IsString } from 'class-validator';

export class WorkspaceUpdateInvitationSettingsDto {
  @IsBoolean()
  allowGuests: boolean;

  @IsString({ each: true })
  allowEmailDomains: string[];

  @IsBoolean()
  requireInvitationId: boolean;

  constructor(dto: WorkspaceUpdateInvitationSettingsDto) {
    this.allowGuests = dto.allowGuests ?? false;
    this.allowEmailDomains = dto.allowEmailDomains ?? [];
    this.requireInvitationId = dto.requireInvitationId ?? false;
  }
}
