import { SVGProps, memo } from 'react';

const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#arrowLeft_svg__a)"
      stroke="currentcolor"
      strokeWidth={2.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M20.25 12H3.75m0 0 6.75-6.75M3.75 12l6.75 6.75"
        strokeOpacity={0.8}
      />
      <path
        d="M20.25 12H3.75m0 0 6.75-6.75M3.75 12l6.75 6.75"
        strokeOpacity={0.45}
      />
    </g>
    <defs>
      <clipPath id="arrowLeft_svg__a">
        <path fill="#fff" transform="matrix(-1 0 0 1 24 0)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgArrowLeft);
export default Memo;
