import { SVGProps, memo } from 'react';

const SvgPlayCircled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.947 30.122A20.359 20.359 0 1 1 3.54 16.597a20.359 20.359 0 0 1 38.406 13.525h0Z"
      stroke="url(#play-circled_svg__a)"
      strokeWidth={4.109}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m31.749 22.56-12.246-7.653a.934.934 0 0 0-1.429.792v15.307a.934.934 0 0 0 1.429.792l12.246-7.654a.934.934 0 0 0 0-1.584Z"
      fill="url(#play-circled_svg__b)"
    />
    <defs>
      <linearGradient
        id="play-circled_svg__a"
        x1={47.58}
        y1={47.855}
        x2={13.485}
        y2={-21.022}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.003} stopColor="#3451B2" />
        <stop offset={1} stopColor="#BD61F2" />
      </linearGradient>
      <linearGradient
        id="play-circled_svg__b"
        x1={33.824}
        y1={33.559}
        x2={17.836}
        y2={7.72}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3451B2" />
        <stop offset={1} stopColor="#BB45FF" />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(SvgPlayCircled);
export default Memo;
