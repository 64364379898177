import { SVGProps, memo } from 'react';

const SvgHedgehog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.938 25.625a1.281 1.281 0 1 1-2.563 0 1.281 1.281 0 0 1 2.563 0ZM25.625 25.625a1.281 1.281 0 1 1-2.562 0 1.281 1.281 0 0 1 2.562 0ZM18.734 28.828a1.437 1.437 0 0 0-.995 2.474l.29.278a3.554 3.554 0 0 0 1.83.937v1.436a.64.64 0 1 0 1.282 0v-1.436a3.554 3.554 0 0 0 1.83-.937l.29-.278a1.438 1.438 0 0 0-.995-2.474h-3.532Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.993 2.797a.634.634 0 0 0-.963-.025L17.198 5.91l-2.25-1.337a.633.633 0 0 0-.86.208l-1.42 2.246H8.415c-.326 0-.6.248-.633.575l-.399 3.917-3.117 1.567a.642.642 0 0 0-.345.672l.565 3.66-1.699 2.743a.644.644 0 0 0 .03.723l.002.002.01.013a5.771 5.771 0 0 1 .181.265c.12.183.278.44.432.733.326.622.562 1.28.523 1.753-.006.078-.05.246-.16.529-.103.267-.24.576-.39.917l-.01.023c-.24.543-.518 1.168-.683 1.718-.083.28-.15.576-.159.856-.008.272.036.626.282.913.11.128.265.207.433.22l3.299.256-.986 2.73a.644.644 0 0 0 .09.604c.129.173.336.269.55.255l3.877-.26c.996 3.498 4.193 6.026 7.948 6.026h4.889c3.677 0 6.82-2.424 7.884-5.812l3.16.98c.202.062.421.02.586-.114a.642.642 0 0 0 .235-.552l-.272-3.285 3.272-.72a.635.635 0 0 0 .345-.207c.26-.302.293-.683.28-.96a3.954 3.954 0 0 0-.175-.918c-.179-.602-.472-1.287-.719-1.864l-.009-.02a24.135 24.135 0 0 1-.361-.873 4.154 4.154 0 0 1-.105-.305 1.264 1.264 0 0 1-.024-.095l-.005-.031c-.032-.482.209-1.145.532-1.766a9.166 9.166 0 0 1 .569-.946l.02-.029.018-.025.009-.013.001-.002a.645.645 0 0 0 .028-.72l-1.7-2.742.566-3.66a.642.642 0 0 0-.345-.672l-3.118-1.567-.398-3.897a.638.638 0 0 0-.63-.575l-4.255-.019-1.42-2.247a.633.633 0 0 0-.834-.223l-2.554 1.358-2.533-3.119Zm-6.628 18.641a9.535 9.535 0 0 1 6.135-2.22c2.342 0 4.477.837 6.135 2.22l.713.596.303-.879a1.923 1.923 0 1 1 1.992 2.54l-.926.083.407.836a9.533 9.533 0 0 1 .943 4.793l-.072 1.145a7.047 7.047 0 0 1-7.033 6.604h-4.924c-3.72 0-6.8-2.891-7.033-6.604l-.072-1.145a9.533 9.533 0 0 1 .943-4.793l.407-.836-.926-.083a1.922 1.922 0 1 1 1.992-2.54l.303.879.713-.596Z"
      fill="#000"
    />
  </svg>
);

const Memo = memo(SvgHedgehog);
export default Memo;
