const hardcodedSystemAdminEmails = new Set([
  'pris@superflow.team',
  'mark@superflow.team',
  'matyi@superflow.team',
  'krisztian@superflow.team',
]);

export function isSystemAdmin(user?: { email?: string }) {
  return user && user.email && hardcodedSystemAdminEmails.has(user.email);
}
