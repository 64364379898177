import clsx from 'clsx';
import { CSSProperties } from 'react';
import { linkText } from 'src/styles/global.css';
import { bodyTextSmall, centerText } from '../auth.css';

interface ILegalLinksProps {
  className?: string;
  style?: CSSProperties;
}

export function LegalLinks({ className, style }: ILegalLinksProps) {
  return (
    <p className={clsx(centerText, className)} style={style}>
      <small className={bodyTextSmall}>
        By signing up you accept our{' '}
        <a
          href="https://www.superflow.team/terms-of-service"
          target="_blank"
          rel="noreferrer"
          className={linkText}
        >
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a
          href="https://www.superflow.team/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className={linkText}
        >
          Privacy Policy
        </a>
        .
      </small>
    </p>
  );
}
