import { SVGProps, memo } from 'react';

const SvgShrinkDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.838 16.058h-10.79V5.268m12.59-1.794L28.05 16.062M5.746 27.563h10.79v10.79M3.95 40.15l12.588-12.587M16.533 5.26v10.789H5.744M3.947 3.46l12.587 12.587M28.04 38.355v-10.79h10.79m1.797 12.582L28.04 27.56"
      stroke="currentColor"
      strokeWidth={3.923}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgShrinkDouble);
export default Memo;
