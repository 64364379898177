import { SVGProps, memo } from 'react';

const SvgBarChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.324 0c2.273 0 4.116 1.982 4.116 4.428v20.664c0 2.446-1.843 4.428-4.116 4.428H4.116C1.843 29.52 0 27.538 0 25.092V4.428C0 1.982 1.843 0 4.116 0h19.208Zm0 2.952H4.116c-.758 0-1.372.66-1.372 1.476v20.664c0 .815.614 1.476 1.372 1.476h19.208c.758 0 1.372-.66 1.372-1.476V4.428c0-.815-.614-1.476-1.372-1.476ZM8.232 17.712c.758 0 1.372.66 1.372 1.476v2.952c0 .815-.614 1.476-1.372 1.476-.758 0-1.372-.66-1.372-1.476v-2.952c0-.815.614-1.476 1.372-1.476ZM13.72 5.904c.758 0 1.372.66 1.372 1.476v14.76c0 .815-.614 1.476-1.372 1.476-.758 0-1.372-.66-1.372-1.476V7.38c0-.815.614-1.476 1.372-1.476Zm5.488 5.904c.758 0 1.372.66 1.372 1.476v8.856c0 .815-.614 1.476-1.372 1.476-.758 0-1.372-.66-1.372-1.476v-8.856c0-.815.614-1.476 1.372-1.476Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgBarChart);
export default Memo;
