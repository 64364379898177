import { useCallback, useEffect, useState } from 'react';
import { Subscription } from 'src/utils/subscription';

/**
 * Type definition for the Zendesk chat function.
 * Currently, we use Zendesk for support chat.
 */
declare type ZendeskFn = (
  command: string,
  action: string,
  callback?: (cb?: (jwt: string) => void) => void,
) => void;
declare const zE: ZendeskFn;

/**
 * Zendesk doesn't have a very good API, so we have to use a hacky way to check
 * if the chat is open or closed. We have to listen to the events and update
 * the state accordingly, and we have to do it outside React because we can't
 * unsubscribe from the events, also because we can't directly check if the
 * chat is open or closed, only through the events, but they only fire when the
 * state changes. ¯\_(ツ)_/¯
 */
const zendeskIsOpenListener = new Subscription<boolean>(false);
if (zE) {
  // Listening to the events to update the state when opened.
  zE('messenger:on', 'open', () => {
    zendeskIsOpenListener.emit(true);
  });

  // Listening to the events to update the state when closed.
  zE('messenger:on', 'close', () => {
    zendeskIsOpenListener.emit(false);
  });
}

/**
 * Check if the support chat framework is available.
 * @returns Whether the support chat is available.
 */
export function useIsSupportChatAvailable(): boolean {
  return !!zE;
}

/**
 * Check if the support chat window is open.
 * @returns Whether the support chat window is open.
 */
export function useIsSupportChatOpen(): boolean {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    return zendeskIsOpenListener.on((val) => setIsOpen(val ?? false));
  }, []);

  return isOpen;
}

/**
 * Open the support chat window. This function will do nothing if support chat
 * is not available.
 * @returns Function to open the chat window.
 */
export function useSupportChatOpen() {
  const isAvailable = useIsSupportChatAvailable();
  return useCallback(() => {
    if (!isAvailable) {
      return;
    }

    zE('messenger', 'open');
    // Manually setting emitting the event too, because Zendesk doesn't fire the
    // event when the chat is already open.
    zendeskIsOpenListener.emit(true);
  }, [isAvailable]);
}

/**
 * Close the support chat window. This function will do nothing if support chat
 * is not available.
 * @returns Function to close the chat window.
 */
export function useSupportChatClose() {
  const isZendeskAvailable = useIsSupportChatAvailable();
  return useCallback(() => {
    if (!isZendeskAvailable) {
      return;
    }

    zE('messenger', 'close');
    // Manually setting emitting the event too, because Zendesk doesn't fire the
    // event when the chat is already closed.
    zendeskIsOpenListener.emit(false);
  }, [isZendeskAvailable]);
}
