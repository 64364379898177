import 'cropperjs/dist/cropper.css';
import { useCallback, useRef, useState } from 'react';
import { default as Cropper, ReactCropperElement } from 'react-cropper';
import { useDropzone } from 'react-dropzone';
import { UploadCloud } from 'src/app/components/icons';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { Flex } from 'src/app/components/shared/Flex';
import { vars } from 'src/styles/vars.css';
import { bodyTextNormalLight, bodyTextSmallGray } from '../workspaceStyles.css';

interface ISelectProps {
  /**
   * Called whenever the cropped selection updates.
   *
   * @see https://github.com/fengyuanchen/cropperjs?tab=readme-ov-file#crop-1
   */
  onImageCropped: (logo: string) => void;
}

export function SelectFile({ onImageCropped }: ISelectProps) {
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
  const [isShowingCropper, setIsShowingCropper] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);

  const handleCropDone = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const croppedData = cropper?.getCroppedCanvas()?.toDataURL();
    if (!croppedData) return;
    onImageCropped(croppedData);
  };

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setImageSrc(reader.result);
      setIsShowingCropper(true);
    });

    if (!file) return;
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Flex
      direction="column"
      align="center"
      gap="12px"
      style={{
        width: '345px',
        padding: '15px',
        borderRadius: vars.borderRadius.large,
        border: `1.6px dashed ${vars.color.brandGray88}`,
      }}
      {...(isShowingCropper ? {} : getRootProps())}
    >
      {isShowingCropper && imageSrc ? (
        <div style={{ width: '100%', marginTop: '20px' }}>
          <Cropper
            ref={cropperRef}
            src={imageSrc as string}
            aspectRatio={1}
            style={{ height: 200, width: '100%' }}
            crop={handleCropDone}
            viewMode={1}
          />
        </div>
      ) : (
        <>
          <input {...getInputProps()} />
          <UploadCloud
            style={{ width: '65px', height: '56px', color: vars.color.light95 }}
          />
          <Flex direction="column" align="center" gap="10px">
            <p className={bodyTextNormalLight}>Drag & drop an image</p>
            <p className={bodyTextNormalLight}>or</p>
            <ButtonPill type="button" intent="primary">
              Select a file
            </ButtonPill>
          </Flex>

          <p className={bodyTextSmallGray}>
            File formats: JPEG, PNG, GIF<br></br>File size: 55kb - 5mb
          </p>

          {isDragActive && (
            <p className={bodyTextSmallGray}>Drop the files here ...</p>
          )}
        </>
      )}
    </Flex>
  );
}
