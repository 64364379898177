import { SubmitHandler, useForm } from 'react-hook-form';
import TextBox from 'src/app/components/form/TextBox/TextBox';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { Flex } from 'src/app/components/shared/Flex';
import { PASSWORD_MIN_LENGTH, updateUserPassword } from 'src/services/auth';
import { RootFormError } from '../../types';

interface IPasswordFormProps {
  onSuccess: () => void;
}

interface IFormInput {
  password: string;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export function PasswordForm({ onSuccess }: IPasswordFormProps) {
  const { formState, handleSubmit, register, setError } = useForm<
    IFormInput & RootFormError
  >();
  const { errors, isSubmitting } = formState;
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      await updateUserPassword(data.password, data.password);
      onSuccess();
    } catch (error) {
      // error is logged/traced in AuthService.updateUserPassword
      setError('root.serverError', {
        message: 'Failed to change password',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap="14px">
        <TextBox
          label="Password"
          type="password"
          placeholder="Type your new password"
          error={errors.password?.message || errors.root?.serverError?.message}
          {...register('password', {
            minLength: {
              value: PASSWORD_MIN_LENGTH,
              message: `The password must be at least ${PASSWORD_MIN_LENGTH} characters.`,
            },
            required: true,
          })}
        />
        <Flex>
          <ButtonPill type="submit" intent="primary" disabled={isSubmitting}>
            Save
          </ButtonPill>
        </Flex>
      </Flex>
    </form>
  );
}
