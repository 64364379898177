import { SVGProps, memo } from 'react';

const SvgChatCircleDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.968 18.864a.75.75 0 0 0-.643-.364H3.75a.75.75 0 0 1-.75-.75v-12A.75.75 0 0 1 3.75 5h16.5a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-.75.75h-5.575a.75.75 0 0 0-.643.364l-1.389 2.272a.75.75 0 0 1-1.286 0l-1.389-2.272Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.378s-4.12-2.22-4.12-4.835a2.141 2.141 0 0 1 2.14-2.14c.894 0 1.66.487 1.98 1.268.32-.78 1.086-1.269 1.98-1.269a2.138 2.138 0 0 1 2.14 2.14c0 2.617-4.12 4.836-4.12 4.836Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgChatCircleDots);
export default Memo;
