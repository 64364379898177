import { SVGProps, memo } from 'react';

const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="currentcolor" strokeWidth={1.2}>
      <path
        d="M12.525 16.875a.525.525 0 1 1-1.05 0 .525.525 0 0 1 1.05 0Z"
        fill="#000"
      />
      <path
        d="M12 13.5v-.75c1.657 0 3-1.176 3-2.625 0-1.45-1.343-2.625-3-2.625s-3 1.176-3 2.625v.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgQuestion);
export default Memo;
