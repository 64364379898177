import { fillParent, screenReaderOnly } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { Flex } from '../../shared/Flex';
import { Spinner } from '../../shared/Spinner';

export function Placeholder() {
  return (
    <Flex className={fillParent} style={{ height: 150, maxHeight: '100vh' }}>
      <h1 className={screenReaderOnly}>Loading...</h1>
      <Spinner type="disk" size="3em" color={vars.color.brandPurple} />
    </Flex>
  );
}
