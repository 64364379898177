import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { AuthErrorCodeMap } from 'src/services/auth';
import { InputError } from '../../form/InputError/InputError';
import { centerText } from '../auth.css';
import { RootFormError, SignUpForm } from '../types';
import { RequestReminderPrompt } from './RequestReminderPrompt';

interface IErrorsProps {
  /**
   * If necessary, e.g. an empty paragraph can be used
   * to prevent layout shift when the error message appears.
   */
  emptyContent?: ReactNode;
}

export function Errors({ emptyContent }: IErrorsProps) {
  const {
    formState: { errors },
  } = useFormContext<SignUpForm & RootFormError>();

  return (
    <>
      {Object.keys(errors).map((key) => {
        const message = errors[key as keyof typeof errors]?.message;
        return message ? <InputError>{message}</InputError> : null;
      })}
      {errors.root?.serverError?.type === AuthErrorCodeMap.EmailTaken ? (
        <InputError className={centerText}>
          <RequestReminderPrompt />
        </InputError>
      ) : (
        emptyContent
      )}
    </>
  );
}
