import { useEffect } from 'react';
import TextBox from 'src/app/components/form/TextBox/TextBox';
import { LoginForm } from '../types';
import { useLoginFormInstance } from './context';

interface IInputFieldsProps {
  /**
   * Optional field name that gets auto-focused on first render.
   * @default 'email'
   */
  autoFocusField?: keyof LoginForm;
}

export function InputFields({ autoFocusField = 'email' }: IInputFieldsProps) {
  const instance = useLoginFormInstance();
  const { formState, register, setFocus } = instance.form;
  const { errors } = formState;

  useEffect(() => {
    if (autoFocusField) setFocus(autoFocusField);
  }, [autoFocusField, setFocus]);

  return (
    <>
      <TextBox
        label="Email"
        type="email"
        placeholder="john.doe@gmail.com"
        autoComplete="email"
        {...register('email', { required: true })}
        error={errors.email?.message}
      />

      <TextBox
        label="Password"
        type="password"
        autoComplete="current-password"
        {...register('password', { required: true })}
        error={errors.password?.message}
      />
    </>
  );
}
