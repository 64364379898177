import clsx from 'clsx';
import { unstyledButton } from 'src/styles/utils.css';
import { linkText } from 'src/styles/global.css';
import { ButtonPill } from '../../shared/ButtonPill';
import { Flex } from '../../shared/Flex';
import { GoogleAuth } from '../GoogleAuth';
import { useLoginFormInstance } from './context';

interface IButtonsProps {
  /**
   * Called when vendor authentication is resolved.
   */
  onVendorAuthSuccess?: () => void;
  /**
   * Called when a password reminder email should be triggered.
   */
  onRequestPassword: () => void;
}

export function Buttons({
  onVendorAuthSuccess,
  onRequestPassword,
}: IButtonsProps) {
  const instance = useLoginFormInstance();
  return (
    <>
      <Flex direction="column" gap="24px">
        <Flex direction="column" align="stretch" alignSelf="stretch">
          <ButtonPill
            intent="primary"
            type="submit"
            disabled={instance.form.formState.isSubmitting}
          >
            Log in
          </ButtonPill>
        </Flex>

        <GoogleAuth onSuccess={onVendorAuthSuccess} />
      </Flex>
      <button
        type="button"
        onClick={onRequestPassword}
        className={clsx(unstyledButton, linkText)}
      >
        Forgot your password?
      </button>
    </>
  );
}
