import clsx from 'clsx';
import { CSSProperties } from 'react';
import { useTypedSelector } from 'src/state/useTypedSelector';
import { userAvatarColor } from 'src/styles/colors/userAvatar.css';
import { roundedCropped } from 'src/styles/utils.css';

interface IAvatarGuestUserProps {
  style?: CSSProperties;
  className?: string;
}

/**
 * Renders the guest user's avatar image.
 *
 * A dynamic background color is set based on the color adjective in the user name.
 * The user name is queried from Redux by the component.
 * If the user name doesn't contain a valid color name, the background color falls back to white.
 */
export function AvatarGuestUser({ style, className }: IAvatarGuestUserProps) {
  type ColorKey = keyof typeof userAvatarColor;
  const user = useTypedSelector((state) => state.user.user);

  const userColor = (user?.displayName?.split(' ')[1] as ColorKey) ?? 'White';
  const background: CSSProperties['background'] = userAvatarColor[userColor];
  const IMAGE_TITLE = user.displayName ?? 'Guest User';

  return (
    <img
      src={user.avatar}
      alt={IMAGE_TITLE}
      title={IMAGE_TITLE}
      className={clsx(roundedCropped, className)}
      style={{ ...style, background }}
    />
  );
}
