import clsx from 'clsx';
import { CSSProperties, JSX, PropsWithChildren } from 'react';
import { errorMessage } from './InputError.css';

interface IInputErrorProps {
  className?: string;
  /**
   * Optional ID attribute that facilitates setting ARIA IDs.
   */
  id?: string;
  /**
   * Controls the `aria-live` prop value.
   * For urgent errors (e.g. after form submission), set `"blocking"`,
   * so the user is notified right away.
   * For non-intrusive errors (e.g. input-level blur validation), set `"lax"`.
   * @default "lax"
   */
  severity?: 'blocking' | 'lax';
  style?: CSSProperties;
  /**
   * Specifies the HTML tag used for rendreing the error message.
   * @default "p"
   */
  tag?: keyof JSX.IntrinsicElements;
}

/**
 * Renders a validation error message with `role` set to `"alert"`.
 * The tag name can be customized via a prop, and the color can be
 * customized via the {@link errorColor} CSS variable.
 */
export function InputError({
  children,
  className,
  id,
  severity = 'lax',
  style,
  tag: Tag = 'p',
}: PropsWithChildren<IInputErrorProps>) {
  return (
    <Tag
      id={id}
      aria-live={severity === 'blocking' ? 'assertive' : 'polite'}
      className={clsx(errorMessage, className)}
      style={style}
    >
      {children}
    </Tag>
  );
}
