import { SVGProps, memo } from 'react';

const SvgArrowFatLineRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 22V10M7 22V10"
      stroke="currentColor"
      strokeOpacity={0.88}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m19 4 12 12-12 12v-6h-8V10h8V4Z"
      fill="currentColor"
      fillOpacity={0.88}
      stroke="currentColor"
      strokeOpacity={0.88}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgArrowFatLineRight);
export default Memo;
