import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'src/state/useTypedSelector';
import { ButtonPill } from '../../shared/ButtonPill';
import { Flex } from '../../shared/Flex';
import {
  bodyTextSmall,
  brandPrimaryText,
  centerText,
  h4Text,
} from '../auth.css';
import useSignUpCallback from '../hooks/useSignUpCallback';
import usePasswordReminderCallback from '../modals/PasswordReminderModal/usePasswordReminderCallback';
import { EmailSignUpPage } from './EmailSignUpPage';
import { GuestUser } from './GuestUser';
import { LoginPage } from './LoginPage';
import { Form } from './PasswordReminderPopover/Form';
import { Success } from './PasswordReminderPopover/Success';
import { Placeholder } from './Placeholder';
import { ProfilePage } from './ProfilePage';

interface IContentProps {
  headingId: string;
  onClose: () => void;
}

export function Content({ headingId, onClose }: IContentProps) {
  const [pageId, setPageId] = useState<
    | 'guest'
    | 'login'
    | 'signupEmailForm'
    | 'signupSuccess'
    | 'profile'
    | 'passwordReminder'
    | 'passwordReminderSuccess'
    | undefined
  >('guest');
  const { isGuestUser, isLoggedIn } = useTypedSelector((state) => state.user);
  useEffect(() => {
    if (isGuestUser) setPageId('guest');
    else if (isLoggedIn) setPageId('profile');
  }, [isGuestUser, isLoggedIn]);

  const onEmailSignUp = useSignUpCallback({
    onSuccess: () => setPageId('signupSuccess'),
  });

  const onPasswordReminder = usePasswordReminderCallback({
    onSuccess: () => setPageId('passwordReminderSuccess'),
  });

  return (
    <div>
      {pageId === 'guest' && (
        <GuestUser
          headingId={headingId}
          onGoToEmailSignup={() => setPageId('signupEmailForm')}
          onGoToLogin={() => setPageId('login')}
          onGoToProfile={() => setPageId('profile')}
        />
      )}
      {pageId === 'login' && (
        <LoginPage
          headingId={headingId}
          onSuccess={() => setPageId('profile')}
          onGoToResetPassword={() => setPageId('passwordReminder')}
        />
      )}
      {pageId === 'signupEmailForm' && (
        <EmailSignUpPage
          headingId={headingId}
          onEmailSignUp={onEmailSignUp}
          onVendorAuth={() => setPageId('signupSuccess')}
        />
      )}
      {pageId === 'signupSuccess' && (
        <ProfilePage
          headingId={headingId}
          onClose={onClose}
          title={
            <h1
              className={clsx(h4Text, centerText)}
              style={{ marginBottom: '2em' }}
            >
              <span className={brandPrimaryText}>
                Hooray!
                <br />
                You&nbsp;ve signed up
              </span>{' '}
              🎊✨
            </h1>
          }
          footer={
            <Flex direction="column" gap="32px">
              <small className={bodyTextSmall}>
                You can close this window.
              </small>
              <Flex direction="column" gap="10px">
                <ButtonPill intent="primary" onClick={onClose}>
                  Start your Flow
                </ButtonPill>
              </Flex>
            </Flex>
          }
        />
      )}
      {pageId === 'profile' && (
        <ProfilePage headingId={headingId} onClose={onClose} />
      )}
      {pageId === 'passwordReminder' && (
        <Form
          headingId={headingId}
          onGoToLogin={() => setPageId('login')}
          onPasswordReminder={onPasswordReminder}
        />
      )}
      {pageId === 'passwordReminderSuccess' && (
        <Success headingId={headingId} onGoToLogin={() => setPageId('login')} />
      )}
      {!pageId && <Placeholder />}
    </div>
  );
}
