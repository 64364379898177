import { combineReducers } from '@reduxjs/toolkit';
import { reducer as isMarkedComplete } from './isMarkedComplete';
import { reducer as layout } from './layout';
import { reducer as sidebar } from './sidebar';

export const activeSession = combineReducers({
  isMarkedComplete,
  layout,
  sidebar,
});
