import { useMergeRefs } from '@floating-ui/react';
import {
  createElement,
  CSSProperties,
  forwardRef,
  PropsWithChildren,
} from 'react';
import { useTooltipState } from './context';

interface IAnchorProps {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: CSSProperties;
}

export const TooltipAnchor = forwardRef<
  HTMLElement,
  PropsWithChildren<IAnchorProps>
>(function TooltipAnchor({ as: tagName = 'div', children, ...props }, ref) {
  const state = useTooltipState();
  const mergedRef = useMergeRefs([state.refs.setReference, ref]);

  return createElement(
    tagName,
    { ...props, ref: mergedRef, 'aria-labelledby': state.id },
    children,
  );
});
