import clsx from 'clsx';
import { CSSProperties } from 'react';
import { useIsSupportChatAvailable } from '@features/support-chat/hooks';
import { SystemAdminSidebarButton } from 'src/app/components/workspace/WorkspaceSidebar/SystemAdminSidebarButton/SystemAdminSidebarButton';
import { useWorkspaceId } from 'src/utils/resource.hooks';
import { isSystemAdmin } from '@shared/utils/system-admin';
import { useUser } from 'src/utils/userContent/hooks';
import { Flex } from '../../shared/Flex';
import { AuthPopoverToggle } from '../AuthPopoverToggle';
import { SendFeedbackButton } from './SendFeedbackButton';
import { sidebarButtonSlot, sidebarWrapper } from './Sidebar.css';
import { SupportChatButton } from './SupportChatButton';
import { WorkspaceList } from './WorkspaceList';
import { Settings } from './menus/Settings';

interface ISidebarProps {
  className?: string;
  style?: CSSProperties;
}

export function WorkspaceSidebar({ className, style }: ISidebarProps) {
  const SIDEBAR_BUTTON_SLOT_SIZE = 52;
  const AVATAR_ICON_SIZE = 36;
  const POPOVER_MARGIN = 8;
  const FLOATING_OFFSET =
    (SIDEBAR_BUTTON_SLOT_SIZE - AVATAR_ICON_SIZE) / 2 + POPOVER_MARGIN;

  const user = useUser();
  const isUserSystemAdmin = isSystemAdmin(user);
  const isWorkspaceSettingsAvailable = !!useWorkspaceId();
  const isSupportChatAvailable = useIsSupportChatAvailable();

  return (
    <>
      <Flex
        as="nav"
        aria-label="Workspaces"
        direction="column"
        justify="flex-end"
        className={clsx(sidebarWrapper, className)}
        style={{ ...style, paddingTop: '18px' }}
      >
        <WorkspaceList />
        <Flex className={sidebarButtonSlot}>
          <AuthPopoverToggle
            placement="right-end"
            offset={{ mainAxis: FLOATING_OFFSET }}
          />
        </Flex>
        {isSupportChatAvailable && (
          <Flex className={sidebarButtonSlot}>
            <SupportChatButton />
          </Flex>
        )}
        <Flex className={sidebarButtonSlot}>
          <SendFeedbackButton />
        </Flex>
        {isWorkspaceSettingsAvailable ? (
          <Flex className={sidebarButtonSlot}>
            <Settings />
          </Flex>
        ) : null}
        {isUserSystemAdmin ? (
          <Flex className={sidebarButtonSlot}>
            <SystemAdminSidebarButton />
          </Flex>
        ) : null}
      </Flex>
    </>
  );
}
