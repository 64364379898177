import clsx from 'clsx';
import { ComponentProps } from 'react';
import { UUID } from 'src/@types/common';
import { Home } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import { Positioned } from 'src/app/components/shared/Positioned';
import { TypedLink } from 'src/app/router';
import { Path } from 'src/app/router/routes';
import { WorkspaceLogo } from '../../WorkspaceLogo';
import {
  homeBadge,
  homeIcon,
  workspaceLink,
  workspaceLinkText,
} from './WorkspaceLink.css';

interface IWorkspaceLinkProps extends ComponentProps<typeof WorkspaceLogo> {
  /** If `true`, a home icon is rendered in a badge. */
  showHomeIcon?: boolean;
  /** Used for linking to the workspace home page. */
  workspaceId: UUID;
}

/**
 * Wraps a {@link WorkspaceLogo} in {@link TypedLink}.
 */
export function WorkspaceLink({
  name,
  workspaceId,
  className,
  imageUrl,
  showHomeIcon,
  size,
  style,
}: IWorkspaceLinkProps) {
  return (
    <TypedLink
      to={Path.Workspace}
      params={{
        workspaceId,
      }}
      aria-label={
        showHomeIcon ? 'Go back to workspace home' : `Go to "${name}" workspace`
      }
      className={clsx(workspaceLink, className)}
      style={style}
    >
      <WorkspaceLogo name={name} imageUrl={imageUrl} size={size} />
      {showHomeIcon && (
        <Positioned right={-2} bottom={-4} unit="px" z={2}>
          <Flex className={homeBadge}>
            <Home className={homeIcon} />
          </Flex>
        </Positioned>
      )}
      <div className={clsx(workspaceLinkText)} aria-hidden>
        {name}
      </div>
    </TypedLink>
  );
}
