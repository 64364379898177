import { SVGProps, memo } from 'react';

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 16.5a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
      fill="currentColor"
      fillOpacity={0.85}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1 1.5a1 1 0 0 0-.986.831L6.657 5H3a1 1 0 0 0 0 2h1.562l.94 14.564a1 1 0 0 0 .998.936h11a1 1 0 0 0 .998-.936L19.438 7H21a1 1 0 1 0 0-2h-3.657l-.457-2.669A1 1 0 0 0 15.9 1.5H8.1ZM15.314 5H8.686l.257-1.5h6.114l.257 1.5ZM7.438 20.5 6.567 7h10.866l-.87 13.5H7.437Z"
      fill="currentColor"
      fillOpacity={0.85}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.566 7 .871 13.5h9.125L17.433 7H6.566ZM9.5 16.5a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
      fill="#currentColor"
      fillOpacity={0.4}
    />
  </svg>
);

const Memo = memo(SvgTrash);
export default Memo;
