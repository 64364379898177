import clsx from 'clsx';
import { Flex } from 'src/app/components/shared/Flex';
import { EmailLoginForm, useLoginCallback } from '../../EmailLoginForm';
import { brandPrimaryText, centerText, h4Text } from '../../auth.css';

interface ILoginFormProps {
  headingId: string;
  onSuccess: () => void;
  onGoToResetPassword: () => void;
}

export function LoginPage({
  headingId,
  onSuccess,
  onGoToResetPassword,
}: ILoginFormProps) {
  const onLogin = useLoginCallback({ onSuccess });

  return (
    <>
      <Flex as="header" direction="column" style={{ marginBottom: '24px' }}>
        <h1
          id={headingId}
          className={clsx(h4Text, brandPrimaryText, centerText)}
          style={{ margin: 0 }}
        >
          Log in to Superflow
        </h1>
      </Flex>
      <main>
        <EmailLoginForm.Root onLogin={onLogin}>
          <EmailLoginForm.DefaultLayout>
            <EmailLoginForm.InputFields />
            <EmailLoginForm.Buttons
              onVendorAuthSuccess={onSuccess}
              onRequestPassword={onGoToResetPassword}
            />
            <EmailLoginForm.Errors />
            <EmailLoginForm.LegalLinks />
          </EmailLoginForm.DefaultLayout>
        </EmailLoginForm.Root>
      </main>
    </>
  );
}
