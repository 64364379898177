import { SVGProps, memo } from 'react';

const SvgReply = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.229 13.667.896 7 9.229.333V4.5a8.333 8.333 0 0 1 8.306 9.008 7.5 7.5 0 0 0-6.64-4.008H9.23v4.167Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgReply);
export default Memo;
