import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ComponentProps } from 'react';
import { MdClose } from 'react-icons/md';
import { unstyledButton } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';

export function CloseButton({
  className,
  ...props
}: ComponentProps<typeof motion.button>) {
  return (
    <motion.button
      type="button"
      title="Close dialog"
      className={clsx(unstyledButton, className)}
      {...props}
      whileHover={{ scale: 1.2 }}
      whileFocus={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
    >
      <MdClose
        style={{
          fontSize: '1.5em',
          color: vars.color.brandBodyText,
          margin: '12x',
        }}
      />
    </motion.button>
  );
}
