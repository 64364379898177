import { ReactNode, useState } from 'react';
import { EditPencilSimpleLine } from 'src/app/components/icons';
import { ButtonWithIcon } from './ButtonWithIcon';

interface IToggleInputProps {
  children: (args: {
    /** Callback that reverts the component state, so the original button is rendered. */
    toggleOff: () => void;
  }) => ReactNode;
  label: string;
}

/**
 * Renders an edit button that can be toggled to be replaced with some form,
 * which can be passed in as the child component.
 *
 * The function as child pattern is applied in order to pass a callback to the children
 * so the button can be toggled back to its original state.
 */
export function ToggleInput({ children, label }: IToggleInputProps) {
  const [isInputActive, setIsInputActive] = useState(false);
  return (
    <div style={{ alignSelf: 'stretch' }}>
      {isInputActive ? (
        <>{children({ toggleOff: () => setIsInputActive(false) })}</>
      ) : (
        <ButtonWithIcon onClick={() => setIsInputActive(true)}>
          <EditPencilSimpleLine width="27px" height="24px" />
          {label}
        </ButtonWithIcon>
      )}
    </div>
  );
}
