import { SVGProps, memo } from 'react';

const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.995 2.824A3 3 0 0 0 17 0H3l-.176.005A3 3 0 0 0 0 3v12l.005.176A3 3 0 0 0 3 18h14l.176-.005A3 3 0 0 0 20 15V3l-.005-.176ZM3 2h14l.117.007A1 1 0 0 1 18 3v12l-.007.117A1 1 0 0 1 17 16H3l-.117-.007A1 1 0 0 1 2 15V3l.007-.117A1 1 0 0 1 3 2Zm12 7a1 1 0 0 1 .993.883L16 10v3a1 1 0 0 1-.883.993L15 14h-3a1 1 0 0 1-.117-1.993L12 12h2v-2a1 1 0 0 1 .883-.993L15 9ZM9 5a1 1 0 0 0-1-1H5l-.117.007A1 1 0 0 0 4 5v3l.007.117A1 1 0 0 0 5 9l.117-.007A1 1 0 0 0 6 8V6h2l.117-.007A1 1 0 0 0 9 5Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgExpand);
export default Memo;
