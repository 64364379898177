import { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SignOut } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import { logOut } from 'src/services/auth';
import { userLogout } from 'src/state/commonActions';
import { screenReaderOnly } from 'src/styles/utils.css';
import { ButtonWithIcon } from './ButtonWithIcon';
import { EditableAvatar } from './EditableAvatar';
import { NameForm } from './NameForm';
import { PasswordForm } from './PasswordForm';
import { ToggleInput } from './ToggleInput';

interface IProfilePageProps {
  /**
   * Optional footer that's rendered below the main content.
   */
  footer?: ReactNode;
  headingId: string;
  /**
   * Called when the user logs out and the popover should close itself.
   */
  onClose: () => void;
  /**
   * Optional, custom title component that's rendered in the page header.
   */
  title?: ReactNode;
}

export function ProfilePage({
  footer,
  headingId,
  onClose,
  title,
}: IProfilePageProps) {
  const dispatch = useDispatch();
  const handleLogout = useCallback(async () => {
    await logOut();
    dispatch(userLogout());
    onClose();
  }, [dispatch, onClose]);
  return (
    <>
      <header>
        {title ?? (
          <h1 id={headingId} className={screenReaderOnly}>
            Profile editor
          </h1>
        )}
      </header>
      <main>
        <EditableAvatar style={{ marginBottom: '28px' }} />
        <Flex direction="column" align="flex-start" gap="14px">
          <ToggleInput label="Edit name">
            {({ toggleOff }) => <NameForm onSuccess={toggleOff} />}
          </ToggleInput>
          <ToggleInput label="Change password">
            {({ toggleOff }) => <PasswordForm onSuccess={toggleOff} />}
          </ToggleInput>
          <div>
            <ButtonWithIcon onClick={handleLogout}>
              <SignOut width="27px" height="24px" />
              Sign out
            </ButtonWithIcon>
          </div>
        </Flex>
      </main>
      {footer && <footer style={{ marginTop: '28px' }}>{footer}</footer>}
    </>
  );
}
