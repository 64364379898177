import { createContext, PropsWithChildren, useContext } from 'react';
import useLoginForm from './useLoginForm';

type FormInstance = ReturnType<typeof useLoginForm>;

const LoginFormContext = createContext<FormInstance | null>(null);

export function LoginFormContextProvider({
  children,
  instance,
}: PropsWithChildren<{ instance: FormInstance }>) {
  return (
    <LoginFormContext.Provider value={instance}>
      {children}
    </LoginFormContext.Provider>
  );
}

/**
 * @returns The active login form instance returned by the {@link useLoginForm} hook.
 * The instance is initialized and propagated by `<EmailLoginForm.Root/>`.
 */
export function useLoginFormInstance() {
  const ctx = useContext(LoginFormContext);
  if (!ctx)
    throw new Error('Hook must be used within `<LoginFormContext.Provider/>');
  return ctx;
}
