import clsx from 'clsx';
import { Email } from 'src/app/components/icons';
import { AvatarGuestUser } from 'src/app/components/shared/AvatarGuestUser';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { Flex } from 'src/app/components/shared/Flex';
import { useTypedSelector } from 'src/state/useTypedSelector';
import { unstyledButton } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { linkText } from 'src/styles/global.css';
import {
  bodyTextNormal,
  bodyTextSmall,
  brandPrimaryText,
  centerText,
  h4Text,
} from '../../auth.css';
import { GoogleAuth } from '../../GoogleAuth';
import { userAvatar } from '../AuthPopover.css';

interface IGuestUserProps {
  headingId: string;
  onGoToLogin: () => void;
  onGoToEmailSignup: () => void;
  onGoToProfile: () => void;
}

export function GuestUser({
  headingId,
  onGoToEmailSignup,
  onGoToLogin,
  onGoToProfile,
}: IGuestUserProps) {
  const { user } = useTypedSelector((state) => state.user);
  return (
    <>
      <Flex as="header" direction="column">
        <h1
          id={headingId}
          className={clsx(h4Text, brandPrimaryText, centerText)}
          style={{ margin: 0 }}
        >
          Sign up to Superflow
        </h1>

        <AvatarGuestUser className={userAvatar} style={{ margin: '1em 0' }} />
        <h2
          className={bodyTextNormal}
          style={{ fontWeight: 600, color: vars.color.brandDarkGray }}
        >
          {user.displayName}
        </h2>
      </Flex>

      <Flex as="main" direction="column" gap="24px">
        <p className={clsx(bodyTextSmall, centerText)}>
          You are currently using Superflow as a guest user. By signing up you
          can make sure all your progress is saved
        </p>
        <ButtonPill intent="secondary" onClick={onGoToEmailSignup}>
          <Flex gap="5px">
            <Email width="24px" height="22px" />
            Sign up with email
          </Flex>
        </ButtonPill>

        <GoogleAuth onSuccess={onGoToProfile} />

        <p className={clsx(bodyTextSmall, centerText)}>
          Already have an account? <br></br>
          <button
            type="button"
            onClick={onGoToLogin}
            className={clsx(unstyledButton, linkText)}
          >
            Log in here
          </button>
        </p>
      </Flex>
    </>
  );
}
