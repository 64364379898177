import { ComponentProps, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InputError } from 'src/app/components/form/InputError/InputError';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { Flex } from 'src/app/components/shared/Flex';
import { ScrollableVerticalLayout } from 'src/app/components/shared/ScrollableVerticalLayout';
import { WorkspaceDto } from 'src/services/database/Workspaces/dto/workspace.dto';
import { WorkspaceUpdateDto } from 'src/services/database/Workspaces/dto/workspaceUpdate.dto';
import { fillParent } from 'src/styles/utils.css';
import { Fields } from './Fields';
import { Intro } from './Intro';
import useSubmitCallback from './useSubmitCallback';

type IFormProps = Pick<
  ComponentProps<typeof ScrollableVerticalLayout>,
  'header'
> & {
  workspace: WorkspaceDto;
  /** Called when the form's initial values change. */
  onDirty: (isDirty: boolean) => void;
};

export function Form({ workspace, header, onDirty }: IFormProps) {
  const form = useForm<WorkspaceUpdateDto>({
    defaultValues: new WorkspaceUpdateDto(workspace),
  });

  const { handleSubmit, formState, setError } = form;
  const { errors, isDirty, isSubmitting } = formState;

  const saveWorkspace = useSubmitCallback({
    onError: () => {
      setError('root', {
        message: "We couldn't save your changes. Please try again.",
      });
    },
    onSuccess: () => {},
  });

  useEffect(() => {
    onDirty(isDirty);
  }, [isDirty, onDirty]);

  return workspace ? (
    <Flex
      as="form"
      onSubmit={handleSubmit(saveWorkspace)}
      className={fillParent}
    >
      <ScrollableVerticalLayout
        header={header}
        content={
          <Flex direction="column" gap="26px" style={{ width: '420px' }}>
            <Intro
              workspaceName={workspace.name}
              workspaceSlug={workspace.slug}
              logoUrl={workspace.logo}
            />
            <FormProvider {...form}>
              <Fields style={{ width: '100%' }} />
            </FormProvider>
          </Flex>
        }
        footer={
          <Flex direction="column" gap="8px">
            <ButtonPill type="submit" intent="primary" disabled={isSubmitting}>
              Save changes
            </ButtonPill>
            {errors.root?.message && (
              <InputError>{errors.root.message}</InputError>
            )}
          </Flex>
        }
        className={fillParent}
      />
    </Flex>
  ) : null;
}
