import { CSSProperties } from 'react';
import { CloseButton } from 'src/app/components/auth/CloseButton';
import { ArrowLeft } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import { unstyledButton } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';

interface IWizardNavButtonsProps {
  className?: string;
  onBackArrowPress?: () => void;
  onCloseButtonPress?: () => void;
  style?: CSSProperties;
}

/**
 * Renders the close, and optional back button of the workspace creation wizard form.
 */
export function WizardNavButtons({
  className,
  onBackArrowPress,
  onCloseButtonPress,
  style,
}: IWizardNavButtonsProps) {
  const isBackButtonVisible = typeof onBackArrowPress === 'function';
  const isCloseButtonVisible = typeof onCloseButtonPress === 'function';
  return (
    <Flex
      justify="space-between"
      className={className}
      style={{ ...style, flexShrink: 0, padding: '12px' }}
    >
      {isBackButtonVisible && (
        <button
          type="button"
          onClick={onBackArrowPress}
          className={unstyledButton}
        >
          <ArrowLeft
            style={{
              width: '20px',
              height: '20px',
              color: vars.color.brandBodyText,
            }}
          />
        </button>
      )}
      {isCloseButtonVisible && (
        <CloseButton
          onClick={onCloseButtonPress}
          style={isBackButtonVisible ? {} : { marginLeft: 'auto' }}
        />
      )}
    </Flex>
  );
}
