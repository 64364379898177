import { ComponentProps } from 'react';
import { Flex } from 'src/app/components/shared/Flex';
import { ScrollableVerticalLayout } from 'src/app/components/shared/ScrollableVerticalLayout';
import { Spinner } from 'src/app/components/shared/Spinner';
import { fillParent } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';

type ILoadingProps = Omit<
  ComponentProps<typeof ScrollableVerticalLayout>,
  'content' | 'footer'
>;

export function Loading(props: ILoadingProps) {
  return (
    <ScrollableVerticalLayout
      {...props}
      content={
        <Flex className={fillParent}>
          <Spinner type="circleBall" size="40px" color={vars.color.light99} />
        </Flex>
      }
      footer={null}
    />
  );
}
