import { MenuItem } from './MenuItem';
import { MenuItemWithModal } from './MenuItemWithModal';
import { SidebarMenuRoot } from './SidebarMenuRoot';

/**
 * A dark-themed popover menu that's aligned to the right of its anchor element.
 *
 * Use the `useSidebarMenu` hook to implement the popover logic, and specify the anchor element.
 * 
 * To prevent multiple sidebar menus from being open, call the `useSidebarMenuState` hook.
 * This implements focus management globally for all sidebar menus and their content.
 *
 * @example
 * ```tsx
const [isOpen, setIsOpen] = useSidebarMenuState(MENU_ID); // pulls in focus state from Redux
const { getAnchorProps, getFloatingProps } = useSidebarMenu({
  isOpen,
  setIsOpen,
  placement: 'right-end',
  offsetOptions: { mainAxis: -5 },
}); // implements popover logic
return (
<>
  <button
    type="button"
    onClick={() => setIsOpen(!isOpen)}
    {...getAnchorProps()} // spread the anchor props
  >
    Toggle
  </button>
  {isOpen && (
    <SidebarMenu.Root
      {...getFloatingProps()} // spread the floating props
    >
      <SidebarMenu.Item (...) />
      <SidebarMenu.Item (...) />
    <SidebarMenu.Root />
  )}
</>
)
 * ```
 */
export const SidebarMenu = {
  /**
   * A presentational component that renders the sidebar menu's title and menu items.
   * Make sure all menu items are wrapped with this element.
   */
  Root: SidebarMenuRoot,
  /**
   * A presentational component that renders a clickable sidebar menu item.
   *
   * This component doesn't contain any logic. If e.g. a modal dialog needs to be toggled,
   * use `<SidebarMenu.ItemWithModal/>`.
   */
  Item: MenuItem,
  /**
   * A stateful component that composes the logic of `<FullPageContainer/>`
   * and sidebar focus state management, and renders a `<SidebarMenu.Item/>` with it.
   *
   * This component should be used if the menu item needs to toggle a positioned, full page modal dialog.
   */
  ItemWithModal: MenuItemWithModal,
} as const;

export { default as useSidebarMenu } from './useSidebarMenu';
