import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ActiveState = {
  workspaceId?: string;
  flowDefinitionId?: string;
  flowInstanceId?: string;
  sessionId?: string;
  shareId?: string;
};

const initialState: ActiveState = {
  workspaceId: undefined,
  flowDefinitionId: undefined,
  flowInstanceId: undefined,
  sessionId: undefined,
  shareId: undefined,
};

const { reducer, actions } = createSlice({
  name: 'active',
  initialState,
  reducers: {
    setActiveState(state, action: PayloadAction<ActiveState>) {
      return action.payload;
    },
  },
});

export { actions, reducer };
