import { SVGProps, memo } from 'react';

const SvgPrize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7 0c.5 0 .898.42.9.924l-.005.11-.09.826.995.001.105.007c.413.05.741.388.789.815l.006.109v1.861l-.004.202c-.087 2.093-1.51 3.828-3.409 4.315a5.394 5.394 0 0 1-1.783 1.587L9 10.863v2.165l2.701.001c.497 0 .9.417.9.931s-.403.93-.9.93h-9c-.497 0-.9-.416-.9-.93s.403-.93.9-.93l2.7-.002v-2.165l-.196-.102A5.401 5.401 0 0 1 3.412 9.17C1.514 8.683.09 6.948.004 4.855L0 4.653V2.792a.92.92 0 0 1 .795-.924L.9 1.86h.995l-.09-.828C1.75.52 2.11.065 2.596.006L2.7 0h9ZM3.6 3.723H1.8v.93c0 1.487 1.124 2.703 2.541 2.788l.159.004V4.653a.92.92 0 0 0-.795-.924L3.6 3.723Zm9 0h-1.8l-.105.006a.916.916 0 0 0-.789.816l-.006.108v2.792l.159-.004c1.365-.082 2.457-1.212 2.536-2.624l.005-.164v-.93Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgPrize);
export default Memo;
