import clsx from 'clsx';
import { CSSProperties } from 'react';
import { Flex } from 'src/app/components/shared/Flex';
import { ISidebarMenuContentProps } from 'src/app/components/workspace/types';
import { WizardNavButtons } from 'src/app/components/workspace/WizardNavButtons';
import { useConfirmCloseSidebar } from 'src/state/sidebar/sidebar';
import { fillParent } from 'src/styles/utils.css';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import { Form } from './Form';
import { Loading } from './Loading';
import { h2TextLight, modalWrapper } from './WorkspaceSettingsModal.css';

type IWorkspaceSettingsModalProps = ISidebarMenuContentProps & {
  className?: string;
  style?: CSSProperties;
};

export function WorkspaceSettingsModal({
  onClose,
  onLockSidebar,
  className,
  style,
}: IWorkspaceSettingsModalProps) {
  const workspace = useWorkspace();
  const closeWithConfirm = useConfirmCloseSidebar(onClose);

  const header = (
    <Flex direction="column" align="center" justify="stretch">
      <WizardNavButtons
        onCloseButtonPress={closeWithConfirm}
        style={{ width: '100%' }}
      />
      <h2 className={h2TextLight}>Workspace settings</h2>
    </Flex>
  );

  return (
    <div className={clsx(modalWrapper, className)} style={style}>
      {workspace ? (
        <Form workspace={workspace} header={header} onDirty={onLockSidebar} />
      ) : (
        <Loading header={header} className={fillParent} />
      )}
    </div>
  );
}
