import { SVGProps, memo } from 'react';

const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 28"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M0 1.402v25.196c0 1.107 1.313 1.776 2.311 1.177l21-12.597c.919-.552.919-1.805 0-2.356L2.311.226C1.313-.375 0 .295 0 1.402Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgPlay);
export default Memo;
