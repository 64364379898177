import { SVGProps, memo } from 'react';

const SvgClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#clock_svg__a)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.086 21.895a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
      <path d="M12.086 7.645v5.25h5.25" />
    </g>
    <defs>
      <clipPath id="clock_svg__a">
        <path fill="#fff" transform="translate(.086 .895)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgClock);
export default Memo;
