import clsx from 'clsx';
import { PasswordReminderForm } from 'src/app/components/auth/types';
import { InputError } from 'src/app/components/form/InputError/InputError';
import TextBox from 'src/app/components/form/TextBox/TextBox';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { Flex } from 'src/app/components/shared/Flex';
import { VITE_EMAIL_SUPPORT } from 'src/env';
import { unstyledButton } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { bodyTextSmall, brandPrimaryText, h4Text } from '../../auth.css';
import usePasswordReminderForm from '../../modals/PasswordReminderModal/usePasswordReminderForm';
import logoSuperflow from '/svg/logo-superflow.svg';

interface IFormProps {
  headingId: string;
  onGoToLogin: () => void;
  onPasswordReminder: (values: PasswordReminderForm) => Promise<void>;
}

export function Form({
  headingId,
  onGoToLogin,
  onPasswordReminder,
}: IFormProps) {
  const SUPPORT_EMAIL = VITE_EMAIL_SUPPORT;
  const { form, handlePasswordReminderWithError, rootErrorMessage } =
    usePasswordReminderForm({
      onPasswordReminder,
    });

  const { formState, handleSubmit, register } = form;
  const { errors, isSubmitting } = formState;

  return (
    <Flex direction="column" justify="center" gap="40px">
      <Flex direction="column" align="center" gap="24px">
        <img
          src={logoSuperflow}
          alt=""
          style={{
            width: '70px',
            height: '70px',
          }}
        />
        <h4 id={headingId} className={clsx(h4Text, brandPrimaryText)}>
          Reset your password
        </h4>
      </Flex>

      <form onSubmit={handleSubmit(handlePasswordReminderWithError)}>
        <TextBox
          type="email"
          label="Email"
          isLabelRendered={false}
          placeholder="Email"
          autoComplete="email"
          {...register('email', { required: true })}
          error={errors.email?.message}
        />
        {rootErrorMessage.length > 0 ? (
          <InputError>{rootErrorMessage}</InputError>
        ) : (
          <p>&nbsp;</p>
        )}
        <ButtonPill type="submit" intent="primary" disabled={isSubmitting}>
          Request reset link
        </ButtonPill>
      </form>

      <Flex direction="column" justify="center" align="flex-start" gap="12px">
        <p className={bodyTextSmall}>
          Go back to the{' '}
          <span>
            <button
              type="button"
              className={unstyledButton}
              onClick={onGoToLogin}
              style={{ color: vars.color.brandPurple }}
            >
              Log in screen{' '}
            </button>
          </span>{' '}
        </p>
        <p className={bodyTextSmall}>
          Need help? Contact{' '}
          <span style={{ color: vars.color.brandPurple }}>
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </span>
        </p>
      </Flex>
    </Flex>
  );
}
