import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { CSSProperties } from 'react';
import { AvatarGuestUser } from 'src/app/components/shared/AvatarGuestUser';
import { useSidebarMenuState } from 'src/state/sidebar/sidebar';
import { vars } from 'src/styles/vars.css';
import { Tooltip } from 'src/app/components/shared/Tooltip';
import {
  sidebarButton,
  sidebarButtonActive,
} from 'src/app/components/workspace/WorkspaceSidebar/Sidebar.css';
import { Flex } from 'src/app/components/shared/Flex';
import { fillParent, rounded } from 'src/styles/utils.css';
import {
  AuthPopover,
  type IPopOverHookArgs,
  useAuthPopover,
} from '../../auth/AuthPopover';

interface IAuthPopoverToggleProps
  extends Pick<IPopOverHookArgs, 'placement' | 'offset'> {
  /**
   * Sets the width and height of the user's avatar.
   *
   * The avatar is wrapped by a 5px margin inside a button
   * with its own padding.
   * @default 28px
   */
  avatarSize?: CSSProperties['width'];
  className?: string;
  style?: CSSProperties;
}

const MENU_ID = 'authPopover';

/**
 * Renders the user's avatar as a toggle button
 * that triggers the auth popover.
 */
export function AuthPopoverToggle({
  avatarSize = '28px',
  className,
  placement,
  offset,
  style,
}: IAuthPopoverToggleProps) {
  const [isOpen, setIsOpen] = useSidebarMenuState(MENU_ID);
  const { getAnchorProps, getFloatingProps } = useAuthPopover({
    isOpen,
    setIsOpen,
    placement,
    offset,
  });

  return (
    <>
      <Tooltip.Root placement="right">
        <Tooltip.Anchor>
          <button
            type="button"
            {...getAnchorProps()}
            title={'Toggle user settings'}
            className={clsx(
              sidebarButton,
              { [sidebarButtonActive]: isOpen },
              className,
            )}
            style={{
              ...style,
              padding: '2px',
            }}
          >
            <Flex
              as="span"
              isInline
              className={clsx(rounded, fillParent)}
              style={{
                outline: `1px solid ${vars.color.brandDark22}`,
                backgroundColor: vars.color.light95,
              }}
            >
              <AvatarGuestUser
                style={{
                  width: avatarSize,
                  height: avatarSize,
                }}
              />
            </Flex>
          </button>
        </Tooltip.Anchor>
        {!isOpen && <Tooltip.Content>User settings</Tooltip.Content>}
      </Tooltip.Root>
      <AnimatePresence>
        {isOpen && <AuthPopover {...getFloatingProps()} />}
      </AnimatePresence>
    </>
  );
}
