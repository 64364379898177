import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoginForm } from '../types';

/**
 * Implements login form state handling,
 * and provides a callback that catches server-side errors.
 */
export default function useLoginForm({
  onLogin,
}: {
  onLogin: (values: LoginForm) => Promise<void>;
}) {
  const form = useForm<LoginForm>();
  const [rootErrorMessage, setRootErrorMessage] = useState('');
  const handleLoginWithError = useCallback(
    async (values: LoginForm) => {
      try {
        setRootErrorMessage('');
        await onLogin(values);
      } catch (error) {
        setRootErrorMessage('Sign up failed, please try again.');
      }
    },
    [onLogin],
  );
  return useMemo(
    () => ({
      /**
       * React Hook Form instance
       * @see https://react-hook-form.com/
       */
      form,
      /**
       * Wrapped submit callback that catches server errors
       * and triggers a generic error message for the user.
       */
      handleLoginWithError,
      /**
       * User-facing top-level error message.
       */
      rootErrorMessage,
    }),
    [form, handleLoginWithError, rootErrorMessage],
  );
}
