import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import { Flex } from 'src/app/components/shared/Flex';
import { fillParent } from 'src/styles/utils.css';
import { smallScreens } from './ScrollableVerticalLayout.css';

interface IScrollableVerticalLayoutProps {
  className?: string;
  header: ReactNode;
  content: ReactNode;
  footer: ReactNode;
  style?: CSSProperties;
}

/**
 * The component serves as the scaffolding for workspace-related forms
 * that are usually rendered within `<FullPageContainer/>`.
 *
 * It arranges the 3 blocks of the header, main content, and footer in a vertical flex-box.
 * The main content block grows to fill the available space, and becomes scrollable if necessary.
 */
export function ScrollableVerticalLayout({
  className,
  header,
  content,
  footer,
  style,
}: IScrollableVerticalLayoutProps) {
  return (
    <Flex
      direction="column"
      justify="space-between"
      align="stretch"
      gap="50px"
      className={className}
      style={style}
    >
      <header
        style={{
          flexShrink: 0,
        }}
      >
        {header}
      </header>

      <main
        style={{
          flexGrow: '1',
          overflowY: 'auto',
        }}
      >
        <div className={clsx(fillParent, smallScreens)}>{content}</div>
      </main>

      <footer
        style={{
          flexShrink: 0,
        }}
      >
        {footer}
      </footer>
    </Flex>
  );
}
