import { SVGProps, memo } from 'react';

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.152 5.025 9.754 17.685 4 12.502"
      stroke="currentcolor"
      strokeOpacity={0.8}
      strokeWidth={2.575}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.152 5.025 9.754 17.685 4 12.502"
      stroke="currentcolor"
      strokeOpacity={0.45}
      strokeWidth={2.575}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgCheck);
export default Memo;
