import clsx from 'clsx';
import {
  CSSProperties,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
} from 'react';
import { brandColor } from 'src/styles/colors/brand';
import { posRela } from 'src/styles/utils.css';
import { Spinner } from '../../shared/Spinner';
import {
  cssInputElement,
  cssInputErrorOutline,
} from '../InputFieldBase/InputFieldBase.css';

type TextBoxInputTypes =
  | 'email'
  | 'number'
  | 'password'
  | 'tel'
  | 'text'
  | 'url'
  | 'date';

// TODO move to InputFieldBase
type WithoutStyling<T> = Omit<T, 'className' | 'style'>;
export type WithStyledWrapper<T> = WithoutStyling<T> & {
  /** Class name passed directly to the input element. */
  inputClassName?: string;
  /** Style rules passed directly to the input element. */
  inputStyle?: CSSProperties;
  /** Class name passed to the wrapper element. */
  wrapperClassName?: string;
  /** Style rules passed to the wrapper element. */
  wrapperStyle?: CSSProperties;
};

interface ITextBoxInputProps
  extends WithStyledWrapper<InputHTMLAttributes<HTMLInputElement>> {
  /** If `true`, an inline spinner is rendered. */
  isWaiting?: boolean;
  /** If `true`, the error style is applied on the input. */
  hasError?: boolean;
  /**
   * Optional element contained on the right-hand side of the input.
   * If `isWaiting` is true, then the component is replaced with a spinner.
   *
   * The slot is vertically centered relative to the input element.
   */
  slotRight?: ReactElement;
  /** Controls the exact type of the input. Defaults to `text`. */
  type?: TextBoxInputTypes;
}

/**
 * Renders a single-line text-like input.
 *
 * To have it wrapped with `<label>`, use `<TextBox/>`.
 */
export const TextBoxInput = forwardRef<HTMLInputElement, ITextBoxInputProps>(
  function TextBox(
    {
      inputClassName,
      inputStyle,
      isWaiting,
      hasError,
      slotRight,
      type = 'text',
      wrapperClassName,
      wrapperStyle,
      ...props
    },
    ref,
  ) {
    return (
      <div
        className={wrapperClassName}
        style={{
          display: 'inline-block',
          ...wrapperStyle,
        }}
      >
        <div className={posRela}>
          <input
            ref={ref}
            type={type}
            className={clsx(inputClassName, cssInputElement, {
              [cssInputErrorOutline]: hasError,
            })}
            style={{ width: '100%', ...inputStyle }}
            {...props}
          />

          {(isWaiting || slotRight) && (
            <div
              style={{
                position: 'absolute',
                zIndex: 2,
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {isWaiting ? (
                <Spinner type="disk" size="20px" color={brandColor.purple} />
              ) : (
                <>{slotRight}</>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);
