import clsx from 'clsx';
import { useMatch } from 'react-router-dom';
import { UserGear } from '@phosphor-icons/react';
import { Flex } from 'src/app/components/shared/Flex';
import {
  sidebarButton,
  sidebarButtonActive,
} from 'src/app/components/workspace/WorkspaceSidebar/Sidebar.css';
import { Tooltip } from 'src/app/components/shared/Tooltip';
import { Path } from 'src/app/router/routes';
import { useTypedNavigate } from 'src/app/router';

export function SystemAdminSidebarButton() {
  const navigate = useTypedNavigate();
  const isActive = useMatch(Path.SystemAdmin);

  return (
    <Tooltip.Root placement="right">
      <Tooltip.Anchor>
        <Flex
          as="button"
          type="button"
          onClick={() => navigate(Path.SystemAdmin)}
          title="System admin"
          className={clsx(sidebarButton, isActive && sidebarButtonActive)}
        >
          <UserGear size={24} />
        </Flex>
      </Tooltip.Anchor>
      {!isActive && <Tooltip.Content>Go to System Admin</Tooltip.Content>}
    </Tooltip.Root>
  );
}
