import { SVGProps, memo } from 'react';

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m19.053 17.456 4.997 4.995-1.65 1.65-4.996-4.996a10.454 10.454 0 0 1-6.553 2.297c-5.796 0-10.5-4.704-10.5-10.5S5.055.402 10.85.402s10.5 4.704 10.5 10.5a10.454 10.454 0 0 1-2.298 6.554Zm-2.34-.866a8.14 8.14 0 0 0 2.304-5.688 8.164 8.164 0 0 0-8.166-8.166 8.164 8.164 0 0 0-8.167 8.166 8.164 8.164 0 0 0 8.167 8.167 8.14 8.14 0 0 0 5.687-2.304l.175-.175Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgSearch);
export default Memo;
