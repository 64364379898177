import { SVGProps, memo } from 'react';

const SvgExpandDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 54 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.667 6.685h13.212v13.212m-15.41 2.202L46.883 6.685M20.455 46.32H7.243V33.106m15.411-2.202L7.24 46.318m-.002-26.43V6.676H20.45m2.202 15.412L7.238 6.674M46.872 33.1v13.212H33.66m-2.202-15.41 15.414 15.414"
      stroke="currentColor"
      strokeWidth={4.804}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgExpandDouble);
export default Memo;
