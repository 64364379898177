import {
  collection,
  doc,
  documentId,
  getDoc,
  getFirestore,
  orderBy,
  query,
  QueryConstraint,
  where,
} from '@firebase/firestore';
import { useMemo, useRef, useSyncExternalStore } from 'react';
import { UUID } from 'src/@types/common';
import { FlowDefinitionDto } from 'src/services/database/FlowDefinitions/dto/flowDefinition.dto';
import {
  CollectionQueryArgs,
  FirestoreFilterArg,
} from 'src/services/database/types';
import { useFirestoreQuerySubscription } from 'src/services/database/useFirestoreQuerySubscription';
import { useMemoizedQueryArgs } from 'src/services/database/utils';
import { FlowDefinitionConverter } from 'src/services/database/FlowDefinitions/converter';
import { getPathForFlowDefinitions } from '../paths';
import { useFirestoreDocSubscription } from '../useFirestoreDocSubscription';

/**
 * Subscribes to a single flow document, so changes are synced live.
 * @param flowDefinitionId - The hook won't subscribe to Firestore as long as this value is undefined (e.g. during a 1st render).
 * @returns  High-level metadata for a flow definition and an optional error object.
 */
export function useFlowDefinitionDoc(flowDefinitionId: UUID) {
  // Query must be memoized to avoid infinite re-renders.
  const query = useMemo(
    () =>
      flowDefinitionId
        ? doc(getFirestore(), getPathForFlowDefinitions(flowDefinitionId))
        : undefined,
    [flowDefinitionId],
  );
  const store = useFirestoreDocSubscription(FlowDefinitionConverter, query);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return {
    result,
    error: store.error,
  };
}

/**
 * Subscribes to a collection of flow definitions. The collection can be filtered and sorted,
 * and the results are updated live.
 *
 * Firestore rules are in place to ensure workspace access control, but regardless,
 * this function is mainly for internal use. See {@link useFlowDefinitionList} for a more user-friendly API.
 *
 * **Flow folder names must match the `slug` value**,
 * otherwise the flow adapter won't be found!
 * @returns  Array of flow definitions and an optional error object.
 */
export function useFlowDefinitionCol(
  queryArg?: CollectionQueryArgs<Partial<FlowDefinitionDto>>,
) {
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
  const filterQuery = useMemo(() => {
    const constraints: QueryConstraint[] = [];

    if (filterByIds && filterByIds.length > 0) {
      // Firebase throws when filtering for 'in' with an empty array
      constraints.push(where(documentId(), 'in', filterByIds));
    }
    if (filterBy) {
      filterBy
        .filter(
          function noUndefinedFilterCriteria(
            condition,
          ): condition is FirestoreFilterArg<Partial<FlowDefinitionDto>> {
            return condition?.value !== undefined;
          },
        )
        .forEach((condition) => {
          constraints.push(where(condition.key, condition.op, condition.value));
        });
    }
    if (sort) {
      constraints.push(orderBy(sort.key, sort.order));
    }
    return query(
      collection(getFirestore(), getPathForFlowDefinitions()),
      ...constraints,
    );
  }, [filterBy, filterByIds, sort]);

  const converter = useRef(FlowDefinitionConverter);
  const store = useFirestoreQuerySubscription(converter.current, filterQuery);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return {
    result,
    error: store.error,
  };
}

/**
 * Retrieves a flow definition document from Firestore async.
 * @param flowDefinitionId - ID of the flow definition to retrieve.
 */
export async function getFlowDefinitionDoc(flowDefinitionId: UUID) {
  return await getDoc(
    doc(
      getFirestore(),
      getPathForFlowDefinitions(flowDefinitionId),
    ).withConverter(FlowDefinitionConverter),
  );
}
