import { createValidatorConverter } from 'src/services/database/utils';
import { FlowDefinitionDto } from 'src/services/database/FlowDefinitions/dto/flowDefinition.dto';

/**
 * This should be passed to a document subscription to properly convert data to/from Firestore.
 *
 * Later a factory function could be created for these converters
 * that require type assertion functions to be present.
 */
export const FlowDefinitionConverter = createValidatorConverter(
  FlowDefinitionDto,
  { useFirestoreTimestamp: true },
);
