import { PropsWithChildren } from 'react';
import { Flex } from '../../shared/Flex';

export function DefaultLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <Flex
      direction="column"
      justify="center"
      gap="24px"
      style={{ maxWidth: '340px', marginLeft: 'auto', marginRight: 'auto' }}
    >
      {children}
    </Flex>
  );
}
