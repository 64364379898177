import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';
import { fillParent } from 'src/styles/utils.css';
import { IRequiredContainerProps } from './types';

interface IFullPageContainerProps extends IRequiredContainerProps {
  children: (args: { onClose: () => void }) => ReactNode;
  className?: string;
}

/**
 * Renders a container component that acts as the hybrid of a modal and a popover:
 * - it manages focus like a modal dialog;
 * - it's positioned immediately to the right of an anchor element;
 * - it fills the screen vertically
 * - it fills the screen horizontally to the right of the anchor element.
 *
 * The component should be controlled by its parent with the help of
 * the `useFullPageContainer` hook.
 */
export const FullPageContainer = forwardRef<
  HTMLDivElement,
  IFullPageContainerProps
>(function FullPageContainer(
  { children, className, floatingContext, floatingProps, onClose, style },
  ref,
) {
  return (
    <FloatingFocusManager modal context={floatingContext}>
      <FloatingPortal>
        <div
          ref={ref}
          {...floatingProps}
          role="dialog"
          className={clsx(fillParent, className)}
          style={style}
        >
          {children({ onClose })}
        </div>
      </FloatingPortal>
    </FloatingFocusManager>
  );
});
