import { SVGProps, memo } from 'react';

const SvgGoBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.956.543.26 2.822a.06.06 0 0 0 .067.052c8.065-.912 10.41 2.434 10.441 6.625.003.31-.461.4-.642.147-1.492-2.077-4.418-2.038-9.341-1.801a.06.06 0 0 0-.056.064l.257 2.801a.44.44 0 0 1-.635.434L1.004 7.014a.44.44 0 0 1-.06-.75L9.26.228a.44.44 0 0 1 .696.316Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgGoBack);
export default Memo;
