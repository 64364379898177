import clsx from 'clsx';
import { Flex } from 'src/app/components/shared/Flex';
import { unstyledButton } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import sendEmail from '../../assets/sendEmail.svg';
import { bodyTextSmall, brandPrimaryText, h4Text } from '../../auth.css';

interface ISuccessProps {
  headingId: string;
  onGoToLogin: () => void;
}

export function Success({ headingId, onGoToLogin }: ISuccessProps) {
  return (
    <Flex direction="column" align="center" gap="40px">
      <Flex direction="column" align="center" gap="24px">
        <img
          src={sendEmail}
          alt=""
          style={{
            marginLeft: '26px', // Visually centers the envelope from the graphic
          }}
        />
        <h4 id={headingId} className={clsx(h4Text, brandPrimaryText)}>
          Reset link has been sent
        </h4>
      </Flex>

      <Flex direction="column" justify="center" align="flex-start" gap="40px">
        <p className={bodyTextSmall}>
          Check your inbox soon! The link will expire in one hour.
        </p>

        <p className={bodyTextSmall}>
          Go back to the{' '}
          <span>
            <button
              type="button"
              className={unstyledButton}
              onClick={onGoToLogin}
              style={{ color: vars.color.brandPurple }}
            >
              Log in screen{' '}
            </button>
          </span>{' '}
        </p>
      </Flex>
    </Flex>
  );
}
