import { SVGProps, memo } from 'react';

const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.472 13.421 2.843-2.842a1.005 1.005 0 0 1 1.499 1.333L7.736 12l-2.843 2.843a3.015 3.015 0 0 0 4.135 4.385l.13-.121L12 16.264a1.005 1.005 0 0 1 1.5 1.334l-.079.087-2.842 2.843a5.025 5.025 0 0 1-7.258-6.95l.15-.157Zm9.95-9.95a5.025 5.025 0 1 1 7.106 7.108l-2.843 2.842A1.005 1.005 0 1 1 16.265 12l2.842-2.843a3.015 3.015 0 1 0-4.264-4.264L12 7.736a1.005 1.005 0 0 1-1.421-1.421l2.842-2.843Zm0 5.686a1.005 1.005 0 1 1 1.42 1.422l-4.263 4.264a1.005 1.005 0 1 1-1.422-1.422l4.264-4.264Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgLink);
export default Memo;
