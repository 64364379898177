import { SVGProps, memo } from 'react';

const SvgGrowth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.613 11.79.094-.083L8 5.415l3.293 3.292a1 1 0 0 0 1.32.083l.094-.083L17 4.415l1.293 1.292c.602.603 1.614.22 1.701-.593L20 5 19.998.94 19.99.854l-.025-.119-.024-.076-.034-.081-.052-.098-.067-.096-.08-.09a1.014 1.014 0 0 0-.112-.097l-.11-.071L19.37.07l-.105-.035-.117-.025-.06-.007L19 0h-4c-.852 0-1.297.986-.783 1.623l.076.084L15.585 3 12 6.585 8.707 3.293a1 1 0 0 0-1.32-.083l-.094.083-7 7a1 1 0 0 0 1.32 1.497Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgGrowth);
export default Memo;
