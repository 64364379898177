import { SVGProps, memo } from 'react';

const SvgContext = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 4 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.825.5c-.82 0-1.49.75-1.49 1.667 0 .916.67 1.666 1.49 1.666s1.491-.75 1.491-1.666C3.316 1.25 2.646.5 1.826.5Zm0 11.667c-.82 0-1.49.75-1.49 1.666 0 .917.67 1.667 1.49 1.667s1.491-.75 1.491-1.667c0-.916-.67-1.666-1.49-1.666Zm0-5.834c-.82 0-1.49.75-1.49 1.667s.67 1.667 1.49 1.667S3.316 8.917 3.316 8s-.67-1.667-1.49-1.667Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgContext);
export default Memo;
