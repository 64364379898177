import {
  FloatingArrow,
  FloatingPortal,
  useDelayGroup,
  useDelayGroupContext,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import { fallbackVar } from '@vanilla-extract/css';
import clsx from 'clsx';
import { forwardRef, HTMLProps } from 'react';
import { vars } from 'src/styles/vars.css';
import { useTooltipState } from './context';
import { tooltipVars, wrapper } from './Tooltip.css';

interface ITooltipContentProps extends HTMLProps<HTMLDivElement> {
  /**
   * Sets the width for the tooltip arrow SVG.
   * @default 20
   */
  arrowWidth?: number;
  /**
   * Sets the height for the tooltip arrow SVG.
   * @default 20
   */
  arrowHeight?: number;
  /**
   * Allows rendering an arrow for the tooltip
   * @default false
   */
  hasArrow?: boolean;
}

export const TooltipContent = forwardRef<HTMLDivElement, ITooltipContentProps>(
  function TooltipContent(
    {
      arrowWidth,
      arrowHeight,
      hasArrow,
      children,
      className,
      style: outerStyle,
      ...props
    },
    ref,
  ) {
    const state = useTooltipState();
    const id = state.id;
    const { isInstantPhase, currentId } = useDelayGroupContext();
    const mergedRef = useMergeRefs([state.refs.setFloating, ref]);

    useDelayGroup(state.context, { id });
    const instantDuration = 0;
    const duration = 250;

    const { isMounted, styles } = useTransitionStyles(state.context, {
      duration: isInstantPhase
        ? {
            open: instantDuration,
            // `id` is this component's `id`
            // `currentId` is the current group's `id`
            close: currentId === id ? duration : instantDuration,
          }
        : duration,
      initial: {
        opacity: 0,
      },
    });

    if (!isMounted) return null;

    return (
      <FloatingPortal>
        <div
          ref={mergedRef}
          id={id} // Matches aria-labelledby in <Anchor/>
          className={clsx(wrapper, className)}
          style={{
            ...state.floatingStyles,
            ...outerStyle,
            ...styles,
            // Prevents "freezing" position re-calculation of tooltip
            // when the pointer is quick enough to hover above the tooltip
            pointerEvents: state.isPointerTooltip ? 'none' : undefined,
          }}
          {...state.getFloatingProps(props)}
        >
          {children}
          {hasArrow && (
            <FloatingArrow
              ref={state.arrowRef}
              context={state.context}
              width={arrowWidth}
              height={arrowHeight}
              fill={fallbackVar(tooltipVars.background, vars.color.brandGray80)}
            />
          )}
        </div>
      </FloatingPortal>
    );
  },
);
