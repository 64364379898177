import { SVGProps, memo } from 'react';

const SvgExpandSimple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.102 8.43h14.534v14.534m-16.954 2.421L50.637 8.429M23.533 50.072H9V35.538m16.951-2.423L8.996 50.072"
      stroke="currentColor"
      strokeWidth={4.933}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgExpandSimple);
export default Memo;
