import clsx from 'clsx';
import { CSSProperties, PropsWithChildren } from 'react';
import { authThemeDark, authThemeLight, inputLabelTheme } from '../auth.css';
import { LoginForm } from '../types';
import { LoginFormContextProvider } from './context';
import useLoginForm from './useLoginForm';

interface IRootProps {
  className?: string;
  /** Called when the user submits a valid form. */
  onLogin: (values: LoginForm) => Promise<void>;
  /**
   * Defaults to `light`.
   * Sets the text colors of the child components.
   * The value refers to text colors, so e.g. a light theme will produce darker text colors.
   */
  theme?: 'light' | 'dark';
  style?: CSSProperties;
}

export function Root({
  children,
  className,
  onLogin,
  theme = 'light',
  style,
}: PropsWithChildren<IRootProps>) {
  const formInstance = useLoginForm({
    onLogin,
  });
  const { form, handleLoginWithError } = formInstance;

  return (
    <form
      onSubmit={form.handleSubmit(handleLoginWithError)}
      className={clsx(
        {
          [authThemeDark]: theme === 'dark',
          [authThemeLight]: theme === 'light',
        },
        className,
      )}
      style={style}
    >
      <div className={inputLabelTheme}>
        <LoginFormContextProvider instance={formInstance}>
          {children}
        </LoginFormContextProvider>
      </div>
    </form>
  );
}
