import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class NudgeTextDto {
  /**
   * If set, rendered separately from the nudge text.
   */
  @IsOptional()
  @IsString()
  readonly emoji?: string;

  /**
   * If set, rendered as a rich text block.
   */
  @IsOptional()
  @IsString()
  readonly text?: string;

  /**
   * If set, rendered as the title formatted as rich text.
   */
  @IsOptional()
  @IsString()
  readonly title?: string;

  /**
   * This property is the implementation of `LocaleMap<T>`
   * to resolve some type issues between composed TS types and DTOs.
   */
  @IsBoolean()
  readonly isPublished: boolean;

  constructor(dto: NudgeTextDto) {
    this.emoji = dto.emoji;
    this.text = dto.text;
    this.title = dto.title;
    this.isPublished = dto.isPublished;
  }
}
