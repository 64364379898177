import { SVGProps, memo } from 'react';

const SvgFist = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.302 2.808a2.36 2.36 0 0 1 1.48-.53c1.068 0 1.965.713 2.266 1.684a.662.662 0 0 1 .117.36V15.949c0 1.01-.069 2.032-.204 3.036l-.368 2.733c-.073.535-.11 1.08-.11 1.622v2.772c0 .376-.304.681-.68.681H7.865a.681.681 0 0 1-.681-.68V21.49l-5.457-5.682a5.597 5.597 0 0 1-1.55-2.904 5.712 5.712 0 0 1 .478-3.61l1.743-3.534a.683.683 0 0 1 .252-.278l2.434-1.506V2.76A2.685 2.685 0 0 1 7.766.08h.14c.842 0 1.585.397 2.077 1.005a2.71 2.71 0 0 1 1.967-.85c1.186 0 2.187.767 2.56 1.827.436-.296.961-.47 1.526-.47.944 0 1.777.484 2.266 1.216Zm2.5 1.854a1.022 1.022 0 0 0-2.042 0V8.06a1.022 1.022 0 0 0 2.043 0v-3.4Zm-4.766-1.707c.751 0 1.362.61 1.362 1.361v3.41c0 .751-.61 1.362-1.362 1.362-.75 0-1.362-.61-1.362-1.362v-3.41c0-.75.611-1.361 1.362-1.361Zm-2.724.004c0-.75-.61-1.361-1.361-1.361-.752 0-1.363.61-1.363 1.361v4.105l1.63.933a.68.68 0 0 1 .265.907l-.048.091a1.36 1.36 0 0 0 .877-1.269V2.96ZM7.766 1.442h.14c.729 0 1.32.592 1.32 1.32V6.283L6.447 4.69v-.128a.663.663 0 0 0 0-.478V2.761c0-.727.592-1.32 1.32-1.32Zm12.477 20.094.368-2.733a21.49 21.49 0 0 0 .192-2.854v-5.744c-.31.149-.655.239-1.021.239a2.368 2.368 0 0 1-1.792-.828 2.71 2.71 0 0 1-1.954.834c-.817 0-1.543-.37-2.043-.941-.49.561-1.2.924-1.999.937 1.591.728 2.68 2.285 2.68 4.031v3.789a.681.681 0 0 1-1.362 0v-3.789c0-1.52-1.212-2.84-2.82-3.071-.014-.002-.026-.008-.038-.013a.18.18 0 0 0-.03-.01 4.602 4.602 0 0 1-2.395.688c-.438 0-.877-.066-1.305-.204-.936-.302-1.63-.874-2.062-1.7a.68.68 0 1 1 1.206-.632c.265.506.682.845 1.275 1.036 1.428.462 3.061-.25 3.798-1.659l.031-.059-5.707-3.269-1.59.912c-.033.02-.068.028-.103.037l-.042.012-1.654 3.353a4.34 4.34 0 0 0-.364 2.744c.167.845.577 1.61 1.185 2.211l5.659 5.892c.122.127.19.296.19.472v4.214h11.576V23.34c0-.603.04-1.21.121-1.804Z"
      fill="#000"
    />
  </svg>
);

const Memo = memo(SvgFist);
export default Memo;
