import {
  autoUpdate,
  offset,
  OffsetOptions,
  Placement,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo } from 'react';
import {
  IWorkspaceFloatingHookArgs,
  IWorkspaceFloatingHookReturn,
} from '../../types';
import { ISidebarMenuRootProps } from './SidebarMenuRoot';

type ISidebarMenuHookArgs = IWorkspaceFloatingHookArgs & {
  /** Optional adjustments to the popover's position. */
  offsetOptions?: OffsetOptions;
  /** The popover's placement. */
  placement: Placement;
};

/**
 * Configures Floating UI to manage the `<SidebarMenu/>` dialog.
 * @returns Props generated by Floating UI to manage the dialog.
 * These props should be spread over both the anchor and the popover element.
 * @see https://floating-ui.com/
 */
export default function useSidebarMenu({
  isOpen,
  setIsOpen,
  placement = 'right',
  offsetOptions,
}: ISidebarMenuHookArgs): IWorkspaceFloatingHookReturn<ISidebarMenuRootProps> {
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: offsetOptions ? [offset(offsetOptions)] : undefined,
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const click = useClick(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
    role,
  ]);

  return useMemo(
    () => ({
      getAnchorProps: () => ({
        ref: refs.setReference,
        ...getReferenceProps(),
      }),
      getFloatingProps: () => ({
        ref: refs.setFloating,
        floatingContext: context,
        floatingProps: getFloatingProps(),
        onClose: () => setIsOpen(false),
        style: floatingStyles,
      }),
    }),
    [
      context,
      floatingStyles,
      getFloatingProps,
      getReferenceProps,
      refs.setFloating,
      refs.setReference,
      setIsOpen,
    ],
  );
}
