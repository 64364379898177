import { FirebaseError } from '@firebase/app';
import clsx from 'clsx';
import { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { authThemeDark, authThemeLight, inputLabelTheme } from '../auth.css';
import { RootFormError, SignUpForm } from '../types';

interface IRootProps {
  className?: string;
  /** Called when the user submits a valid form. */
  onSignUp: (values: SignUpForm) => Promise<void>;
  /**
   * Defaults to `light`.
   * Sets the text colors of the child components.
   * The value refers to text colors, so e.g. a light theme will produce darker text colors.
   */
  theme?: 'light' | 'dark';
  style?: CSSProperties;
}

export function Root({
  children,
  className,
  onSignUp,
  style,
  theme = 'light',
}: PropsWithChildren<IRootProps>) {
  const defaultValues = useMemo<SignUpForm>(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    }),
    [],
  );
  const methods = useForm<SignUpForm & RootFormError>({
    defaultValues,
  });
  const onSubmit: SubmitHandler<SignUpForm> = async (data) => {
    try {
      await onSignUp(data);
    } catch (error) {
      methods.setError(
        'root.serverError',
        error instanceof FirebaseError
          ? { type: error.code }
          : { message: 'Sign up failed' },
      );
    }
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={clsx(
          {
            [authThemeDark]: theme === 'dark',
            [authThemeLight]: theme === 'light',
          },
          className,
        )}
        style={style}
      >
        <div className={inputLabelTheme}>{children}</div>
      </form>
    </FormProvider>
  );
}
