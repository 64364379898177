import { SVGProps, memo } from 'react';

const SvgVideoOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#videoOff_svg__a)"
      strokeWidth={4.729}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    >
      <path d="M30.631 30.858v1.872a3.744 3.744 0 0 1-3.743 3.743H6.299a3.744 3.744 0 0 1-3.743-3.743V14.013a3.743 3.743 0 0 1 3.743-3.744h3.744m10.593 0h6.252a3.743 3.743 0 0 1 3.743 3.744v6.251l1.872 1.872 11.23-8.123V32.73M2.556 2.782l41.177 41.177" />
    </g>
    <defs>
      <clipPath id="videoOff_svg__a">
        <path fill="currentColor" d="M.685.91h44.92v44.921H.686z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgVideoOff);
export default Memo;
