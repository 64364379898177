import { useCallback } from 'react';
import { loginWithEmailAndPassword } from 'src/services/auth';
import { useSetUser } from 'src/utils/userContent/hooks';
import { LoginForm } from '../types';

/**
 * @returns Memoized callback that logs in the user
 * with email and password, and stores their data in Redux.
 */
export default function useLoginCallback(
  args: { onSuccess?: () => void } = {},
) {
  const setUser = useSetUser();
  const { onSuccess } = args;

  return useCallback(
    async (loginUser: LoginForm) => {
      // The caller should deal with errors
      const response = await loginWithEmailAndPassword(loginUser);
      if (response?.user) {
        const { email, isAnonymous, uid, displayName } = response.user;
        setUser({
          displayName,
          email,
          isAnonymous,
          uid,
        });

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    },
    [onSuccess, setUser],
  );
}
