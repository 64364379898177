import {
  FloatingContext,
  FloatingFocusManager,
  FloatingPortal,
} from '@floating-ui/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { CSSProperties, forwardRef, useId } from 'react';
import { posRela } from 'src/styles/utils.css';
import { Positioned } from '../../shared/Positioned';
import { CloseButton } from '../CloseButton';
import { floatingContent } from './AuthPopover.css';
import { Content } from './Content';

interface IAuthPopoverProps {
  className?: string;
  /**
   * Floating UI context required for managing popover focus.
   * @see https://floating-ui.com/docs/FloatingFocusManager#context
   */
  floatingContext: FloatingContext;
  /**
   * Props generated by Floating UI that are necessary for managing and positioning the popover.
   * @see https://floating-ui.com/docs/popover#usefloating-hook
   */
  floatingProps?: Record<string, unknown>;
  /**
   * Called when the user tries to close the popover from within the component.
   */
  onClose: () => void;
  style?: CSSProperties;
}

/**
 * Renders a popover that checks the user's authentication status,
 * and renders either
 * - a guest user welcome
 * - a signup or login form
 * - a profile editor when authenticated.
 *
 * The component should be used in conjunction with the
 * `useAuthPopover` hook.
 */
export const AuthPopover = forwardRef<HTMLElement, IAuthPopoverProps>(
  function AuthPopover(
    { className, floatingContext, floatingProps, onClose, style },
    ref,
  ) {
    const headingId = useId();
    return (
      <FloatingFocusManager context={floatingContext} modal={true}>
        <FloatingPortal>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.25,
            }}
          >
            <aside
              ref={ref}
              aria-labelledby={headingId}
              className={clsx(floatingContent, posRela, className)}
              style={style}
              {...floatingProps}
            >
              <Positioned top={5} right={0} unit="px">
                <CloseButton onClick={onClose} />
              </Positioned>
              <Content headingId={headingId} onClose={onClose} />
            </aside>
          </motion.div>
        </FloatingPortal>
      </FloatingFocusManager>
    );
  },
);
