import * as convert from 'color-convert';

/**
 * Defines a color value in HSL format (with optional Alpha setting),
 * so it can be manipulated for various use cases.
 */
export class HSL {
  static fromHex(hex: string) {
    return new HSL(...convert.hex.hsl(hex));
  }

  static fromRGB(r: number, g: number, b: number, a?: number) {
    return new HSL(...convert.rgb.hsl(r, g, b), a);
  }

  static fromRGBString(rgb: string) {
    const match = rgb.match(/\d+/g);
    if (!match || match.length < 3) {
      throw new Error(`Invalid RGB format ${rgb}`);
    }

    const [r, g, b, a] = match as [string, string, string, string?];
    return HSL.fromRGB(
      Number.parseInt(r, 10),
      Number.parseInt(g, 10),
      Number.parseInt(b, 10),
      Number.parseFloat(a ?? '1'),
    );
  }

  static fromHSLString(hsl: string) {
    const match = hsl.match(/\d+/g);
    if (!match || match.length < 3) {
      throw new Error(`Invalid HSL format ${hsl}`);
    }

    const [h, s, l, a] = match as [string, string, string, string?];
    return new HSL(
      Number.parseInt(h, 10),
      Number.parseInt(s, 10),
      Number.parseInt(l, 10),
      Number.parseFloat(a ?? '1'),
    );
  }

  static fromString(color: string) {
    if (color.startsWith('#')) {
      return HSL.fromHex(color);
    }

    if (/^rgba?\((?:\d+,\s*){2}\d+(,\s*\d+|)\)$/.test(color)) {
      return HSL.fromRGBString(color);
    }

    if (/^hsla?\(\d+(?:,\s*\d+%){2}(,\s*\d+|)\)$/.test(color)) {
      return HSL.fromHSLString(color);
    }

    throw new Error(`Invalid color string, ${color}`);
  }

  constructor(
    readonly h: number,
    readonly s: number,
    readonly l: number,
    readonly a = 1,
  ) {}

  /**
   * @param opacity - Optional value between `0-1` to overwrite the stored opacity value.
   * @returns A `hsla(...)` string that can be applied directly in CSS rules.
   */
  toString(opacity?: number) {
    return `hsla(${this.h}, ${this.s}%, ${this.l}%, ${
      typeof opacity === 'number' ? opacity : this.a
    })`;
  }

  /**
   * Darkens the color by the specified amount, returning a new HSL object.
   * @param percent - The factor to darken the color by.
   * @returns A new HSL object with the darkened color.
   */
  darken(percent: number) {
    return new HSL(this.h, this.s, Math.max(0, this.l - percent), this.a);
  }

  /**
   * Lightens the color by the specified amount, returning a new HSL object.
   * @param percent - The factor to lighten the color by.
   * @returns A new HSL object with the lightened color.
   */
  lighten(percent: number) {
    return new HSL(this.h, this.s, Math.min(100, this.l + percent));
  }

  /**
   * Saturate the color by the specified amount, returning a new HSL object.
   * @param percent - The percent to saturate the color by.
   * @returns A new HSL object with the saturated color.
   */
  saturate(percent: number) {
    return new HSL(this.h, Math.min(100, this.s + percent), this.l);
  }

  /**
   * Desaturate the color by the specified amount, returning a new HSL object.
   * @param percent - The percent to desaturate the color by.
   * @returns A new HSL object with the desaturated color.
   */
  desaturate(percent: number) {
    return new HSL(this.h, Math.max(0, this.s - percent), this.l);
  }

  /**
   * Shifts the hue of the color by the specified amount, returning a new HSL
   * object.
   * @param degrees - The number of degrees to shift the hue by.
   * @returns A new HSL object with the shifted hue.
   */
  shift(degrees: number) {
    return new HSL((this.h + degrees) % 360, this.s, this.l);
  }

  /**
   * Helper method to generate a gradient array of colors based on the current
   * color. It returns an array of two HSL objects, the first being darker and
   * the second being lighter than the current color. It shifts the hue of the
   * current color by 5 degrees each way to help with perceived color.
   * @param range - The range of the gradient to lighten or darken the color by.
   * @returns An array of two HSL objects representing the gradient.
   */
  gradient(range: number = 15): [HSL, HSL] {
    const half = Math.round(range / 2);
    const dark = this.darken(range)
      .desaturate(half)
      .shift(-1 * half);
    const light = this.lighten(range).saturate(range).shift(half);
    return [dark, light];
  }
}

type GradientColorStep = {
  hsl: HSL;
  range?: string;
};

/**
 * Defines a multistep linear gradient. The gradient is defined by an array of
 * steps, each of which can be an HSL color or a string color.
 */
export class GradientColor {
  constructor(
    /** Gradient steps */
    readonly steps: GradientColorStep[],
    /** Direction of the linear gradient */
    readonly degree: number,
  ) {
    if (this.steps.length < 2) {
      throw new Error('Gradient must have at least two steps');
    }
  }

  /**
   * @param opacity - Defaults to `1`. Adjusts the opacity of both gradient colors.
   * @returns A `linear-gradient()` CSS color value.
   */
  toString(opacity: number = 1) {
    if (opacity !== undefined && (Number(opacity) < 0 || Number(opacity) > 1)) {
      throw new Error('Opacity must be between 0-1');
    }

    return `linear-gradient(
    ${this.degree}deg, 
    ${this.steps.map((step) => `${step.hsl} ${step.range ?? ''}`).join(', ')}
    )`;
  }
}

/**
 * Defines a 2-step linear gradient color. This is used in many places through
 * the app, so we keep it as a separate class for now. Don't use this class for
 * new code.
 * @deprecated
 */
export class TwoStepGradientColor extends GradientColor {
  constructor(
    /** Gradient start color */
    readonly start: GradientColorStep,
    /** Gradient end color */
    readonly stop: GradientColorStep,
    /** Direction of the linear gradient */
    degree: number,
  ) {
    super([start, stop], degree);
  }
}

/**
 * Defines a two-stop linear gradient color from a single HSL color. This is
 * currently using the {@link TwoStepGradientColor} class for compatibility, but
 * it will need to be replaced with {@link GradientColor} in the future.
 */
export class HslGradientColor extends TwoStepGradientColor {
  constructor(color: HSL, degree: number) {
    const [start, stop] = color.gradient();
    super({ hsl: start }, { hsl: stop }, degree);
  }
}
