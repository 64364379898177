import { getStorage } from '@firebase/storage';
import { useCallback } from 'react';
import { saveImage } from 'src/services/storage';
import { usePartialUpdateWorkspace } from 'src/services/database/Workspaces/setters';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import { WorkspaceUpdateDto } from 'src/services/database/Workspaces/dto/workspaceUpdate.dto';

/**
 * Handles updating the workspace in Firestore.
 * The Redux state is also updated on success.
 */
export default function useSubmitCallback({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}) {
  const workspace = useWorkspace();
  const storage = getStorage();
  const updateWorkspace = usePartialUpdateWorkspace(workspace?.slug ?? '');
  return useCallback(
    async function saveWorkspaceData(dto: WorkspaceUpdateDto) {
      if (!dto || !workspace) {
        return;
      }

      const { slug, logo } = dto;
      if (!slug) {
        return;
      }

      try {
        const hasChangedLogo = workspace.logo !== logo;
        // Check if logo was edited, if yes upload and save the return path
        if (hasChangedLogo && logo) {
          // TODO turn into reusable hook
          const logoUrl = await saveImage(
            storage,
            logo,
            `workspaces/${slug}/logo`,
          );
          if (logoUrl) {
            dto.logo = logoUrl;
          }
        }
        await updateWorkspace(dto);
        onSuccess();
      } catch (error) {
        onError(error);
      }
    },
    [workspace, onError, onSuccess, storage, updateWorkspace],
  );
}
