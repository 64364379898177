import { SVGProps, memo } from 'react';

const SvgCaretUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9c0-.255.098-.511.293-.706l8-8a.999.999 0 0 1 1.414 0l8 8a.999.999 0 1 1-1.414 1.413L9 2.414 1.707 9.708A.999.999 0 0 1 0 9Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgCaretUp);
export default Memo;
