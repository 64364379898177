import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import TextBox from 'src/app/components/form/TextBox/TextBox';
import { Flex } from 'src/app/components/shared/Flex';
import { PASSWORD_MIN_LENGTH } from 'src/services/auth';
import { SignUpForm } from '../types';

interface IInputFieldsProps {
  /**
   * Optional field name that gets auto-focused on first render.
   * @default 'firstName'
   */
  autoFocusField?: keyof SignUpForm;
}

export function InputFields({
  autoFocusField = 'firstName',
}: IInputFieldsProps) {
  const { register, setFocus } = useFormContext();

  useEffect(() => {
    if (autoFocusField) setFocus(autoFocusField);
  }, [autoFocusField, setFocus]);

  return (
    <>
      <Flex justify="space-between" align="flex-start" gap="14px">
        <TextBox
          label="First Name"
          type="text"
          placeholder="John"
          {...register('firstName', { required: true })}
          groupStyle={{ flexGrow: 1 }}
          inputStyle={{ width: '100%' }}
        />
        <TextBox
          label="Last Name"
          type="text"
          placeholder="Doe"
          {...register('lastName', { required: true })}
          groupStyle={{ flexGrow: 1 }}
          inputStyle={{ width: '100%' }}
        />
      </Flex>
      <TextBox
        label="Email"
        type="email"
        placeholder="john.doe@gmail.com"
        autoComplete="email"
        {...register('email', { required: true })}
      />
      <TextBox
        label="Password"
        type="password"
        autoComplete="current-password"
        {...register('password', {
          minLength: {
            value: PASSWORD_MIN_LENGTH,
            message: `The password must be at least ${PASSWORD_MIN_LENGTH} characters.`,
          },
          required: true,
        })}
      />
    </>
  );
}
