import { useCallback, useEffect, useMemo, useState } from 'react';

type FocusCallback = (isElementFocused: boolean) => void | Promise<void>;

/**
 * Generates event handlers that toggle the active state of an element.
 * Both pointer and keyboard focus/blur events are handled.
 * @param focusCallback - Optional callback that gets notified if the focus state changes.
 * @returns Tuple of boolean focus state and a spreadable object of callbacks
 */
export function useFocusState(focusCallback?: FocusCallback) {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (focusCallback) {
      focusCallback(isFocused);
    }
  }, [focusCallback, isFocused]);

  const onPointerEnter = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onPointerLeave = useCallback(() => {
    setIsFocused(false);
  }, []);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return useMemo(
    () =>
      [
        isFocused,
        {
          onPointerEnter,
          onPointerLeave,
          onFocus,
          onBlur,
        },
      ] as const,
    [isFocused, onBlur, onFocus, onPointerEnter, onPointerLeave],
  );
}
