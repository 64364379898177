import { SVGProps, memo } from 'react';

const SvgTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.518 1.25a1 1 0 0 0-.715-.3H1.717a.976.976 0 0 0-.976.976V4.05a1 1 0 0 0 .284.698L4.21 8.015c.576.593 1.158.593 1.734 0L7.675 6.25c.576-.594.58-1.174.004-1.767L4.518 1.251ZM1.744 2.803a.637.637 0 0 1 0-.884.596.596 0 0 1 .86 0 .638.638 0 0 1 0 .884.596.596 0 0 1-.86 0Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgTag);
export default Memo;
