import { SVGProps, memo } from 'react';

const SvgCog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#cog_svg__a)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.086 16.645a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" />
      <path d="M3.971 17.59a9.295 9.295 0 0 1-.944-2.277L4.6 13.344a7.65 7.65 0 0 1 0-.904l-1.572-1.968a9.321 9.321 0 0 1 .942-2.279l2.505-.28c.2-.226.413-.44.638-.64l.281-2.502a9.295 9.295 0 0 1 2.275-.937l1.968 1.573a7.65 7.65 0 0 1 .904 0l1.969-1.572a9.32 9.32 0 0 1 2.278.942l.281 2.504c.226.2.439.413.639.639l2.503.28c.414.715.732 1.48.944 2.278l-1.573 1.969c.017.3.017.603 0 .904l1.572 1.968a9.32 9.32 0 0 1-.938 2.278l-2.504.282c-.2.225-.413.438-.638.638l-.281 2.503a9.296 9.296 0 0 1-2.277.944l-1.97-1.573a7.653 7.653 0 0 1-.903 0l-1.969 1.572a9.324 9.324 0 0 1-2.278-.937l-.281-2.504a7.613 7.613 0 0 1-.639-.639l-2.505-.293Z" />
    </g>
    <defs>
      <clipPath id="cog_svg__a">
        <path fill="#fff" transform="translate(.086 .895)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgCog);
export default Memo;
