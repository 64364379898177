import { SVGProps, memo } from 'react';

const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.797 5.01-5.09 3.637 5.09 3.636V5.01Zm-6.545-1.454h-8c-.804 0-1.455.651-1.455 1.454v7.273c0 .804.651 1.455 1.455 1.455h8c.803 0 1.454-.651 1.454-1.455V5.01c0-.803-.65-1.454-1.454-1.454Z"
      stroke="currentColor"
      strokeWidth={1.984}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgVideo);
export default Memo;
