import clsx from 'clsx';
import { cloneElement, CSSProperties, forwardRef, ReactElement } from 'react';
import { CaretRight } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import {
  activeMenuItem,
  buttonText,
  menuItem,
  menuItemIcon,
} from './SidebarMenu.css';

interface IMenuItemProps {
  className?: string;
  /** If `true`, a **>** (right caret) icon is rendered in the button's right slot. */
  hasModal?: boolean;
  /**
   * Required icon component rendered in the button's left slot.
   *
   * Its `className` prop is assigned internally to reduce code duplication.
   */
  icon: ReactElement;
  /** Should be set to `true` if the button's child content is active. */
  isActive?: boolean;
  /** The rendered button label. */
  label: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const MenuItem = forwardRef<HTMLButtonElement, IMenuItemProps>(
  function MenuItem(
    { className, hasModal, icon, isActive, label, onClick, style },
    ref,
  ) {
    return (
      <Flex
        // Ref is required for anchoring the popover triggered by this menu item
        ref={ref}
        as="button"
        gap="12px"
        justify="flex-start"
        type="button"
        onClick={onClick}
        title={label}
        className={clsx(menuItem, className, {
          [activeMenuItem]: isActive,
        })}
        style={style}
      >
        <Flex>{cloneElement(icon, { className: menuItemIcon })}</Flex>
        <span className={buttonText} style={{ flexGrow: 1 }}>
          {label}
        </span>
        {hasModal && (
          <Flex style={{ marginLeft: 'auto' }}>
            <CaretRight className={menuItemIcon} width="16px" height="16px" />
          </Flex>
        )}
      </Flex>
    );
  },
);
