import { useFormContext } from 'react-hook-form';
import { sendUserPasswordResetEmail } from 'src/services/auth';
import { unstyledButton } from 'src/styles/utils.css';
import useAsync from 'src/utils/useAsync';
import { Spinner } from '../../shared/Spinner';
import { formStatus } from '../auth.css';
import { SignUpForm } from '../types';

/**
 * Intended for handling the "email already taken" signup error
 * with better UX.
 *
 * Renders a mini password reminder flow within the scope
 * of a form error message, where the user can trigger
 * a password reminder request.
 */
export function RequestReminderPrompt() {
  const { email } = useFormContext<SignUpForm>().getValues();
  const { execute, status } = useAsync(
    () => sendUserPasswordResetEmail({ email }),
    { isImmediate: false, isParallel: true },
  );

  switch (status) {
    case 'idle': {
      {
        return (
          <>
            This email already has an account with us.
            <button
              type="button"
              onClick={execute}
              className={unstyledButton}
              style={{
                textDecoration: 'underline',
              }}
            >
              Click here to request a reminder email.
            </button>
          </>
        );
      }
    }
    case 'pending': {
      return <Spinner type="circleBall" size="1em" />;
    }
    case 'success': {
      return (
        <span className={formStatus.success}>
          Check your inbox soon! The link will expire in one hour.
        </span>
      );
    }
    case 'error': {
      return (
        <>
          We could not send you the reminder.
          <button
            type="button"
            onClick={execute}
            className={unstyledButton}
            style={{
              textDecoration: 'underline',
            }}
          >
            Click here to retry.
          </button>
        </>
      );
    }
    default: {
      return null;
    }
  }
}
