import { useCallback } from 'react';
import { signUpWithEmail, updateUserData } from 'src/services/auth';
import {
  getGuestUserAdjectiveName,
  getGuestUserAnimalName,
  getGuestUserColorName,
} from 'src/services/auth/utils';
import { useSetUser, useUserId } from 'src/utils/userContent/hooks';
import { SignUpForm } from '../types';

function getDisplayName(firstName: string, lastName: string, uid: string) {
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : `${getGuestUserAdjectiveName(uid)} ${getGuestUserColorName(
        uid,
      )} ${getGuestUserAnimalName(uid)}`;
}

// TODO Don't use automatic member assignment, this should be done through
//  invitation as it's a security risk.

/**
 * @returns Memoized callback that signs up the user
 * with email and password, and stores their data in Redux.
 */
export default function useSignUpCallback(
  args: { onSuccess?: () => void } = {},
) {
  const setUser = useSetUser();
  const userId = useUserId();

  return useCallback(
    async (signUpData: SignUpForm) => {
      const { firstName, lastName, email, password } = signUpData;

      const response = await signUpWithEmail({
        email,
        password,
      });

      // TODO: handle this case properly: we could not sign up the user
      if (!response?.user) {
        console.error('Could not sign up the user');
        return;
      }

      const displayName = getDisplayName(firstName, lastName, userId);

      // Update the user's profile with a new display name
      await updateUserData({ displayName });
      setUser({
        displayName,
        email,
        isAnonymous: response.user.isAnonymous,
        uid: response.user.uid,
      });

      if (typeof args.onSuccess === 'function') {
        args.onSuccess();
      }
    },
    [userId, setUser, args],
  );
}
