import { SVGProps, memo } from 'react';

const SvgExternalLinkIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.577 15.044h-6.25v-6.25m11.458-5.208L9.326 15.044"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.287 4.107H5.592c-.46 0-.902.227-1.228.63-.326.403-.509.95-.509 1.52v15.048c0 .57.183 1.117.509 1.52.326.404.767.63 1.228.63h12.154c.46 0 .902-.226 1.228-.63.326-.403.509-.95.509-1.52v-8.89"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgExternalLinkIn);
export default Memo;
