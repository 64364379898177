import { PropsWithChildren } from 'react';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import { PageLoadingSpinner } from 'src/app/components/shared/PageLoadingSpinner/PageLoadingSpinner';
import { TypedNavigate } from 'src/app/router/TypedNavigate/TypedNavigate';
import { Path } from 'src/app/router/routes';

interface IWorkspaceTagRouteGuardProps {
  requiredTags: FlowDefinitionTag[];
}

export function WorkspaceTagRouteGuard({
  requiredTags,
  children,
}: PropsWithChildren<IWorkspaceTagRouteGuardProps>) {
  const workspace = useWorkspace();
  if (!workspace) {
    return <PageLoadingSpinner />;
  }

  if (workspace.tags.length === 0) {
    return <TypedNavigate to={Path.Workspace} replace />;
  }

  const hasRequiredTags = requiredTags.every((tag) =>
    workspace.tags.includes(tag),
  );

  if (!hasRequiredTags) {
    return <TypedNavigate to={Path.Workspace} replace />;
  }

  return <>{children}</>;
}
