import { LegalLinks } from '../LegalLinks';
import { Buttons } from './Buttons';
import { DefaultLayout } from './DefaultLayout';
import { Errors } from './Errors';
import { InputFields } from './InputFields';
import { Root } from './Root';
export { default as useLoginCallback } from './useLoginCallback';

/**
 * Provides the necessary components for implementing an email login form
 * using the compound component pattern.
 * 
 * @example
 * ```tsx
 * <EmailLoginForm.Root onLogin={()=> alert('Hooray!')}>
    <EmailLoginForm.DefaultLayout>
      <EmailLoginForm.InputFields autoFocusField="email" />
      <EmailLoginForm.Buttons
        onVendorAuth={() => setPageId('success')}
      />
      <EmailLoginForm.Errors />
      <EmailLoginForm.LegalLinks />
    </EmailLoginForm.DefaultLayout>
  </EmailLoginForm.Root>
 * ```
 */
export const EmailLoginForm = {
  /**
   * Renders the form submit button and the Google auth button under each other.
   */
  Buttons,
  /**
   * Renders a flex column layout with a maximum width which is applied in most app locations.
   * Adding or omitting this component doesn't impact the form behavior.
   */
  DefaultLayout,
  /**
   * Renders the validation error messages.
   */
  Errors,
  /**
   * Renders all the form input fields without any grouping layout.
   */
  InputFields,
  /** Renders the ToC and Privacy Policy URLs. */
  LegalLinks,
  /**
   * Renders the parent `<form>`.
   * It instantiates a React Hook Form instance to manage the login form.
   * The form instance is propagated to all child components,
   * so they can access it through the `useLoginFormInstance()` hook.
   */
  Root,
} as const;
