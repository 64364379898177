import { SVGProps, memo } from 'react';

const SvgEmailOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 84 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.977 1.654 1.85 26.348a.796.796 0 0 0-.151 1.235l40.125 40.898c.314.32.831.318 1.142-.005l39.355-40.9a.796.796 0 0 0-.15-1.226L42.816 1.657a.796.796 0 0 0-.84-.003Z"
      fill="url(#emailOpen_svg__a)"
      stroke="currentColor"
      strokeWidth={1.592}
    />
    <path
      d="M72.653 1H12.546a.796.796 0 0 0-.796.796v58.118c0 .44.356.796.796.796h60.107c.44 0 .796-.356.796-.796V1.796A.796.796 0 0 0 72.653 1Z"
      fill="#FAF7F5"
      stroke="currentColor"
      strokeWidth={1.592}
    />
    <path
      d="M83 69.065H1.798V27.104a.796.796 0 0 1 1.215-.678L37.224 47.57l5.163-2.95a.796.796 0 0 1 .812.013l4.773 2.937 33.81-21.133a.796.796 0 0 1 1.219.675v41.953Z"
      fill="url(#emailOpen_svg__b)"
    />
    <path
      d="M1.797 69.065H83m-81.204 0L37.224 47.57M1.797 69.065V27.104a.796.796 0 0 1 1.215-.678L37.224 47.57m45.777 21.495V27.112a.796.796 0 0 0-1.218-.675L47.972 47.57M83 69.065 47.97 47.57m0 0L43.2 44.633a.796.796 0 0 0-.812-.014l-5.163 2.95"
      stroke="currentColor"
      strokeWidth={1.592}
    />
    <path
      stroke="currentColor"
      strokeWidth={1.592}
      strokeLinecap="round"
      d="M29.659 10.145h27.864M23.292 20.495h40.602M29.659 30.845h27.864"
    />
    <defs>
      <linearGradient
        id="emailOpen_svg__a"
        x1={87.432}
        y1={72.211}
        x2={42.109}
        y2={-38.737}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.003} stopColor="#3451B2" />
        <stop offset={1} stopColor="#BD61F2" />
      </linearGradient>
      <linearGradient
        id="emailOpen_svg__b"
        x1={87.39}
        y1={71.082}
        x2={66.87}
        y2={-6.499}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.003} stopColor="#3451B2" />
        <stop offset={1} stopColor="#BD61F2" />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(SvgEmailOpen);
export default Memo;
