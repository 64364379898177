import { UUID } from 'src/@types/common';
import { FIRESTORE_COLLECTION_PATH } from '@shared/utils/constants';

export function constructPath(...segments: Array<string | undefined>) {
  return segments.filter((segment) => !!segment).join('/');
}

export function getPathForFlowDefinitions(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.FlowDefinitions, docId);
}

/** @deprecated Use `getPathForFlowDefinitions` instead, as sessions are now part of the flow definition. */
export function getPathForSessions(flowId: UUID, docId?: UUID) {
  return constructPath(
    getPathForFlowDefinitions(flowId),
    FIRESTORE_COLLECTION_PATH.Sessions,
    docId,
  );
}

export function getPathForWorkspaces(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.Workspaces, docId);
}

export function getPathForWorkspaceInvitations(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.WorkspaceInvitations, docId);
}

export function getPathForFlowInstances(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.FlowInstances, docId);
}

export function getPathForCanvasContent(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.CanvasContent, docId);
}

export function getPathForCanvasWidget(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.CanvasWidget, docId);
}

export function getPathForEnrollments(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.Enrollments, docId);
}

export function getPathForFocusManagerLocks(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.FocusManagerLocks, docId);
}

export function getPathForSharedResources(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.SharedResources, docId);
}

export function getPathForSurveyAnswers(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.SurveyAnswers, docId);
}

export function getPathForUsageAllocations(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.UsageAllocations, docId);
}

export function getPathForSkills(docId?: UUID) {
  return constructPath(FIRESTORE_COLLECTION_PATH.Skills, docId);
}
