import { SVGProps, memo } from 'react';

const SvgLeaves = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 47 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.427 32.626a22.976 22.976 0 0 1-6.69 16.215 22.795 22.795 0 0 1-16.15 6.716A22.795 22.795 0 0 1 7.436 48.84a22.976 22.976 0 0 1-6.69-16.215c0-5.753 2.453-12.384 7.292-19.706a1.99 1.99 0 0 1 1.263-.853 1.977 1.977 0 0 1 1.493.296l8.013 5.363 7.66-16.148A1.992 1.992 0 0 1 27.921.47a1.978 1.978 0 0 1 1.74.554c3.661 3.669 7.84 8.119 11.087 13.308 3.82 6.11 5.678 12.093 5.678 18.294Z"
      fill="#00EDDF"
    />
    <path
      d="M23.587 55.56a22.795 22.795 0 0 0 16.15-6.716c-4.74-14.437-9.243-21.303-20.93-31.115l-8.013-5.363a1.984 1.984 0 0 0-2.756.557C3.199 20.245.746 26.876.746 32.63a22.976 22.976 0 0 0 6.69 16.215 22.795 22.795 0 0 0 16.15 6.716Z"
      fill="#51A6E3"
    />
  </svg>
);

const Memo = memo(SvgLeaves);
export default Memo;
