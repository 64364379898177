import { SVGProps, memo } from 'react';

const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.874 19.102-4.87 2.576a1.341 1.341 0 0 1-1.816-.569 1.361 1.361 0 0 1-.135-.859l.93-5.456a1.36 1.36 0 0 0-.387-1.198L.656 9.732a1.36 1.36 0 0 1-.024-1.915c.207-.213.477-.352.77-.395l5.444-.796a1.346 1.346 0 0 0 1.013-.74L10.293.922a1.342 1.342 0 0 1 2.413 0l2.435 4.964c.196.4.575.676 1.013.74l5.444.796a1.353 1.353 0 0 1 1.138 1.535c-.043.295-.18.567-.393.775l-3.939 3.864a1.36 1.36 0 0 0-.387 1.198l.93 5.456a1.353 1.353 0 0 1-1.098 1.564c-.292.05-.592.002-.854-.136l-4.869-2.576a1.338 1.338 0 0 0-1.252 0Zm-1.084-1.9a3.664 3.664 0 0 1 3.42 0l3.229 1.71-.642-3.767a3.474 3.474 0 0 1 1.002-3.067l2.808-2.755-3.79-.554a3.588 3.588 0 0 1-2.71-1.958L11.5 3.535 9.893 6.811a3.588 3.588 0 0 1-2.71 1.958l-3.79.554L6.2 12.078a3.475 3.475 0 0 1 1.002 3.067l-.642 3.766 3.23-1.708Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgStar);
export default Memo;
