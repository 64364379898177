import { SVGProps, memo } from 'react';

const SvgSpeechBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.094 10.933a7.879 7.879 0 0 1-1.1 4.011c-.863 1.49-2.188 2.744-3.829 3.62-1.64.876-3.531 1.34-5.46 1.341a11.583 11.583 0 0 1-4.645-.95l-6.966 2.006 2.322-6.017a7.879 7.879 0 0 1-1.1-4.01c0-1.667.538-3.3 1.553-4.716C4.884 4.8 6.335 3.656 8.06 2.91a11.582 11.582 0 0 1 4.645-.95h.611c2.547.121 4.954 1.05 6.758 2.608 1.804 1.558 2.88 3.636 3.02 5.836v.528Z"
      stroke="currentColor"
      strokeWidth={2.024}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgSpeechBubble);
export default Memo;
