import { ValidationOptions } from 'class-validator';
import { FLOW_DEFINITION_GLOBAL_WORKSPACE_ID } from 'src/services/database/FlowDefinitions/constants';
import { createCustomValidator } from 'src/services/database/validators/utils';
import { isFirestoreUUID } from 'src/services/database/validators/validators';

/** Flow definition workspace ID */
export type FlowDefinitionWorkspaceId =
  | string
  | typeof FLOW_DEFINITION_GLOBAL_WORKSPACE_ID;

/**
 * Checks if the given value is a valid flow definition workspace ID.
 * @param value - The value to check.
 */
export function isFlowDefinitionWorkspaceId(value: unknown) {
  return (
    typeof value === 'string' &&
    value.length > 0 &&
    (value === FLOW_DEFINITION_GLOBAL_WORKSPACE_ID || isFirestoreUUID(value))
  );
}

/**
 * Decorator for validating a flow definition workspace ID.
 */
export function IsFlowDefinitionWorkspaceId(
  validationOptions?: ValidationOptions,
) {
  return createCustomValidator(
    'IsFlowDefinitionWorkspaceId',
    (value) => isFlowDefinitionWorkspaceId(value),
    () => 'Invalid flow definition workspace ID',
    validationOptions,
  );
}
