import { SVGProps, memo } from 'react';

const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#upload_svg__a)">
      <path
        d="M27.5 20.5H35a1.25 1.25 0 0 1 1.25 1.25v10A1.25 1.25 0 0 1 35 33H5a1.25 1.25 0 0 1-1.25-1.25v-10A1.25 1.25 0 0 1 5 20.5h7.5"
        stroke="currentColor"
        strokeOpacity={0.88}
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20.5V4.25"
        stroke="currentColor"
        strokeOpacity={0.88}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12.5 11.75 7.5-7.5 7.5 7.5"
        stroke="currentColor"
        strokeOpacity={0.88}
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.375 28.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
        fill="currentColor"
        fillOpacity={0.88}
      />
    </g>
    <defs>
      <clipPath id="upload_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgUpload);
export default Memo;
