import { SVGProps, memo } from 'react';

const SvgVolumeMuted = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.485 4.854 4.679 7.9H1.635v4.566h3.044l3.806 3.045V4.854Zm6.142-.054a7.61 7.61 0 0 1 0 10.763M11.94 7.487a3.806 3.806 0 0 1 0 5.381"
      stroke="currentColor"
      strokeWidth={1.553}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.553}
      strokeLinecap="round"
      d="m2.866 1.158 13.962 17.065"
    />
  </svg>
);

const Memo = memo(SvgVolumeMuted);
export default Memo;
