import clsx from 'clsx';
import { CSSProperties } from 'react';
import { AvatarGuestUser } from 'src/app/components/shared/AvatarGuestUser';
import { Flex } from 'src/app/components/shared/Flex';
import { useTypedSelector } from 'src/state/useTypedSelector';
import { roundedCropped } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { bodyTextNormal, bodyTextSmall, centerText } from '../../auth.css';
import { userAvatar } from '../AuthPopover.css';

interface IEditableAvatarProps {
  className?: string;
  style?: CSSProperties;
}

export function EditableAvatar({ className, style }: IEditableAvatarProps) {
  const user = useTypedSelector((state) => state.user.user);
  return (
    <Flex
      as="figure"
      direction="column"
      gap="14px"
      className={className}
      style={style}
    >
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <Flex
          className={clsx(roundedCropped, userAvatar)}
          style={{
            boxShadow: vars.elevations.medium,
          }}
        >
          <AvatarGuestUser style={{ maxWidth: '100%' }} />
        </Flex>
      </div>
      <figcaption className={centerText}>
        <div className={bodyTextNormal}>{user.displayName}</div>
        <small className={bodyTextSmall}>
          {user.email === '' ? 'john.doe@gmail.com' : user.email}
        </small>
      </figcaption>
    </Flex>
  );
}
