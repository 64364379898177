import { FloatingContext, FloatingFocusManager } from '@floating-ui/react';
import clsx from 'clsx';
import {
  Children,
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useId,
} from 'react';
import { unstyledList } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { menuTitle, sidebarMenu } from './SidebarMenu.css';

export interface ISidebarMenuRootProps {
  className?: string;
  /**
   * Floating UI's context is required for properly implementing ARIA props
   * for the popover menu.
   */
  floatingContext: FloatingContext;
  style?: CSSProperties;
  /** Optional menu title rendered above the list of items. */
  title?: string;
  /**
   * If necessary, any extra markup can be rendered below the title,
   * and above the menu items.
   */
  subtitle?: ReactNode;
}

export const SidebarMenuRoot = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ISidebarMenuRootProps>
>(function SidebarMenuRoot(
  { children, className, floatingContext, style, title, subtitle },
  ref,
) {
  const sidebarMenuTitleId = useId();

  return (
    <FloatingFocusManager context={floatingContext}>
      <div ref={ref} className={clsx(sidebarMenu, className)} style={style}>
        {title && (
          <h1
            id={sidebarMenuTitleId}
            className={menuTitle}
            style={{
              color: vars.color.brandGray88,
              padding: '18px 26px',
              textAlign: 'center',
            }}
          >
            {title}
          </h1>
        )}
        {subtitle}
        <nav aria-labelledby={title ? sidebarMenuTitleId : undefined}>
          <ul className={unstyledList}>
            {Children.map(children, (menuItem, ix) => (
              <li key={ix}>{menuItem}</li>
            ))}
          </ul>
        </nav>
      </div>
    </FloatingFocusManager>
  );
});
