import { SVGProps, memo } from 'react';

const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66.883A1 1 0 0 0 7.667 0H1L.883.007A1 1 0 0 0 0 1v24l.007.117A1 1 0 0 0 1 26h6.667l.116-.007A1 1 0 0 0 8.667 25V1L8.66.883Zm13.333 0A1 1 0 0 0 21 0h-6.667l-.116.007a1 1 0 0 0-.884.993v24l.007.117a1 1 0 0 0 .993.883H21l.117-.007A1 1 0 0 0 22 25V1l-.007-.117Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgPause);
export default Memo;
