import { SVGProps, memo } from 'react';

const SvgLike = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g clipPath="url(#like_svg__a)">
      <path
        d="M1.72 8.475h2.5v10h-2.5a.833.833 0 0 1-.834-.834V9.308a.833.833 0 0 1 .833-.834zm4.41-1.078 5.333-5.333a.417.417 0 0 1 .545-.04l.711.534a1.25 1.25 0 0 1 .46 1.308l-.96 3.775h5.333a1.667 1.667 0 0 1 1.667 1.667v1.753c0 .218-.042.434-.125.635l-2.58 6.263a.833.833 0 0 1-.77.516H6.72a.833.833 0 0 1-.833-.834V7.986c0-.22.088-.433.244-.589z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="like_svg__a">
        <path
          transform="translate(.053 .974)"
          fill="currentColor"
          d="M0 0h20v20H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgLike);
export default Memo;
