import { PropsWithChildren } from 'react';
import { TooltipContext, TooltipOptions, useTooltip } from './context';

export function TooltipRoot({
  children,
  ...options
}: PropsWithChildren<TooltipOptions>) {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}
