import { useMemo } from 'react';
import {
  FIRESTORE_COLLECTION_PATH,
  SYSTEM_PRODUCT_NAME,
} from '@shared/utils/constants';
import { createResourceId } from '@shared/utils/resourceId/resourceId';
import { useFlowInstanceDoc } from 'src/services/database/FlowInstances/getters';
import { useActiveParam } from 'src/state/active/hooks';

/**
 * Returns the active workspace ID.
 */
export function useWorkspaceId() {
  return useActiveParam('workspaceId') ?? '';
}

/**
 * Returns the active workspace resource ID.
 */
export function useWorkspaceResourceId() {
  const workspaceId = useWorkspaceId();
  return useMemo(
    () =>
      createResourceId({
        product: SYSTEM_PRODUCT_NAME,
        collection: FIRESTORE_COLLECTION_PATH.Workspaces,
        id: workspaceId,
      }),
    [workspaceId],
  );
}

/**
 * Retrieves the active flow instance ID.

 * TODO [KS] Temporarily, we are using the same ID for flow instances and shared
 *  resources, so this function will return the share ID from the URL as a
 *  fallback.
 */
export function useFlowInstanceId() {
  const shareId = useSharedResourceId();
  const flowInstanceId = useActiveParam('flowInstanceId') ?? '';
  if (shareId) {
    return shareId;
  }

  return flowInstanceId;
}

/**
 * Retrieves the active flow definition ID.
 *
 * The definition ID should be set as a URL path param.
 * If it isn't available, then the hook tries retrieving the active flow instance,
 * and use the definition ID stored in that document.
 */
export function useFlowDefinitionId() {
  const pathParam = useActiveParam('flowDefinitionId');
  const fallbackDefinitionId = useFlowInstanceDoc(
    useFlowInstanceId(),
  ).result?.data()?.flowDefinitionId;
  return pathParam ?? fallbackDefinitionId ?? '';
}

/**
 * @returns ID of the current session's definition
 */
export function useSessionId() {
  return useActiveParam('sessionId') ?? '';
}

/**
 * @returns ID of the current shared resource from the URL
 */
export function useSharedResourceId() {
  return useActiveParam('shareId') ?? '';
}
