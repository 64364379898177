import clsx from 'clsx';
import { CSSProperties } from 'react';
import { fillParent, posRela } from 'src/styles/utils.css';
import { AsyncStatus } from 'src/utils/useAsync';
import { useLoadImage } from 'src/utils/useLoadImage';
import { Flex } from '../../shared/Flex';
import { Positioned } from '../../shared/Positioned';
import { logoSize, workspaceLogoWrapper } from './WorkspaceLogo.css';
import { getWorkspaceColorFromName } from './colors';

interface IWorkspaceLogoProps {
  className?: string;
  imageUrl?: string;
  /** Workspace name whose 1st character is displayed as fallback. */
  name: string;
  /**
   * Defaults to `'medium'`. Controls the image size and font size of the logo.
   */
  size?: keyof typeof logoSize;
  style?: CSSProperties;
}

/**
 * Renders a workspace logo, or the 1st letter of the workspace name as fallback.
 */
export function WorkspaceLogo({
  className,
  imageUrl,
  name,
  size = 'medium',
  style,
}: IWorkspaceLogoProps) {
  const imageState = useLoadImage(imageUrl, {
    isVisibleOnMount: true,
  });

  return (
    <Flex
      isInline
      className={clsx(posRela, workspaceLogoWrapper, logoSize[size], className)}
      style={{
        ...style,
        backgroundColor: getWorkspaceColorFromName(name),
        lineHeight: 1,
      }}
    >
      <span role="presentation">{name.trim().charAt(0).toUpperCase()}</span>
      {imageState === AsyncStatus.Success && (
        <Positioned left={0} top={0} unit="px" z={2} className={fillParent}>
          <img
            src={imageUrl}
            alt={`Workspace logo of ${name}`}
            className={fillParent}
            style={{
              // Prevents showing placeholder through transparent SVGs
              background: getWorkspaceColorFromName(name),
            }}
          />
        </Positioned>
      )}
    </Flex>
  );
}
