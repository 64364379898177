import { IsBoolean, IsEnum, IsOptional } from 'class-validator';
import { CanvasContentType } from '@features/canvas/components/canvasComponent.types';
import { UnknownPartial } from 'src/services/database/types';
import { _CanvasItemConfigBaseDto } from './_canvasItemConfigBase.dto';

/**
 * Describes a pre-existing canvas content item that's instantiated
 * the moment the canvas is created.
 * Such content is defined in the canvas editor.
 */
export class CanvasDefaultContentDto<
  TContent = UnknownPartial,
> extends _CanvasItemConfigBaseDto<TContent> {
  /**
   * Describes the content type that informs how to validate the unknown `content` property.
   */
  @IsEnum(CanvasContentType)
  readonly contentType: CanvasContentType;

  /**
   * TODO Not implemented yet.
   * Whether the user can update the item's _content_ (e.g. the cell of a table) in the database.
   * Examples: the content of a table cell; the assigned scores saved in a scoring grid.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  readonly isContentEditable?: boolean;

  /**
   * TODO Not implemented yet.
   * Whether the user can update the item's coordinates or parent nodes in the database.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  readonly isMovable?: boolean;

  constructor(dto: CanvasDefaultContentDto<TContent>) {
    super(dto);
    this.contentType = dto.contentType;
    this.isContentEditable = dto.isContentEditable ?? true;
    this.isMovable = dto.isMovable ?? true;
  }
}
