import { Timestamp } from '@firebase/firestore';
import { LocaleMap, TextObject, UUID } from 'src/@types/common';
import { Size } from 'src/@types/dnd';
import { UnknownPartial } from 'src/services/database/types';
import { isFirestoreTimestamp as validatorIsFirestoreTimestamp } from 'src/services/database/validators/validators';
import { trace } from 'src/services/telemetry';

/**
 * Type guard that returns `true` if `value` is of type `UUID`
 */
export function isUUID(value?: unknown): value is UUID {
  return typeof value === 'string' || typeof value === 'number';
}

/**
 * Asserts that `value` is a Firestore Timestamp or `null`.
 * @returns `true` if `value` is a Firestore Timestamp or `null`.
 * @param value - The value to be validated.
 * @see {@link validatorIsFirestoreTimestamp}
 * @deprecated Use {@link validatorIsFirestoreTimestamp} instead.
 */
export function isFirestoreTimestamp(
  value: unknown,
): value is Timestamp | null {
  return validatorIsFirestoreTimestamp(value);
}

/**
 * @returns `true` if `value` is a {@link LocaleMap} with valid
 * [ISO 639-1](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes) language codes
 * following the `en_US` format.
 *
 * Only the locale codes are validated, but not the arbitrary content.
 */
export function isLocaleMap<T = unknown>(
  value: unknown,
  /** If defined, all object values are validated using this function. */
  childValidationFn?: (value: unknown) => boolean,
): value is LocaleMap<T> {
  const LOCALE_CODE = /^[a-z]{2}_[A-Z]{2}$/;
  const areLocaleCodesValid =
    isObject(value) && Object.keys(value).every((key) => LOCALE_CODE.test(key));
  return typeof childValidationFn === 'function' && isObject(value)
    ? areLocaleCodesValid &&
        Object.entries(value).every(([, child]) => childValidationFn(child))
    : areLocaleCodesValid;
}

/**
 * @returns `true` if `value` is a {@link TextObject}.
 */
export function isTextObject(value: unknown): value is TextObject {
  return isValidType<TextObject>(value, [['text', isString]]).isValid;
}

/**
 * @returns `true` if `value` is a JS object, so it satisfies `Record<string, unknown>` types.
 */
export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

/**
 * @returns `true` if value is a number (including `Infinity`) and is not `NaN`.
 */
export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}

/**
 * @returns `true` if value is a positive number between `0-1`.
 */
export function isRatioNumber(value: unknown): value is number {
  return typeof value === 'number' && 0 <= value && value <= 1;
}

/**
 * @returns `true` if value is a positive number between `0-1`.
 */
export function isIntegerNumber(value: unknown): value is number {
  return typeof value === 'number' && value === Math.round(value);
}

/**
 * @returns `true` if value is a string.
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * @returns `true` if value is {@link Size}.
 */
export function isSize(value: unknown): value is Size {
  return isValidType<Size>(value, [
    ['width', isNumber],
    ['height', isNumber],
  ]).isValid;
}

/**
 * A utility wrapper for validation functions that only runs the validation if the value exists.
 * @param assertionFn - Assertion function that should be applied to the object's field, if it exists.
 * @returns The value of the assertion function,
 * which can be a boolean, or `void` if the function throws on failure.
 * @example
 * ```ts
 * const result = isValidType<ExampleType>(unknownValue, [
 *  ['requiredField', isNumber],
 *  ['dateField', isFirestoreTimestamp],
 *  ['optionalField', validateIfExists(isUUID)]
 * ])
 * ```
 */
export function validateIfExists(assertionFn: (value: unknown) => boolean) {
  return (value: unknown) => {
    return value ? assertionFn(value) : true;
  };
}

/**
 * A utility wrapper for validation functions that
 * - assumes that `value` is an array, and
 * - runs the assertion function for every item of the array.
 * Validation passes only if `value` is an array, and every item passes validation.
 * @param assertionFn - Assertion function that should be applied to every array item.
 * @returns The value of the assertion function,
 * which can be a boolean, or `void` if the function throws on failure.
 * @example
 * ```ts
 * const result = isValidType<ExampleType>(unknownValue, [
 *  ['requiredField', isNumber],
 *  ['itemIds', validateArrayEvery(isUUID)]
 * ])
 * ```
 */
export function validateArrayEvery(assertionFn: (value: unknown) => boolean) {
  return (value: unknown) => {
    return Array.isArray(value) && value.every((item) => assertionFn(item));
  };
}

/**
 * A utility wrapper for validation functions that
 * - assumes that `value` is an object, and
 * - runs the assertion function for every property value of the oject.
 * Validation passes only if `value` is an object, and every item passes validation.
 * @param assertionFn - Assertion function that should be applied to every property value.
 * @returns The value of the assertion function,
 * which can be a boolean, or `void` if the function throws on failure.
 * @example
 * ```ts
 * const result = isValidType<ExampleType>(unknownValue, [
 *  ['requiredField', isNumber],
 *  ['objectProp', validateObjectEvery(isUUID)]
 * ])
 * ```
 */
export function validateObjectEvery(assertionFn: (value: unknown) => boolean) {
  return (value: unknown) => {
    return (
      isObject(value) && Object.values(value).every((val) => assertionFn(val))
    );
  };
}

/**
 * Used for specifying predicate methods to a field of `T`.
 * To be used for calling {@link isValidType}.
 */
type ValidatorTuple<T> = [keyof T, (value: unknown) => boolean];

/**
 * Asserts that an unknown value satisfies type `T` specified type by applying custom validators.
 * The predicate functions must return `true` if the value is valid.
 * @template T - The type to validate against.
 * @param value - The value to be validated.
 * @param validators - Array of validator tuples. Each tuple should contain a field name of `T`,
 * and a predicate function that returns `true` if the value is valid.
 * @param settings - Optional settings object.
 * @param settings.skippedFields - Optional array of field names that shouldn't be validated.
 * @returns An object indicating whether the value is valid and an array of invalid fields.
 * @example
 * ```ts
 * import { isNumber, isString, isValidType, validateIfExists } from 'src/utils/typeAssertions/common'
 *
 * interface Example {
 *   id: UUID
 *   name: string
 *   age: number
 * }
 *
 * function assertIsExample(value: unknown): asserts value is Example {
 *   const result = isValidType<CanvasSession>(someValue, [
 *     ['name', isString],
 *     ['age', validateIfExists(isNumber)],
 *     ['id', (val) => isUUID(id)],
 *   ])
 *   if (!result.isValid) {
 *     console.error(validationResult.invalidFields)
 *     throw new Error('Value is not of type Example')
 *   }
 * }
 * ```
 */
export function isValidType<T>(
  value: unknown,
  validators: ValidatorTuple<T>[],
  settings?: {
    /**
     * If `true`, missing fields won't throw an error.
     * Only existing fields will be validated.
     */
    isPartialAllowed?: boolean;
    /** Optional array of field names that shouldn't be validated. */
    skippedFields?: (keyof T)[];
  },
): { isValid: boolean; invalidFields: (keyof T)[] } {
  const { isPartialAllowed, skippedFields = [] } = settings ?? {};
  const maybeType = value as T;
  const invalidFields: (keyof T)[] = [];

  validators.forEach(([fieldName, validator]) => {
    if (isPartialAllowed && !maybeType[fieldName]) {
      return;
    }
    if (!validator(maybeType?.[fieldName])) {
      invalidFields.push(fieldName);
    }
  });

  const remainingInvalidFields = invalidFields.filter(
    (field) => !skippedFields.includes(field),
  );

  if (remainingInvalidFields.length > 0) {
    trace({
      level: 'error',
      message: 'Type assertion failed for object',
      data: {
        value,
        skippedFields,
        invalidFields: remainingInvalidFields,
        validationConfig: settings,
      },
    });
  }

  return {
    isValid: remainingInvalidFields.length === 0,
    invalidFields: remainingInvalidFields,
  };
}

export function isEnum<T extends UnknownPartial>(en: T) {
  return function checkEnumAgainstValue(value: unknown): value is T {
    return Object.values(en).includes(value);
  };
}
