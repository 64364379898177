import { motion, MotionProps, useWillChange } from 'framer-motion';
import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Close, EditPencilSimpleLine } from 'src/app/components/icons';
import { Flex } from 'src/app/components/shared/Flex';
import { unstyledButton } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import useAccordion from 'src/utils/useAccordion';
import { workspaceText16 } from '../WorkspaceSettingsModal.css';

interface IToggleFormProps {
  className?: string;
  style?: CSSProperties;
  /** Rendered as the accordion's clickable title. */
  title: string;
}

/**
 * Similarly to `<AuthPopover/>`'s `<ToggleInput/>` component,
 * this one renders a clickable section title that can be expanded to an entire form.
 */
export function FormAccordion({
  children,
  title,
  className,
  style,
}: PropsWithChildren<IToggleFormProps>) {
  const {
    isOpen,
    setIsOpen,
    getAccordionProps,
    getContentProps,
    getHeaderProps,
  } = useAccordion();

  //#region  =========== Height animation measurement ===========
  // TODO generalize to be used elsewhere
  const measureRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const onAnimationComplete = () => {
    setIsAnimating(false);
  };

  useEffect(() => {
    if (isOpen && measureRef.current) {
      setContentHeight(measureRef.current.getBoundingClientRect().height);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsAnimating(true);
  }, [isOpen]);

  const willChange = useWillChange();
  //#endregion  ======== Height animation measurement ===========

  return (
    <section className={className} style={style} {...getAccordionProps()}>
      <Flex
        as="button"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        title={isOpen ? `Close ${title} section` : `Open ${title} section`}
        gap="8px"
        className={unstyledButton}
        {...getHeaderProps()}
      >
        <span role="presentation" className={workspaceText16}>
          {title}
        </span>
        {isOpen ? (
          <Close
            width="27px"
            height="24px"
            style={{ color: vars.color.brandGray88 }}
          />
        ) : (
          <EditPencilSimpleLine
            width="27px"
            height="24px"
            style={{ color: vars.color.light99 }}
          />
        )}
      </Flex>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={
          isOpen
            ? { height: contentHeight, opacity: 1 }
            : { height: 0, opacity: 0 }
        }
        onAnimationComplete={onAnimationComplete}
        style={{
          overflowY: !isOpen || isAnimating ? 'hidden' : undefined,
          willChange,
        }}
        aria-hidden={!isOpen}
        {...(getContentProps() as MotionProps)}
      >
        <div ref={measureRef}>{children}</div>
      </motion.div>
    </section>
  );
}
