import clsx from 'clsx';
import { CSSProperties, DOMAttributes, ReactElement, useId } from 'react';
import { screenReaderOnly } from 'src/styles/utils.css';
import { vars } from 'src/styles/vars.css';
import { Flex } from '../../shared/Flex';
import { InputError } from '../InputError/InputError';
import { cssInputLabel } from './InputFieldBase.css';

interface IInputFieldBaseProps {
  className?: string;
  /**
   * Validation error message rendered under the input element.
   */
  error?: string;
  /**
   * Render function that should return the actual input element.
   * @param inputId - Should be passed to the input element for a11y.
   * @param labelId - Should be passed to the label element for a11y.
   * @param errorId - If the field has an error, this ID should be passed to the error element for a11y.
   * @param hasError - If `true`, the input element should be set in its error state.
   * @returns The rendered input element
   */
  input: (
    inputId: string,
    labelId: string,
    errorId: string,
    hasError: boolean,
  ) => ReactElement;
  /**
   * Toggles the visibility of the input label (while the ARIA label is always set).
   * @default true
   */
  isLabelRendered?: boolean;
  /**
   * The label for the input element.
   * Its rendering can be disabled with `isLabelRendered={false}`,
   * but the hidden `<label>` will still serve as the ARIA label for the input element.
   */
  label: string;
  /**
   * Intended for non-traditional input components to manually trigger focus state
   * in cases where the `htmlFor` prop wouldn't be sufficient.
   */
  onLabelClick?: DOMAttributes<HTMLLabelElement>['onClick'];
  required?: boolean;
  style?: CSSProperties;
  /**
   * Applies to the label of the input field.
   */
  labelStyle?: CSSProperties;
}

/**
 * Wraps an input element with `<label>` and an optional error message.
 *
 * The component can be used on its own, but it's also designed
 * to be used alongside React Hook Form.
 *
 * To override the field's colors, import the CSS variables from `cssVarsInputField`.
 *
 * @example
 * ```tsx
 * const { getFieldState, handleSubmit, register } = useForm<{ username: string }>()
 * return (
 *  <form onSubmit={handleSubmit(console.log)}>
 *    <InputFieldBase
 *      required
 *      error={getFieldState('username').error.message}
 *      input={(inputId, labelId, errorId, hasError) => (
 *         <input type="text"
 *          id={inputId}
 *          {...register('username', { required: true })}
 *          aria-labelledby={labelId}
 *          aria-describedby={hasError ? errorId : 'Longer description' }
 *          style={{ outline: hasError ? '2px solid red' : undefined }}
 *         />
 *      )}
 *    />
 *  </form>
 * )
 * ```
 */
export function InputFieldBase({
  className,
  error,
  input,
  isLabelRendered = true,
  label,
  onLabelClick,
  required,
  style,
  labelStyle,
}: IInputFieldBaseProps) {
  const inputId = useId();
  const labelId = `label_${inputId}`;
  const errorId = `error_${inputId}`;

  return (
    <Flex
      direction="column"
      justify="flex-start"
      align="flex-start"
      alignSelf="stretch"
      gap="4px"
      className={className}
      style={style}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <label
        className={clsx(cssInputLabel, {
          [screenReaderOnly]: !isLabelRendered,
        })}
        id={labelId}
        htmlFor={inputId}
        onClick={onLabelClick}
        style={labelStyle}
      >
        {label}
        {required ? (
          <span
            style={{ color: vars.color.status.error }}
            aria-label="required"
          >
            {' '}
            *
          </span>
        ) : null}
      </label>

      <div style={{ alignSelf: 'stretch' }}>
        {input(inputId, labelId, errorId, !!error)}
      </div>
      {error && <InputError id={errorId}>{error}</InputError>}
    </Flex>
  );
}
