import clsx from 'clsx';
import { useCallback, useState } from 'react';
import {
  useIsSupportChatOpen,
  useSupportChatClose,
  useSupportChatOpen,
} from '@features/support-chat/hooks';
import { fillParent } from 'src/styles/utils.css';
import { Tooltip } from 'src/app/components/shared/Tooltip';
import { Question } from '../../icons';
import { Flex } from '../../shared/Flex';
import {
  sidebarButton,
  sidebarButtonActive,
  sidebarButtonBadge,
  sidebarButtonWithBadge,
} from './Sidebar.css';

const HAS_SEEN_SUPPORT_CHAT_LOCALSTORAGE_KEY = 'hasSeenSupportChat';
let hasSeenSupportChat = localStorage.getItem(
  HAS_SEEN_SUPPORT_CHAT_LOCALSTORAGE_KEY,
);

export function SupportChatButton() {
  const open = useSupportChatOpen();
  const close = useSupportChatClose();
  const isOpen = useIsSupportChatOpen();

  // TODO [KS] Remove this fake notification badge when we have onboarding
  const [isOpenedOnce, setIsOpenedOnce] = useState<boolean>(
    hasSeenSupportChat === 'true',
  );

  const openChat = useCallback(() => {
    // TODO [KS] Remove this fake notification badge when we have onboarding
    if (!isOpenedOnce) {
      setIsOpenedOnce(true);
      hasSeenSupportChat = 'true';
      localStorage.setItem(
        HAS_SEEN_SUPPORT_CHAT_LOCALSTORAGE_KEY,
        hasSeenSupportChat,
      );
    }

    open();
  }, [isOpenedOnce, open]);

  return (
    <Tooltip.Root placement="right">
      <Tooltip.Anchor>
        <Flex
          as="button"
          type="button"
          className={clsx(
            sidebarButton,
            !isOpenedOnce && sidebarButtonWithBadge,
            isOpen && sidebarButtonActive,
          )}
          onClick={() => (isOpen ? close() : openChat())}
        >
          <Question className={fillParent} />
          {!isOpenedOnce && <span className={sidebarButtonBadge}></span>}
        </Flex>
      </Tooltip.Anchor>
      {!isOpen && <Tooltip.Content>Support</Tooltip.Content>}
    </Tooltip.Root>
  );
}
