import {
  IsEnum,
  IsObject,
  IsOptional,
  IsString,
  MinLength,
  ValidateNested,
} from 'class-validator';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import { WorkspaceUpdateInvitationSettingsDto } from 'src/services/database/Workspaces/dto/workspaceUpdateInvitationSettings.dto';

export class WorkspaceUpdateDto {
  @MinLength(2)
  @IsOptional()
  slug?: string;

  @MinLength(1)
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  logo?: string;

  @IsEnum(FlowDefinitionTag, { each: true })
  @IsOptional()
  tags?: FlowDefinitionTag[];

  @IsObject()
  @ValidateNested()
  invitationSettings?: WorkspaceUpdateInvitationSettingsDto;

  constructor(dto: WorkspaceUpdateDto) {
    this.slug = dto.slug;
    this.name = dto.name;
    this.logo = dto.logo;
    this.tags = dto.tags;
    this.invitationSettings = dto.invitationSettings
      ? new WorkspaceUpdateInvitationSettingsDto(dto.invitationSettings)
      : undefined;
  }
}
