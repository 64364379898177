import { SVGProps, memo } from 'react';

const SvgStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.666 12.487-2.597 1.427a.703.703 0 0 1-.969-.315.78.78 0 0 1-.072-.476l.496-3.022a.771.771 0 0 0-.206-.663l-2.101-2.14a.774.774 0 0 1-.013-1.06.707.707 0 0 1 .41-.22l2.904-.44a.72.72 0 0 0 .54-.41l1.298-2.75a.704.704 0 0 1 1.287 0l1.299 2.75a.72.72 0 0 0 .54.41l2.904.44c.392.06.663.44.606.85a.762.762 0 0 1-.209.43l-2.1 2.14a.771.771 0 0 0-.207.663l.496 3.022c.067.408-.195.796-.586.866a.69.69 0 0 1-.455-.075l-2.597-1.427a.69.69 0 0 0-.668 0Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgStarFilled);
export default Memo;
