import { TooltipAnchor } from './Anchor';
import { TooltipContent } from './Content';
import { TooltipRoot } from './Root';
export { tooltipVars } from './Tooltip.css';

/**
 * Renders a floating tooltip next to an anchor element.
 *
 * The tooltip is shown when the anchor element is focused,
 * but its state can also be controlled through props.
 *
 * The anchor can render any HTML tag through the `as` prop,
 * and the tag's relevant props will be offered by TypeScript.
 *
 * @example
 * ```tsx
 * <Tooltip.Root
 *   placement="bottom"
 *   offsetPx={15}
 * >
 *   <Tooltip.Anchor as="button" onClick={console.log}>
 *     Hover me
 *   <Tooltip.Anchor>
 *   <Tooltip.Content>
 *     This is a tooltip
 *   </Tooltip.Content>
 * </Tooltip.Root>
 * ```
 */
export const Tooltip = {
  /**
   * The root component configures the tooltip, and serves its state
   * to the sub-components.
   */
  Root: TooltipRoot,
  /**
   * The element whose focus will trigger showing the tooltip.
   */
  Anchor: TooltipAnchor,
  /**
   * Renders the tooltip's content.
   * The component's styling can be customized through the `tooltipVars`
   * CSS variables.
   */
  Content: TooltipContent,
} as const;
