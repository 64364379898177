import { SVGProps, memo } from 'react';

const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.858 1.649h7.843c1.993 0 3.608 1.762 3.608 3.935V16.08c0 2.174-1.615 3.936-3.608 3.936H3.866c-1.993 0-3.608-1.762-3.608-3.936V2.96C.258.787 1.873-.975 3.866-.975h6.474c.363 0 .707.179.935.487l1.583 2.137Zm-3.092 0h-5.9c-.664 0-1.203.587-1.203 1.311V16.08c0 .724.539 1.312 1.203 1.312H20.7c.665 0 1.203-.587 1.203-1.312V5.584c0-.724-.538-1.312-1.203-1.312h-8.417c-.364 0-.707-.179-.936-.487L9.766 1.648Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgFolder);
export default Memo;
